import { Autocomplete, Box, IconButton, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import assets from 'src/assets';
import InspectionBottomNavigation from 'src/pages/User Site/Inspections/InspectionV2/Components/InspectionBottomNavigation';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { ChildEntity } from 'src/dtos/EntityChild.dto';
import { NameNumberDto } from 'src/dtos/NameDto.dto';
import { Status } from 'src/dtos/Statuses';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService';
import InspectionChecklistVersionService from 'src/Services/Checklists/Inspections/InspectionChecklistVersionService';
import { guidRegex } from 'src/Utils/helperFunc';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import Section from './Components/Section';
import { GetSectionStatus, GetSectionVisible } from './CalculateStatusesHelper';
import FinalSignOff from './Components/FinalSignOff';

type Props = {
    demoMode: boolean;
}
function InspectionV2({demoMode}: Props) {
    const [messageItem, setMessageItem] = useState<MessageItem>({});
    const {responseId} = useParams(); 
    
    //#region queries
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [checklistResponseQueryEnabled, setChecklistResponseQueryEnabled] = useState(true);
    
    const isAnyMutationOngoing = 
        queryClient.isMutating({ mutationKey: ["UpdateQuestion"] }) > 0 ||
        queryClient.isMutating({ mutationKey: ["UpdateSelectedComponents"] }) > 0;


    const timeoutRef = useRef<NodeJS.Timeout | null >(null)
    useEffect(() => {
        if(isAnyMutationOngoing){
            setChecklistResponseQueryEnabled(false)
            // Clear the timeout if there's an ongoing mutation
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        } else {

            // Clear the timeout if there's an ongoing mutation
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setChecklistResponseQueryEnabled(true)
            }, 2000)

        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };

    }, [isAnyMutationOngoing]);

    const checklistResponseQuery = useQuery({
        queryKey: ["checklistResponse", responseId],
        queryFn: async () => {
            if(responseId){
                return await InspectionChecklistResponseService.Get(responseId);
            }
            return InspectionChecklistResponseService.GetDefaultValues()
        },
        initialData: InspectionChecklistResponseService.GetDefaultValues(),
        enabled: checklistResponseQueryEnabled
    })

    const checklistVersionQuery = useQuery({
        queryKey: ["checklistVersion", checklistResponseQuery.data.inspectionChecklistVersionId],
        queryFn: async () => {
            if(guidRegex.test(checklistResponseQuery.data.inspectionChecklistVersionId) && checklistResponseQuery.data.inspectionChecklistVersionId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistVersionService.GetInspection(checklistResponseQuery.data.inspectionChecklistVersionId);
            }
            return InspectionChecklistVersionService.GetDefaultVersionValues()
        },
        initialData: InspectionChecklistVersionService.GetDefaultVersionValues()
    })
    
    const checklistQuery = useQuery({
        queryKey: ["checklist", checklistVersionQuery.data.inspectionChecklistId],
        queryFn: async () => {
            if(guidRegex.test(checklistVersionQuery.data.inspectionChecklistId) && checklistVersionQuery.data.inspectionChecklistId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistService.Get(checklistVersionQuery.data.inspectionChecklistId);
            }
            return InspectionChecklistService.GetDefaultValues()
        },
        initialData: InspectionChecklistService.GetDefaultValues()
    })

    const attraction = checklistQuery.data.attraction ?? {id: "", label : ""}

    useEffect(() => {
        if(checklistResponseQuery.isError){
            setMessageItem({error: checklistResponseQuery.error})
        }
        if(checklistVersionQuery.isError){
            setMessageItem({error: checklistVersionQuery.error})
        }
        if(checklistQuery.isError){
            setMessageItem({error: checklistQuery.error})
        }
    }, [checklistQuery.error, checklistQuery.isError, checklistResponseQuery.error, checklistResponseQuery.isError, checklistVersionQuery.error, checklistVersionQuery.isError])

    const submitInspectionMutation = useMutation({
        mutationFn: async ({id, completedTime}:{id:string, completedTime: Date}) => {
            return await InspectionChecklistResponseService.Submit(id, completedTime)
        }, 
        onSuccess: () => {
            setMessageItem({successMessage: "Inspection Submitted."})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const UpdateSelectedComponents = useMutation({
        mutationKey: ["UpdateSelectedComponents", responseId],
        mutationFn: async ({responseId, values}: {responseId: string, values: NameNumberDto[]}) => {
            return await InspectionChecklistResponseService.UpdateSelectedComponents(responseId, values)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["checklistResponse", responseId], exact: true});
        }
    })
   
    //#endregion

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        initialValues: checklistResponseQuery.data ?? InspectionChecklistResponseService.GetDefaultValues(),
        onSubmit: (values) => {
            
        }
    });

    const componentSectionIndexes = (componentId: number) : number[] => {       
        const sectionIndexes = checklistVersionQuery.data.sections.reduce<number[]>((acc, section, sectionIndex) => {
            if(section.repeatingComponentSet?.id === componentId){
                acc.push(sectionIndex)
            }
            return acc
        }, [])

        return sectionIndexes
    }

    const handleUserSelectedCompChange = async (values: any) => {
        //prevent components from being removed if their section is not Unanswered
        if(formik.values.selectedComponents && values < formik.values.selectedComponents){
            const removedComponent = formik.values.selectedComponents.find(comp => !values.includes(comp))
            if(removedComponent) {
                const sectionIndexes = componentSectionIndexes(removedComponent.id)
                
                
                const hasStartedSections = sectionIndexes.some((sectionIndex) => {
                    return GetSectionStatus(formik.values.sectionResponses[sectionIndex], checklistVersionQuery.data.sections[sectionIndex], formik.values.inspectedComponents, formik.values.selectedComponents ?? []) !== Status.Unanswered
                })

                console.log(hasStartedSections)
                if(hasStartedSections && checklistVersionQuery.data.userSelectedComponent){
                    setMessageItem({errorMessage: `Cannot remove one of the ${checklistVersionQuery.data.userSelectedComponent.label} when a related section has been started`})
                    return
                }
            }
        }
        formik.setFieldValue("selectedComponents", values)
        responseId && UpdateSelectedComponents.mutateAsync({responseId: responseId, values: values})
        
    } 

    const submitInspection = async () => {
        
        const values = formik.values;
        values.completedTime = new Date();
        
        //cannot be submitted as in progress, should fail the inspection
        if(values.status === Status.Inprogress){
            values.status = Status.Failed
        }
        
        submitInspectionMutation.mutateAsync({id: formik.values.id, completedTime: values.completedTime})
    }

    const userSelectedOptions = (userSelectedComponent: ChildEntity) : NameNumberDto[] => {
        const options: NameNumberDto[] = userSelectedComponent.children.map((child) => {
            const comp: NameNumberDto = {
                id: child.id??0,
                label: child.label
            }
            return comp
        })

        return options;
    }

    const handleBackClick = () => {
        navigate({ pathname: window.location.pathname.replace(new RegExp(`/${responseId}`), '') });
    }

    //determines if the questions in the provided section are editable.
    const questionsEditable = (sectionIndex: number) : true | string  => {
        const lastSectionStatus = sectionIndex - 1 >= 0 
            && checklistVersionQuery.data
            && GetSectionStatus(formik.values.sectionResponses[sectionIndex-1], checklistVersionQuery.data.sections[sectionIndex-1], formik.values.inspectedComponents, formik.values.selectedComponents ?? []);

        if((formik.values.status === Status.Passed || formik.values.status === Status.Failed) && formik.values.completedTime !== null){
            return "Cannot edit a completed inspection"
        }

        if(checklistVersionQuery.data?.forceWorkflow === false){
            return true
        }

        const lastSectionVisible = checklistVersionQuery.data && GetSectionVisible(checklistVersionQuery.data.sections[sectionIndex -1], formik.values.selectedComponents ?? [])
        if(lastSectionStatus === Status.Passed || lastSectionStatus === Status.Failed || lastSectionStatus === Status.ComponentFailed || lastSectionStatus === false || !lastSectionVisible){
            return true
        } else {
            return "Must complete previous section before proceeding"
        }
    };

    const initialLoading = checklistResponseQuery.isLoading || checklistVersionQuery.isLoading || checklistQuery.isLoading || formik.values.id === InspectionChecklistResponseService.GetDefaultValues().id || checklistVersionQuery.data.sections.length === 0;
    if (initialLoading){
        return (
            <>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    {/* For logo */}
                    <Skeleton animation="wave" variant="circular" sx={{ width: "5rem", height: "5rem" }}/>
                    {/* For Title */}
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <Skeleton animation="wave" sx={{width: "80%", display:"flex"}}/>
                    </div>
                </Stack>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </>
        )
    }

    
    return (
        <>
            <Box sx={{marginBottom:"4rem"}}>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                    <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{checklistQuery.data.label}</Typography>    
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                        <IconButton onClick={handleBackClick}>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </div>
                </Stack>
                {checklistVersionQuery.data.userSelectedComponent && (
                    <div style={{padding:"1rem"}}>
                        <Paper sx={{padding:"1rem"}}>
                            <QuestionTitle title={`Which ${checklistVersionQuery.data.userSelectedComponent.label} are you inspecting during this inspection?`} required/>
                            <Autocomplete
                                id={`userSelecteditems_${checklistVersionQuery.data.userSelectedComponent.label}`}
                                multiple
                                options={userSelectedOptions(checklistVersionQuery.data.userSelectedComponent)}
                                value={formik.values.selectedComponents ?? []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label}
                                onChange={(_, value) => handleUserSelectedCompChange(value)}
                                sx={{width: "20rem"}}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant='outlined'
                                    size="small"
                                    label={checklistVersionQuery.data.userSelectedComponent?.label}
                                    />
                                )}
                            />
                        </Paper>
                    </div>  
                )}
                {checklistVersionQuery.data.sections.map((section, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                        {formik.values.sectionResponses[sectionIndex] != null && GetSectionVisible(section, formik.values.selectedComponents ?? [])  && (
                            <Section
                                formik={formik}
                                formikString={`sectionResponses[${sectionIndex}]`}
                                sectionContent={section}
                                sectionResponse={formik.values.sectionResponses[sectionIndex]}
                                questionsEditable={() => questionsEditable(sectionIndex)}
                                setMessageItem={setMessageItem}
                                attraction={attraction}
                                demoMode={demoMode}
                            />
                        )}

                    </Fragment>
                ))}

                <FinalSignOff
                    formik={formik}
                    checklistName={checklistQuery.data.label}
                    inspectionResponse={formik.values} 
                    sectionContents={checklistVersionQuery.data.sections}                
                    submitInspection={submitInspection}
                    loading={submitInspectionMutation.isPending} 
                />
            </Box>
            <ErrorHandlingSnackbar messageItem={messageItem} alertSx={{marginBottom:"5rem"}}/>
            <InspectionBottomNavigation journalAttractionId={attraction.id}/>
        </>
    )
}
export default InspectionV2