import React, { useCallback, useEffect, useState } from 'react';
import PointsListItem from './PointsListItem';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import { List } from '@mui/material';
import { LearningDocumentPoint, LearningDocumentSubpoint, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import { FormikProps } from 'formik';
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from './helper';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';

export type Props = {
    formik: FormikProps<LearningDocumentVersion>
    formikString: string;
    formikPointsValue: LearningDocumentPoint[];
    preparationChecklist?: boolean;
};

function PointsCreatorUnmemoized ({formik, formikString, formikPointsValue, preparationChecklist } : Props) {
    const [deletePointIndex, setDeletePointIndex] = useState<number | null>(null)

    const onDragEnd = (result: DropResult) => {
        const { type, destination, source } = result;
        // dropped outside the list
        if (!destination) return;
    
        const sourceIndex = source.index;
        const destIndex = destination.index;
        if(type === "droppablePoint"){
          const newPoints = reorder(formikPointsValue, source.index, destination.index);
          const sortedPoints = GetSortOrder(newPoints)
          formik.setFieldValue(`${formikString}`,sortedPoints);
        } else if(type === "droppableSubpoint" ){
          const subPointsMap = formikPointsValue.reduce((acc : any, point) => {
            acc[`point_${point.sortOrder}`] = point.subpoints
            return acc;
          }, {});
    
          const sourceParentId = source.droppableId;
          const destParentId = destination.droppableId;
    
          const sourceSubpoints = subPointsMap[sourceParentId];
          const destSubpoints = subPointsMap[destParentId];
    
          let newPoints = [...formikPointsValue];
    
          if(sourceParentId === destParentId) {
            const reorderedSubpoints : LearningDocumentSubpoint[] = reorder(
              sourceSubpoints,
              sourceIndex, 
              destIndex
            );
            newPoints = newPoints.map((point) => {
              if(`point_${point.sortOrder}` === sourceParentId) {
                point.subpoints = reorderedSubpoints;
              }
              return point;
            });
            const sortedPoints = GetSortOrder(newPoints)
            formik.setFieldValue(`${formikString}`, sortedPoints);
          } else {
            let newSourceSubpoints = [...sourceSubpoints];
            const [draggedPoint] = newSourceSubpoints.splice(sourceIndex, 1);
    
            let newDestSubpoints = [...destSubpoints];
            newDestSubpoints.splice(destIndex, 0, draggedPoint);
            newPoints = newPoints.map((point) => {
              if(`point_${point.sortOrder}` === sourceParentId) {
                point.subpoints = newSourceSubpoints;
              } else if (`point_${point.sortOrder}` === destParentId) {
                point.subpoints = newDestSubpoints;
              }
              return point;
            });
            const sortedPoints = GetSortOrder(newPoints)
            formik.setFieldValue(`${formikString}`,sortedPoints);
          }
        }
    
    };

    //used to get sortOrder based on index
    const GetSortOrder = (unSortedPoints: LearningDocumentPoint[]) => {
        const newPoints = unSortedPoints.map((point, index) => {
        const newPoint: LearningDocumentPoint = {...point, sortOrder: index};
        if(newPoint.subpoints){
            newPoint.subpoints = newPoint.subpoints.map((subpoint, subpointIndex) => {
            const newSubpoint: LearningDocumentSubpoint = {...subpoint, sortOrder: subpointIndex }
            return newSubpoint;
            })
        }
        return newPoint;
        })
        return newPoints;
    }

    
    const DeletePointFunc = (index: number) => {
      const curPoints = [...formikPointsValue]
      curPoints.splice(index, 1)
      const newPoints = GetSortOrder(curPoints);
      formik.setFieldValue(`${formikString}`, newPoints)
      
      //if the last point is deleted insert new point
      if(newPoints.length === 0){
        formik.setFieldValue(`${formikString}`, [LearningDocumentVersionService.GetDefaultPointValue()])
      }
    }
    const DeletePoint = useCallback(DeletePointFunc, [formik, formikPointsValue, formikString])
    
    useEffect(() => {
      if(deletePointIndex !== null){
        DeletePoint(deletePointIndex)
        setDeletePointIndex(null)
      }
    }, [DeletePoint, deletePointIndex])

    const DeleteSubPoint = (pointIndex: number, subPointIndex: number) => {
      const subpoints = formikPointsValue[pointIndex].subpoints;
      subpoints.splice(subPointIndex, 1);
      //reset sort order
      const newSubPoints = subpoints.map((subpoint, index) => {return {...subpoint, sortOrder: index}})

      formik.setFieldValue(`${formikString}[${pointIndex}].subpoints`, newSubPoints)
    }

    const handleAddPointBelow = (pointIndex: number) => {
      const insertIndex = pointIndex + 1;
      let newPoints = [
        ...formikPointsValue.slice(0, insertIndex),
        LearningDocumentVersionService.GetDefaultPointValue(),
        ...formikPointsValue.slice(insertIndex)
      ];

      //reset sortorder
      newPoints = newPoints.map((newPoint, newPointIndex) => {
        return {...newPoint, sortOrder: newPointIndex}
      })
      
      formik.setFieldValue(`${formikString}`, newPoints);
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list" type="droppablePoint">
            {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
                {formikPointsValue.map((item: LearningDocumentPoint, index: number) => (
                    <PointsListItem 
                        key={index}
                        formik={formik}
                        formikString={`${formikString}[${index}]`}
                        formikPointValue={item} 
                        index={index} 
                        DeletePoint={() => setDeletePointIndex(index)}
                        DeleteSubPoint={(subPointIndex: number) => DeleteSubPoint(index, subPointIndex)} 
                        HandleAddPointBelow={() => handleAddPointBelow(index)}
                        preparationChecklist={preparationChecklist}
                    />
                ))}
                {provided.placeholder}
            </List>
            )}
        </Droppable>
      </DragDropContext>
    );
}

const PointsCreator = PointsCreatorUnmemoized

export default PointsCreator;
