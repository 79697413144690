import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from './components/common/layouts/AdminLayout';
import UserLayout from './components/common/layouts/UserLayout';
import PageNotFound from './pages/UtilityPages/PageNotFound/PageNotFound';
import {adminAppRoutes, completedDocumentAppRoutes, userAppRoutes} from "./routes"
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './config/authConfig';
import { Avatar, Button, Paper, Stack, Typography } from '@mui/material';
import { EndSessionRequest, IPublicClientApplication } from '@azure/msal-browser';
import assets from './assets';
import LandingPage from './pages/UtilityPages/LandingPage/LandingPage';
import { QueryClient } from '@tanstack/react-query';
import CompletedDocumentLayout from './components/common/layouts/CompletedDocumentLayout';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 //24 hours
    },
    mutations: {
      retry: 3,
      gcTime: 1000 * 60 * 60
    }
},
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirect = () => {
    // Store the current location before redirecting
    localStorage.setItem('redirectLocation', location.pathname);

    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'select_account',
        redirectStartPage: "",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const redirectLocation = localStorage.getItem('redirectLocation');
    // Check if there's a stored redirect location
    if (activeAccount && redirectLocation) {
      // Redirect the user to the stored location after successful login
      navigate(redirectLocation);
      localStorage.removeItem('redirectLocation');
    }
  }, [activeAccount, navigate]);

  useEffect(() => {
    const checkSessionExpiry = () => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            const activeAccount = accounts[0];
            const idTokenClaims = activeAccount.idTokenClaims;
            if(idTokenClaims === undefined || idTokenClaims.exp === undefined){
              return
            }
            const expiration = idTokenClaims.exp * 1000; // Convert to milliseconds
            const now = new Date().getTime();
            
          if (now >= expiration) {
            console.log("Session has expired");
            const endSessionRequest: EndSessionRequest = {
              account: activeAccount,
              logoutHint: activeAccount.name,
              onRedirectNavigate: (_url) => {
                return false;
              }
            }
            instance.logoutRedirect(endSessionRequest)
          }
      }
    };

    const intervalId = setInterval(checkSessionExpiry, 1000 * 60); // Check every minute
    return () => clearInterval(intervalId);
}, [instance]);
  

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{persister, maxAge: 1000 * 60 * 60 * 24}}>
      <ReactQueryDevtools initialIsOpen={false} />
      <div className="App">
          <AuthenticatedTemplate>
            {activeAccount ? (
              <Routes>
                <Route path ="/" element={<LandingPage />}/>

                {/* Admin protected routes */}
                <Route path ="/admin/" element={<AdminLayout />}>
                  {adminAppRoutes}
                </Route>
          
                {/* user routes */}
                <Route path="/user/" element={<UserLayout/>}>
                  {userAppRoutes}
                </Route>

                {/* completedDocument routes */}
                <Route path="/completed/" element={<CompletedDocumentLayout/>}>
                  {completedDocumentAppRoutes}
                </Route>

                {/* catch all (page not found) */}
                <Route path="/*" element={<PageNotFound/>}/>
          
              </Routes>
            ) : null}
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <div style={{display:"flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
              <Paper  sx={{padding: "2rem", blockSize: "fit-content",}}>
                <Stack sx={{display:"flex", alignItems:"center"}}>
                  <Avatar src ={assets.images.logo} sx={{ width: "8rem", height: "8rem", marginBottom:"1rem" }}/>
                  <Typography variant="h3" gutterBottom>
                    AW Hub
                  </Typography>
                  <div style={{display:"flex", justifyContent: "center"}}>
                    <Button onClick={handleRedirect} variant="contained" sx={{marginTop:"1rem"}} >
                        Sign in with Microsoft
                    </Button>
                  </div>
                </Stack>
              </Paper>
            </div>
          </UnauthenticatedTemplate>
      </div>
    </PersistQueryClientProvider>

);
}

function App({instance} : {instance: IPublicClientApplication}) {

  return (
    <MsalProvider instance={instance}>
      <MainContent/>
    </MsalProvider>
    
  );
}

export default App;
