import { Button, Stack, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { InspectionChecklistQuestionResponseDto, InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { ChildEntity } from 'src/dtos/EntityChild.dto'

type Props = {
    formik: FormikProps<InspectionChecklistResponseDto>;
    formikString: string;
    relatedComponent?: ChildEntity | null
    questionResponse: InspectionChecklistQuestionResponseDto
    questionEditable: () => true | string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>;
    handleUpdateQuestion: (newValues: InspectionChecklistQuestionResponseDto) => void;
}
function ImpactsOperation({formik, formikString, relatedComponent, questionResponse, questionEditable, setMessageItem, handleUpdateQuestion}: Props) {

    const handleImpactsYesClick = () => {
        const questionEditableResponse = questionEditable()
        if(questionEditableResponse !== true){
            setMessageItem({errorMessage: questionEditableResponse})
            return
        }

        const newImpactsOperation = questionResponse.impactsOperation === true ? undefined : true
        formik.setFieldValue(`${formikString}.impactsOperation`, newImpactsOperation)
        handleUpdateQuestion({...questionResponse, impactsOperation: newImpactsOperation})
    }

    const handleImpactsNoClick = () => {
        const questionEditableResponse = questionEditable()
        if(questionEditableResponse !== true){
            setMessageItem({errorMessage: questionEditableResponse})
            return
        }

        const newImpactsOperation = questionResponse.impactsOperation === false ? undefined : false
        formik.setFieldValue(`${formikString}.impactsOperation`, newImpactsOperation)
        handleUpdateQuestion({...questionResponse, impactsOperation: newImpactsOperation})
    }

    return (
        <>
            <Stack>
                {relatedComponent && relatedComponent.children && relatedComponent.children.length > 1 && (
                    <Typography>Grouped question logic go here which components fail</Typography>
                )}
                <QuestionTitle
                    title={`Will this issue cause 
                        ${relatedComponent && relatedComponent.requiresTagging 
                            ? `"${relatedComponent.label}" to be tagged out` 
                            : `the ride to be out of service`}?`}
                />
                <Stack direction="row" spacing={2}>
                    <Button variant={questionResponse.impactsOperation === true ? "contained" : "outlined"} color='error' onClick={handleImpactsYesClick}>Yes</Button>
                    <Button variant={questionResponse.impactsOperation === false ? "contained" : "outlined"} color='success' onClick={handleImpactsNoClick}>No</Button>
                </Stack>

            </Stack>

        </>
    )
}

export default ImpactsOperation