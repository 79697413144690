import { Chip, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { JournalEntryDto, JournalEntryStatus, JournalEntryStatusString, JournalEntryTypeString } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'

type Props = {
    journalEntry: JournalEntryDto
}
function JournalEntry({journalEntry}: Props) {
    return (
        <Paper sx={{padding: "1rem", margin:"1rem", width: "fit-content"}}>
            <Typography variant='h5'>{`Journal Entry ${journalEntry.entryId}`}</Typography>
            <Typography variant='h5'>{journalEntry.label}</Typography>
            <Stack direction="row" spacing={6}>
                <Chip
                    label={JournalEntryTypeString[journalEntry.type]}
                />
                {journalEntry.status !== JournalEntryStatus.NA && (
                    <Chip
                        label={JournalEntryStatusString[journalEntry.status]}
                        color={journalEntry.status === JournalEntryStatus.completed ? "success" : "primary"}
                    />
                )}
            </Stack>
            <Typography variant='h6'>Action Required</Typography>
            <Typography>{journalEntry.description}</Typography>
            <Typography variant='h6'>Action Taken</Typography>
            <Typography>{journalEntry.resolutionDescription}</Typography>
        </Paper>
    )
}

export default JournalEntry