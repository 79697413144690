import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { guidRegex } from 'src/Utils/helperFunc'
import JournalEntryNew from './Components/JournalEntryNew'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import JournalEntryEdit from './Components/JournalEntryEdit'
import { JournalEntryStatus, JournalEntryStatusString } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import { useQuery } from '@tanstack/react-query'
import AttractionService from 'src/Services/Resources/AttractionService'
import * as yup from 'yup'
import { journalEntryTypesWithStatus } from 'src/Services/Checklists/Inspections/AttractionJournalEntryService'

const MIN_CHARACTERS = 10
export const validationSchema = yup.object({
    label: yup.string().required("Entry Summary is a required field"),
    type: yup.number().required("Entry Type is a required field"),
    status: yup.number(),
    description: yup.string().when(['type', 'resolutionDescription'], ([type, resolutionDescription]) => {
        if(journalEntryTypesWithStatus.includes(type) && (resolutionDescription == null || resolutionDescription.length < MIN_CHARACTERS))
            return yup.string()
                .required('Action Required is a required field')
                .min(MIN_CHARACTERS, `Action Required must be at least ${MIN_CHARACTERS} characters`)
                .max(500, 'Action Required cannot be more than 500 characters')
        return yup.string()
            .notRequired()
            .max(500, 'Description cannot be more than 500 characters')
    }),
    resolutionDescription: yup.string().when(['type', 'status'], ([type, status]) => {
        if(journalEntryTypesWithStatus.includes(type) && status === JournalEntryStatus.completed)
            return yup.string()
                .required(`Action Taken is a required field when the status is ${JournalEntryStatusString[JournalEntryStatus.completed]}`)
                .min(MIN_CHARACTERS, `Action Taken must be at least ${MIN_CHARACTERS} characters`)
                .max(500, 'Action Taken cannot be more than 500 characters')
        return yup.string()
            .notRequired()
            .max(500, 'Action Taken cannot be more than 500 characters')
    })
})

function JournalEntry() {
    const {attractionId, entryId} = useParams() 
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const location = useLocation()
    const navigate = useNavigate()

    //#region Queries
    const attractionQuery = useQuery({
        queryKey: ["attraction", attractionId],
        queryFn: async () => {
            if(attractionId && guidRegex.test(attractionId)){
                return await AttractionService.Get(attractionId)
            }
            return null
        }
    })
    const attractionName = attractionQuery.data ? {id: attractionQuery.data.id, label: attractionQuery.data.label} : {id: "", label: ""}

    useEffect(() => {
        if(attractionQuery.isError){
            setMessageItem({error: attractionQuery.error})
        }
    }, [attractionQuery.error, attractionQuery.isError])
    
    //#endregion

    const onCreateNew = (journalEntryId: string) => {
        //Update Url
        navigate({pathname: window.location.pathname.replace(new RegExp('/new'), `/${journalEntryId}`)})
    }

    return (
        <>
            
            {entryId && guidRegex.test(entryId) ? (
                <div>
                    <JournalEntryEdit 
                        id={entryId}
                        validationSchema={validationSchema}
                        setMessageItem={setMessageItem}
                        attractionName={attractionName}
                        backLink={location.pathname.replace(`/${entryId}`, "")}
                    />
                </div>
            ) : (
                <div>
                    <JournalEntryNew
                        validationSchema={validationSchema}
                        setMessageItem={setMessageItem}
                        attractionName={attractionName}
                        backLink={location.pathname.replace(`/new`, "")}
                        onCreate={onCreateNew}
                    />
                </div>
            )}
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        
        </>

        
        
    )
}

export default JournalEntry