import React, { useEffect, useRef } from 'react'
import { LearningDocumentTopic, LearningDocumentType } from 'src/dtos/Training/LearningDocument.dto'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, Typography } from '@mui/material';
import colorConfigs from 'src/config/colorConfigs';
import { LearningDocumentResponseDto, LearningDocumentResponseStatus, LearningDocumentResponseStatusString, LearningDocumentTopicResponse } from 'src/dtos/Training/LearningDocumentResponse.dto';
import { FormikProps } from 'formik';
import CompetencyDocumentTopic from './Components/CompetencyDocumentTopic';
import TrainingDocumentTopic from './Components/TrainingDocumentTopic';
import { ChipColor } from 'src/config/constants';
import { useMutation } from '@tanstack/react-query';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import { GetTopicStatus } from '../../calculateStatusHelper';

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    index: number;
    documentType: LearningDocumentType;
    demoMode?: boolean;
};

function Topic({topic, documentType, index, topicResponse, formikString, formik, demoMode }: Props) {

    const topicStatus = GetTopicStatus(topicResponse, documentType === LearningDocumentType.competencyDocument)
    let statusColour: ChipColor = "default";
    if (topicStatus === LearningDocumentResponseStatus.Completed)
        statusColour = "success";
    else if (topicStatus === LearningDocumentResponseStatus.InProgress)
        statusColour = "primary";
    else if (topicStatus === LearningDocumentResponseStatus.Unanswered)
        statusColour = "primary";
    else if (topicStatus === LearningDocumentResponseStatus.AwaitingOutcome)
        statusColour = "secondary"
    else if (topicStatus === LearningDocumentResponseStatus.NotYetCompetent)
        statusColour = "warning"
    else if (topicStatus === LearningDocumentResponseStatus.Competent)
        statusColour = "success"

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: LearningDocumentTopicResponse }) => {
            return LearningDocumentResponseService.UpdateTopic(id, values)
        },
        
    })
    const hasMounted = useRef(false);
    useEffect(() => {
        if (hasMounted.current && demoMode === false) {
            //Topic has changed
            updateMutation.mutate({id: topicResponse.id, values: topicResponse})
            
        } else {
            hasMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicResponse.commentary, topicResponse.startedTime, topicResponse.competent])
    
    return (
        <Accordion>
            <AccordionSummary
                id={`${index}topic_header`}
                expandIcon={<ExpandMoreIcon className="invsiblePrint" />}
                aria-controls="topic"
            >
                <Stack
                    direction="row"
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "1rem",
                    }}
                >
                    <Typography variant="h5">{topic.label}</Typography>
                    {/* TODO: Add StopWatch */}
                    {topic.estimateCompletionMinutes && (
                        <Chip
                            sx={{ backgroundColor: colorConfigs.AW.blue }}
                            label={`${topic.estimateCompletionMinutes}m`}
                        />
                    )}
                    <Chip
                        label={LearningDocumentResponseStatusString[topicStatus]}
                        color={statusColour}
                    />
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {documentType === LearningDocumentType.trainingDocumentNTM ||
                documentType === LearningDocumentType.trainingDocumentRTM || 
                documentType === LearningDocumentType.orientation ? (
                    <TrainingDocumentTopic
                        topic={topic}
                        topicResponse={topicResponse}
                        formikString={formikString}
                        formik={formik}
                        demoMode={demoMode}
                    />
                ) : (
                    <CompetencyDocumentTopic
                        topic={topic}
                        topicResponse={topicResponse}
                        formikString={formikString}
                        formik={formik}
                        demoMode={demoMode}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default Topic; 