import { TableRow, TableCell, TextField } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import CustomSignaturePad from 'src/components/SignaturePad/SignaturePad'
import { dateTimeOptions } from 'src/config/constants'
import { AttendanceSheetAttendee, AttendanceSheetResponse, AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto'

type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    assessor: AttendanceSheetAttendee
    updateAttendanceSheetResponse: (newValues: AttendanceSheetResponse) => void;
}
function AssessorRow({formik, assessor, updateAttendanceSheetResponse}:Props) {
    const [assessorNotes, setAssessorNotes] = useState(assessor.notes ?? "")
    const trainerIndex = formik.values.attendanceSheetResponse.attendees.findIndex(attendee => attendee.teamMemberId === assessor.teamMemberId && attendee.type === AttendeeType.Trainer)
    const updateAssessorSignature = (signature: string | undefined) => {
        
        let newValues = formik.values.attendanceSheetResponse

        newValues.attendees[trainerIndex].signature = signature
        newValues.attendees[trainerIndex].attended = true
        if(signature){
            newValues.attendees[trainerIndex].timeSigned = new Date()
        } else {
            newValues.attendees[trainerIndex].timeSigned = undefined
        }
        updateAttendanceSheetResponse(newValues)
    }

    const updateNotes = (newNotes: string) => {
        let newValues = formik.values.attendanceSheetResponse
        newValues.attendees[trainerIndex].notes = newNotes

        updateAttendanceSheetResponse(newValues)
    }

    return (
        <TableRow
            key={assessor.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {`${assessor.teamMember.prefferedFirstName} ${assessor.teamMember.lastName}`}
            </TableCell>
            <TableCell>
                <TextField 
                    id={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    name={`attendanceSheetResponse.attendees[${trainerIndex}].notes`}
                    value={assessorNotes}
                    onChange={(e) => setAssessorNotes(e.target.value)}
                    onBlur={() => updateNotes(assessorNotes)}
                    multiline
                    maxRows={5}
                    size='small'
                    sx={{width:"20rem"}}
                    helperText={`(handover notes for next Assessor if necessary)`}
                />
            </TableCell>
            <TableCell>
                <CustomSignaturePad 
                    person={{name: `${assessor.teamMember.prefferedFirstName} ${assessor.teamMember.lastName}`, signature: assessor.signature}}
                    setSignature={(signature: string | undefined) => updateAssessorSignature(signature)}
                />
            </TableCell>
            <TableCell>
                {assessor.timeSigned && new Date(assessor.timeSigned).toLocaleDateString(undefined, dateTimeOptions)}
            </TableCell>
        </TableRow>
    )
}

export default AssessorRow