import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Paper, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { AttractionNameDto } from 'src/dtos/Resources/Attraction.dto'
import { InspectionChecklist } from 'src/dtos/Checklists/MaintenanceChecklist.dto'
import * as yup from 'yup'
import AttractionService from "src/Services/Resources/AttractionService";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { dateOptions } from "src/config/constants";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DepartmentService from "src/Services/Resources/DepartmentService";
import { DepartmentNameDto } from "src/dtos/Resources/Department.dto";
import ChecklistVersions from "./ChecklistVersions/ChecklistVersions";
import ExpirySelector from 'src/components/ExpirySelector/ExpirySelector'

const validationSchema = yup.object({
  label: yup
    .string()
    .max(75, "Checklist Name cannot be more than 75 characters")
    .required("Checklist Name is required"),
  description: yup
    .string()
    .max(255, "Checklist Description cannot be more than 255 characters")
    .notRequired(),
  department: yup
    .object()
    .shape({
      id: yup.string().required(),
      label: yup.string().required(),
    }).required("Department is required"),
  attraction: yup
    .object()
    .shape({
      id: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Attraction is required"), //TODO: wont be required for other inspection types
  expiry: yup
    .object()
    .shape({
      type: yup
        .string()
        .required("Expiry Type is required"),
      afterValue: yup
        .number()
        .typeError("Must be a number")
        .positive("Must be positive")
        .notRequired(),
      everyTimeValue: yup
        .string()
        .matches(
          /^([01]\d|2[0-3]):([0-5]\d)$/,
          "Invalid time format. Please use HH:mm (24-hour format)"
        ),
      everyDayValue: yup
        .string()
        .matches(
          /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)$/
        ),
    })
    .required(),
})

function InspectionChecklistForm() {
    const {id} = useParams();
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined});
    const [formValues, setFormValues] = useState<InspectionChecklist>(InspectionChecklistService.GetDefaultValues());
    const [loading, setLoading] = useState(false);
    const [attractionsNames, setAttractionsNames] = useState<AttractionNameDto[]>([]);
    const [departmentNames, setDepartmentNames] = useState<DepartmentNameDto[]>([]);
    const navigate = useNavigate()

    const urlIdRegex = new RegExp(`/${id}$`)
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid

    const handleBackClick = () => {
      //remove id param from end of the url
      navigate({ pathname: window.location.pathname.replace(urlIdRegex, '') });
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
            if(id !== undefined && guidRegex.test(id)){
              const data = await InspectionChecklistService.Get(id);
              setFormValues(data);
            } else {
              //navigate to create page
              navigate({ pathname: window.location.pathname.replace(urlIdRegex, '/new')})
            }
        } catch (error: any) {
          if(error.response.status === 404){
            //navigate to create page
            navigate({ pathname: window.location.pathname.replace(urlIdRegex, '/new')})
          }else {
            setMessageItem({error: error})
          }
        }
      }
      fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
      const fetchNames = async () => {
        try {
          const attractionsData = await AttractionService.GetNames();
          setAttractionsNames(attractionsData);
          const departmentData = await DepartmentService.GetNames();
          setDepartmentNames(departmentData);
        } catch (error: any) {
          setMessageItem({ error: error });
        }
      };
      fetchNames();
    }, []);

    const save = async (values: any) => {
      setLoading(true);
      try {
          if(id === "new"){
              //create
              const response = await InspectionChecklistService.Create(values);
              if(response.status >= 200 && response.status <= 299){
                setMessageItem({successMessage: "Inspection Checklist Created Successfully!"})
              }
              //Update url
              const regex = new RegExp(`/${id}`);
              navigate({pathname: window.location.pathname.replace(regex, `/${response.data.id}`)})
          } else if(id !== undefined){
              //Update
              const response = await InspectionChecklistService.Update(id, values)
              if(response.status >= 200 && response.status <= 299){
                setMessageItem({successMessage: "Inspection Checklist Updated Successfully!"})
              }
              setFormValues(values);
          }
          formik.resetForm();
      } catch(error: any) {
        setMessageItem({error: error})
      } finally {
        setLoading(false);
      }
    }

    

    function ToggleEnabledSwitch() {
      const [open, setOpen] = useState(false);

      const handleSaveToggleEnable = async () => {
        try {
            if(id !== undefined){
                if(!formik.values.enabled){
                    //enable
                    await InspectionChecklistService.Enable(id)
                    setMessageItem({successMessage: "Inspection Checklist Activated Succesfully!"})
                } else {
                    //disable
                    await InspectionChecklistService.Disable(id)
                    setMessageItem({successMessage: "Inspection Checklist Deactivated Succesfully!"})
                }
            }
        } catch (error: any){
            setMessageItem({error: error});
        }
      }

      const handleClick = () => {
        formik.setFieldValue("enabled", !formik.values.enabled);
        handleSaveToggleEnable();
        setOpen(false);
      }
      return (
        <>
          <FormControlLabel 
            control={
            <Switch 
                checked={formik.values.enabled}
                onChange={() => setOpen(true)}  
            />
            }
            label={formik.values.enabled ? "Active" : "Inactive"}
          />
          <Dialog
            open={open}
            onClose={()=> setOpen(false)}
          >
            <DialogTitle>
            {formik.values.enabled ? 'Deactivate' : 'Activate'} Inspection Checklist?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {formik.values.enabled ? 'deactivate' : 'activate'} {formik.values.label}?
                New inspections cannot be started for this checklist once it has been deactivated.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={handleClick}>{formik.values.enabled ? 'Deactivate' : 'Activate'}</Button>
            </DialogActions>
          </Dialog>
        </>
      )
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
          save(values);
        }
    })

    return (
      <>
        <Paper sx={{padding: "1rem"}}>
          <form onSubmit={e => 
              {
                e.preventDefault(); 
                !formik.isValid && console.log("errors:", JSON.stringify(formik.errors));
                formik.isValid ? formik.handleSubmit(e) : formik.validateForm();
              }}
          >
            <Stack direction="row" sx={{display: "flex", justifyContent: "space-between"}}>
              <Typography variant="h5">{id === "new" ? "New" : "Edit"} Inspection Checklist</Typography>
              <Tooltip title="Go Back">
                  <IconButton onClick={handleBackClick}><ArrowBackIcon fontSize='large'/></IconButton>
              </Tooltip>
            </Stack>
            <Stack className="settings">
              <QuestionTitle required title="Name" />
              <TextField
                id="label"
                name="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                variant="outlined"
                size="small"
                sx={{ width: "20rem" }}
              />
              
              <QuestionTitle title="Description" />
              <TextField
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                size="small"
                multiline
                minRows={2}
                maxRows={3}
                sx={{ width: "20rem" }}
                
              />
              <QuestionTitle required title="Department" />
              <Autocomplete
                disablePortal
                id="department"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                options={departmentNames}
                size="small"
                sx={{ width: "20rem" }}
                value={
                  formik.values.department
                    ? {
                        id: formik.values.department.id,
                        label: formik.values.department.label,
                      }
                    : null
                }
                onChange={(_, value: any) =>{
                  formik.setFieldValue("department", value ? { id: value.id, label: value.label } : null)
                  formik.setFieldValue("departmentId", value && value.id)
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="department.label"
                    error={
                      formik.touched.department &&
                      Boolean(formik.errors.department)
                    }
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                  />
                )}
              />
              <QuestionTitle required title="Attraction" />
              <Autocomplete
                disablePortal
                id="attraction"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                options={attractionsNames}
                size="small"
                sx={{ width: "20rem" }}
                value={
                  formik.values.attraction
                    ? {
                        id: formik.values.attraction.id,
                        label: formik.values.attraction.label,
                      }
                    : null
                }
                onChange={(_, value: any) =>{
                  formik.setFieldValue("attraction", value ? { id: value.id, label: value.label } : null)
                  formik.setFieldValue("attractionId", value && value.id)
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="attraction.label"
                    error={
                      formik.touched.attraction &&
                      Boolean(formik.errors.attraction)
                    }
                    helperText={
                      formik.touched.attraction && formik.errors.attraction
                    }
                  />
                )}
                
              />
              <QuestionTitle required title="Expiry Policy" />
              <ExpirySelector formik={formik}/>

              {id !== "new" && (
                <>
                  <QuestionTitle title="Inspection Checklist Status"/>
                  <ToggleEnabledSwitch/>
                </>
                  
              )}
            </Stack>
            <Stack direction="row" sx={{display: "flex", justifyContent: "space-between", padding:"1rem"}}>
              {id !== "new" && (
                <Accordion sx={{width: "20rem"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="additional-info"
                    >
                    <Typography>Modification Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {formik.values.createdBy && (
                        <Typography>
                            Created By: {formik.values.createdBy}
                        </Typography>
                        )}
                        {formik.values.createdOn && (
                        <Typography>
                            Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                        {formik.values.modifiedBy && (
                        <Typography>
                            Modified By: {formik.values.modifiedBy}
                        </Typography>
                        )}
                        {formik.values.modifiedOn && (
                        <Typography>
                            Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                        </Typography>
                        )}
                    </AccordionDetails>              
                </Accordion>
              )}
                
              <div style={{flexGrow: 1, textAlign:"right"}}>
                  <LoadingButton  type='submit' variant="contained" color='primary' size="large" loading={loading}><span>Save</span></LoadingButton>
              </div>
            </Stack>
            
          </form>
            
        </Paper>
        {id !== "new" && id !== undefined && (
          <div style={{paddingTop: "1.5rem"}}>
            <ChecklistVersions
              inspectionChecklistId={id}
            />
          </div>
          
        )}
        
        <ErrorHandlingSnackbar messageItem={messageItem} />
    </>
  )
}

export default InspectionChecklistForm