import { AttendanceSheetResponse } from "./AttendanceSheetResponse.dto";

export enum LearningDocumentResponseStatus {
    Completed,
    InProgress,
    Unanswered,
    Competent,
    NotYetCompetent,
    AwaitingSignature,
    AwaitingSubmission,
    AwaitingOutcome
}

export const LearningDocumentResponseStatusString: string[] = [
    "Completed",
    "In Progress",
    "Unanswered",
    "Competent",
    "Not Yet Competent",
    "Awaiting Signature",
    "Awaiting Submission",
    "Awaiting Outcome"
]
export interface LearningDocumentResponseDto {
    id: string;
    expiry?: Date;
    startedTime?: Date;
    startedBy?: string;
    completedTime?: Date;
    completedBy?: string;

    learningDocumentVersionId: string;
    status: LearningDocumentResponseStatus; //Complete, Inprogress, Competent, Not Yet Competent
    competent?: boolean;
    attendanceSheetResponseId: string;
    attendanceSheetResponse: AttendanceSheetResponse
    topicResponses: LearningDocumentTopicResponse[];
    preparationChecklistResponse?: LearningDocumentSubtopicResponse;
}

export interface LearningDocumentTopicResponse {
    id: number; 
    competent?: boolean; //used for CTA/CTC. true = Competent, false = Not Yet Competent, null = unanswered
    commentary?: string;
    startedTime?: Date;
    completedTime?: Date;
    subtopicResponses: LearningDocumentSubtopicResponse[];
}

export interface LearningDocumentSubtopicResponse {
    id: number;
    pointResponses: LearningDocumentPointResponse[];
}

export interface LearningDocumentPointResponse {
    id: number; 
    checked: boolean;
    tallyValue?: number;
    timeAnswered?: Date;
    answeredBy?: string;
    answeredById?: string;
    subpointResponses: LearningDocumentSubpointResponse[];
}

export interface LearningDocumentSubpointResponse {
    id: number;
    checked: boolean;
    timeAnswered?: Date;
    answeredBy?: string;
    answeredById?: string;
}

export interface StartDocumentRequestDto {
    publishedVersionId: string;
    trainerIds: string[];
    traineeIds: string[];
    responseId?: string;
}

export enum ComptencyDocumentStatus {
    InProgress,
    NotYetCompetent,
    Unattempted,
    Rescinded,
    completed
}
export const CompetencyDocumentStatusString: string[] = [
    "In Progress",
    "Not Yet Competent",
    "Unattempted",
    "Rescinded",
    "Competent"
]

export interface CompetencyDocumentDto {
    id: string;
    label: string;
    publishedVersionId: string;
    status: ComptencyDocumentStatus;
    responseId?: string;
}