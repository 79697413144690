import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, Modal, Paper, Popover, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { FormikErrors, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { Status } from 'src/dtos/Statuses'
import * as yup from "yup";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService';
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService';
import { LearningDocument, LearningDocumentSubtopic, LearningDocumentTopic, LearningDocumentVersion } from 'src/dtos/Training/LearningDocument.dto';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import { ChecklistVersionName } from 'src/dtos/Checklists/MaintenanceChecklist.dto';
import PreviewIcon from '@mui/icons-material/Preview';
import QRCode from 'react-qr-code';
import "./styles.scss";
import { FocusElement } from 'src/Utils/helperFunc';
import { LoadingButton } from '@mui/lab';
import ReorderIcon from '@mui/icons-material/Reorder';
import { DropResult } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import DraggableList, { DraggableListItem } from 'src/components/DraggableList/DraggableList';
import TopicCreator from './Components/TopicCreator/TopicCreator';
import { reorder } from './Components/PointsCreator/helper';
import PreparationSection from './Components/PreparationSection/PreparationSection';

const validationSchema = yup.object({
    versionLabel: yup.string().max(50, "Version name cannot exceed 50 characters").required("Version Name is required"),
    versionDescription: yup.string().max(1000, "Version description cannot exceed 1000 characters").required("Version Description is required"),
    topics : yup.array()
        .of(
            yup.object().shape({
                label: yup.string().max(75, "Topic title cannot exceed 75 characters").required("Topic title is required"),
                estimateCompletionMinutes: yup.number().min(1, "Estimate completion time must be at least one minute").notRequired(),
                subTopics: yup.array()
                    .of(
                        yup.object().shape({
                            label: yup.string().max(50, "Subtopic title cannot exceed 50 characters").required("Subtopic title is required"),
                            points: yup.array()
                                .of(
                                    yup.object().shape({
                                        label: yup.string().max(255, "Point title cannot exceed 255 characters").required("Point title requried"),
                                        type: yup.number().required("Point type is required"),
                                        examples: yup.string().notRequired(),
                                        subpoints: yup.array()
                                            .of(
                                                yup.object().shape({
                                                    label: yup.string().max(255, "Subpoint title cannot exceed 255 characters").required("Subpoint title required")
                                                })
                                            )
                                    })
                                )
                        })
                    )
            })
        )

});

function LearningDocumentCreator() {
    const [formValues, setFormValues] = useState<LearningDocumentVersion>(LearningDocumentVersionService.GetDefaultVersionValues())
    const [learningDocumentValues, setLearningDocumentValues] = useState<LearningDocument>(LearningDocumentService.GetDefaultValues())
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined, });
    const [showChanges, setShowChanges] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [reorderTopicsOpen, setReorderTopicsOpen] = useState(false);


    const [versionNames, setVersionNames] = useState<ChecklistVersionName[]>([])

    const [anchorElCopyVersion, setAnchorElCopyVersion] = useState<HTMLButtonElement | null>(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const {learningDocumentId, versionId} = useParams();
    const urlVersionIdRegex = new RegExp(`/${versionId}$`)
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
          save(values);
        }
    });

    const save = async (values: LearningDocumentVersion) => {
        setLoading(true)
        if(!formEditable){
            setMessageItem({errorMessage: "You cannot save a learning document unless it is a draft"})
        }

        if(learningDocumentId !== undefined && guidRegex.test(learningDocumentId)){
            values.learningDocumentId = learningDocumentId;
        }

        try {
            if(versionId === "new"){
                //create
                const response = await LearningDocumentVersionService.Create(values);
                if(response.status >= 200 && response.status <= 299){
                    setMessageItem({successMessage: "Version Created Successfully!"})
                }

                //Update url
                navigate({pathname: window.location.pathname.replace(urlVersionIdRegex, `/${response.data.id}`)})
            } else if (versionId !== undefined) {
                //Update
                const response = await LearningDocumentVersionService.Update(versionId, values)
                if(response.status >= 200 && response.status <= 299) {
                    setMessageItem({successMessage: "Version Updated Successfully!"})
                    setFormValues(response.data)
                    formik.setValues(response.data)
                }
            }
        } catch(error: any) {
            setMessageItem({error: error})
        } finally {
            setLoading(false)
        }

    }

    const handleBackClick = () => {
        //remove id param from end of the url
        if(formik.dirty && formik.values.status === Status.Draft){
          if(!window.confirm("You have unsaved changes, are you sure you want to go back?")){
            return
          }
        } 
    
        navigate({ pathname: window.location.pathname.replace(urlVersionIdRegex, '') });
        
    }

    const handleCopyVersionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElCopyVersion(event.currentTarget);
    }

    const handleStartNewClick = () => {
        formik.setFieldValue("topics", [LearningDocumentVersionService.GetDefaultTopicValues()])
    }

    function CopyVersionSelector() {
    
        const handleClose = () => {
          setAnchorElCopyVersion(null)
        }
        const [selectedVersion, setSelectedVersion] = useState<ChecklistVersionName | null>(null)
        
        const handleClick = async () => {
          //Get copy
          try {
            if(selectedVersion && selectedVersion.id){
              const copy: LearningDocumentVersion = await LearningDocumentVersionService.GetCopy(selectedVersion.id);
              formik.setValues(copy)
            }
          } catch (error: any) {
            setMessageItem({error: error})
          }
        }
    
        const open = Boolean(anchorElCopyVersion);
        return (
          <>
            <Popover
              open={open}
              anchorEl={anchorElCopyVersion}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
            
            >
              <Box>
                <Stack sx={{padding: "0.5rem"}} spacing={2}>
                  <Typography sx={{textAlign:"center"}} variant="h6">Select Version</Typography>
                  <Autocomplete
                    disablePortal={false}
                    id="version"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => (option.versionLabel ? option.versionLabel : "")}
                    options={versionNames}
                    size="small"
                    sx={{ width:" 15rem"}}
                    onChange={(_, value) => setSelectedVersion(value)}
                    value={selectedVersion}
                    renderInput={(params) => 
                    <TextField {...params} />}
                  />
                  <div  style={{display:"flex", justifyContent:"center"}}>
                    <Button onClick={handleClick}>Copy Version</Button>
                  </div>
                  
                </Stack>
                
              </Box>
            </Popover>
          </>
        )
    }

    const ReorderTopicsModal = () => {

        const style = {
            margin: "auto",
            marginTop: "10rem",
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: "1rem",
            boxShadow: 24,
            p: 4,
        };

        const handleClose = () => {
            setReorderTopicsOpen(false);
        }

        const onDragEnd = (result: DropResult) => {
            const {destination, source} = result;
            //dropped outside the list
            if(!destination) return;

            //reorder topics
            let newTopics = reorder(formik.values.topics, source.index, destination.index)
            //set new sortorder
            newTopics = newTopics.map((topic, index) => {
                return {...topic, sortOrder: index}
            })

            formik.setFieldValue("topics", newTopics)
        }

        return (
            <>
                <Tooltip title="Reorder Topics">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton tabIndex={-1} onClick={() => setReorderTopicsOpen(true)}>
                            <ReorderIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Modal
                    open={reorderTopicsOpen}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Stack direction="row" spacing={1} sx={{display:"flex", justifyContent:"space-between"}}>
                            <Typography variant='h6'>Reorder Topics</Typography>
                            <div>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        </Stack>
                        <DraggableList
                            onDragEnd={onDragEnd}
                            droppableId='droppable-list-topics'
                        >
                            {formik.values.topics.map((topic, index) => (
                                <DraggableListItem
                                    draggableId={`topic_${index}`}
                                    index={index}
                                    key={index}
                                >
                                    {(provided) => (
                                        <Stack direction={"row"}>
                                            <div 
                                                {...provided.dragHandleProps} 
                                                style={{ display: "flex", alignItems: "center", marginRight: '8px' }}
                                                >
                                                <DragIndicatorIcon/>
                                            </div>
                                            <Typography>{topic.label === "" ? `Topic ${index + 1}` : topic.label}</Typography>
                                        </Stack>
                                    )}
                                </DraggableListItem>
                            ))}
                        </DraggableList>
                    </Box>
                </Modal>
            </>
        )
    }

    const [formErrors, setFormErrors] = useState<JSX.Element | null>(null)
    const handleSaveErrors = () => {

        let errors: JSX.Element[] = [];
        let topicsErrors = formik.errors.topics

        //Loop sections for errors
        if(topicsErrors === undefined){
            setFormErrors(null);
            return
        }
        
        for(let i = 0; i < topicsErrors.length; i++) {
            const topicErrors = topicsErrors[i] as FormikErrors<LearningDocumentTopic>;
            if(topicErrors === undefined){
                continue
            }

            const topicAllErrors: JSX.Element[] = [];
            
            topicAllErrors.push(<Typography variant="h6">{`Topic: ${formik.values.topics[i].label === "" ? `Number ${i+1}` : formik.values.topics[i].label}`}</Typography>)
            if(topicErrors.label !== undefined){
                topicAllErrors.push(<Typography color="red">{topicErrors.label}</Typography>)
            }
            
            
            
            errors = [...errors, ...topicAllErrors]

            const subtopicsErrors = topicErrors.subtopics;
            if(subtopicsErrors !== undefined){
                for(let j = 0; j < subtopicsErrors.length; j++){
                    const subtopicErrors = subtopicsErrors[j] as FormikErrors<LearningDocumentSubtopic>;
                    if(subtopicErrors === undefined){
                        continue
                    }

                    const subtopicAllErrors: JSX.Element[] = [];
                    if(subtopicErrors.label !== undefined){
                        subtopicAllErrors.push(<Typography color="red">{`Subtopic ${j+1}: ${subtopicErrors.label}`}</Typography>)
                    }
                    
                    errors = [...errors, ...subtopicAllErrors]

                    //TODO: finish errors for points and subpoints
                }
            }
        }
    
        setFormErrors(<div>{errors}</div>)
        if(errors.length > 0){
            setMessageItem({errorMessage: "Form validation errors please view errors at bottom of page"})
        }
    
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSaveClick = () => {
        if(formEditable){
            if(formik.isValid){
                formik.handleSubmit()
                setFormErrors(null)
            } else {
                formik.validateForm();
                handleSaveErrors()
            }
        } 
    }

    const handlePublishClick = () => {
        setPublishAlertDialogOpen(true);
    }
    
      const [publishAlertDialogOpen, setPublishAlertDialogOpen] = useState(false);
    
      function PublishAlertDialog() {
        const handleClick = () => {
          handlePublish();
          setPublishAlertDialogOpen(false);
        }
        return (
          <Dialog
            open={publishAlertDialogOpen}
            onClose={()=> setPublishAlertDialogOpen(false)}
          >
            <DialogTitle>
                Publish Learning Document Version?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to publish this version?
                Once it has been published it can no longer be modified and will be the version used for any unstarted training sessions or assesments.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPublishAlertDialogOpen(false)}>Cancel</Button>
              <Button variant={"contained"} onClick={handleClick}>Publish</Button>
            </DialogActions>
          </Dialog>
        )
      }
      const handlePublish = async () => {
        setLoading(true);
        //if there are unsaved changes, save the checklist before publishing
        if(formik.dirty){
          handleSaveClick();
        }
    
        //set as published
        try {
          if(versionId !== undefined && guidRegex.test(versionId)){
            const response = await LearningDocumentVersionService.Publish(versionId);
            if(response.status >= 200 && response.status <= 299){
              setMessageItem({successMessage: "Version Published Successfully!"})
              formik.setFieldValue("status", Status.Published);
            }
          }
        } catch (error: any) {
          setMessageItem({error: error})
        } finally {
          setLoading(false)
        }    
      }

    useEffect(() => {
        const fetchLearningDocument = async () => {
            try {
                if(learningDocumentId !== undefined && guidRegex.test(learningDocumentId)){
                    const data = await LearningDocumentService.Get(learningDocumentId);
                    setLearningDocumentValues(data)
                }
            } catch (error: any) {
                setMessageItem({error: error})
            }
        }

        const fetchLearningDocumentVersion = async () => {
            try {
                if(versionId !== undefined && guidRegex.test(versionId)){
                    const data = await LearningDocumentVersionService.Get(versionId);
                    setFormValues(data);
                } 
            } catch (error: any) {
                setMessageItem({error: error})
            }
        }

        const fetchVersionNames = async () => {
            try {
                if(learningDocumentId !== undefined && guidRegex.test(learningDocumentId)){
                    const data = await LearningDocumentVersionService.GetNames(learningDocumentId);
                    setVersionNames(data);

                    //if there are no previous versions then user should not be able to copy from another version
                    if(data.length === 0){
                        formik.setFieldValue("topics", [LearningDocumentVersionService.GetDefaultTopicValues()])
                    }
                }
            } catch (error: any) {
                setMessageItem({error: error})
            }
        }

        fetchLearningDocument();
        fetchLearningDocumentVersion();
        fetchVersionNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningDocumentId, versionId])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if(e.repeat){
                //prevent function from firing continuously
                return
            }

            if((e.key === "S" || e.key === "s") && (e.ctrlKey || e.metaKey)){
                e.preventDefault(); //stop page from downloading
                if(!loading){
                    handleSaveClick();
                }
            }

            
        }

        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            event.returnValue = true; //Legacy support
        }

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    })

    function DemoQRCode(){
        const demoUrl = `${window.location.origin}/user/DemotrainingDocuments/${versionId}`
        return (
          <Modal
            open={previewOpen}
            onClose={() => setPreviewOpen(false)}
          >
            <div className="modalBox">
              <Typography variant="h5" sx={{paddingBottom: "2rem", textAlign: "center"}}>Demonstration</Typography>
              <div style={{cursor:"pointer"}} onClick={() => window.open(demoUrl)}>
                <Stack>
                  <QRCode value={demoUrl}/>
                  <Button variant="contained" sx={{marginTop:"1rem"}}>Open Demonstration</Button>
                </Stack>
              </div>
              
            </div>
          </Modal>
        )
    }

    const handleAddTopic = () => {
        let newTopics = [
            ...formik.values.topics, 
            {...LearningDocumentVersionService.GetDefaultTopicValues(), sortOrder: formik.values.topics.length} 
        ];

        formik.setFieldValue(`topics`, newTopics)

        //move focus to new topic
        setTimeout(() => {
            FocusElement(-6);
          }, 100);
    }

    const formEditable = formik.values.status === Status.Draft;
    return (
        <>
            <form onSubmit={e =>
                {
                e.preventDefault(); 
                !formik.isValid && console.log("errors:", JSON.stringify(formik.errors));
                formik.isValid ? formik.handleSubmit(e) : formik.validateForm();
            }}    
            >
                {formik.values.topics && formik.values.topics.length === 0 ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Paper style={{display: "block", maxWidth: "fit-content", padding:"1rem", paddingTop:"0"}}>
                            <QuestionTitle title="How are you starting your Learning Document?"/>
                            <Stack spacing={1}>
                                <Button onClick={handleCopyVersionClick}>Copy another Version</Button>
                                <Button onClick={handleStartNewClick}>Start New</Button>
                            </Stack>
                        </Paper>
                        <CopyVersionSelector/>
                    </div>
                ) : (
                    <Paper>
                        <div style={{padding:"1rem"}}>
                            <Stack sx={{padding: "1rem"}}>
                                <Stack direction="row" sx={{display: "flex", justifyContent: "space-between"}}>
                                    <Typography variant="h5">{versionId === "new" ? "New" : formik.values.status === Status.Draft ? "Edit" : "View"} Version - {learningDocumentValues.label} {formik.values.versionLabel && ` - ${formik.values.versionLabel}`}</Typography>
                                    <Tooltip title="Go Back">
                                        <IconButton onClick={handleBackClick}><ArrowBackIcon fontSize='large'/></IconButton>
                                    </Tooltip>
                                </Stack>
                                {formEditable ? (
                                    <Stack>
                                        <QuestionTitle required title="Name" infoText="Name of the version not the name of the learning document"/>
                                        <TextField
                                            id="versionLabel"
                                            name="versionLabel"
                                            value={formik.values.versionLabel}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.versionLabel && Boolean(formik.errors.versionLabel)}
                                            helperText={formik.touched.versionLabel && formik.errors.versionLabel}
                                            variant="outlined"
                                            size="small"
                                            sx={{width: "20rem"}}
                                        />
                                        <QuestionTitle required title="Description" infoText="This description is the reason why this version has been created, not the purpose of the learning document."/>
                                        <TextField
                                            id="versionDescription"
                                            name="versionDescription"
                                            value={formik.values.versionDescription}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.versionDescription && Boolean(formik.errors.versionDescription)}
                                            helperText={formik.touched.versionDescription && formik.errors.versionDescription}
                                            variant="outlined"
                                            size="small"
                                            sx={{width: "20rem"}}
                                        />
                                    </Stack>
                                ) : (
                                    <>
                                        <QuestionTitle title="Description" infoText="This description is the reason why this version has been created, not the purpose of the learning document."/>
                                        <Typography sx={{marginTop: "-0.5rem", marginBottom: "0.5rem"}} variant="body2">{formik.values.versionDescription}</Typography>
                                    </>
                                )}
                                <div>
                                    <QuestionTitle title="Force Topic Workflow" required infoText="Toggled ON: Topics must be completed in order. Togged OFF: Topics can be completed out of order."/>                   
                                    <Switch
                                        name={`forceWorkflow`}
                                        checked={Boolean(formik.values.forceWorkflow)}
                                        onChange={() => formik.setFieldValue(`forceWorkflow`, !formik.values.forceWorkflow)}
                                        disabled={!formEditable}
                                    />
                                </div>
                            </Stack>
                            <Stack direction="row" spacing={4} sx={{paddingBottom: "1rem", marginLeft: "1rem", display: "flex", alignItems: "center"}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                showChanges
                                            }
                                            onChange={() =>
                                                setShowChanges(!showChanges)
                                            }
                                        />
                                    }
                                    label="Compare to Previous Version"
                                />
                                
                                <Tooltip title="Preview Checklist">
                                    <IconButton onClick={()=> setPreviewOpen((prevState) => !prevState)}>
                                        <PreviewIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                                <ReorderTopicsModal/>
                                
                            </Stack>
                            
                            
                            <Divider sx={{marginBottom: "2rem"}}/>

                        </div>
                        
                        <PreparationSection formik={formik}/>
                            

                        {formik.values.topics && (
                            <>
                          
                                {formik.values.topics.map((topic, index) => (
                                    <TopicCreator
                                        key={index}
                                        formik={formik}
                                        topicIndex={index}
                                        published={formik.values.status === Status.Published}
                                    /> 
                                ))}
                            </>
                        )}
                        {formEditable && (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={handleAddTopic}>Add Topic</Button>
                            </div>
                        )}
                        <Stack direction="row" spacing={2} sx={{padding: "1rem", display: "flex", justifyContent: "end"}}>
                            <LoadingButton variant="contained" onClick={handleSaveClick} disabled={!formEditable || !formik.dirty} loading={loading}>Save</LoadingButton>
                            <LoadingButton variant="contained" loading={loading} onClick={handlePublishClick}>Publish</LoadingButton>
                        </Stack>
                        {formErrors && (
                            <div style={{display:"flex", justifyContent:"center", alignContent: "center"}}>
                                <Paper sx={{padding: "1rem", width: "35rem", overflowWrap: "break-word", marginBottom: "2rem"}}>
                                <Typography variant="h5" sx={{textAlign:"center"}}>Form Errors</Typography>
                                    {formErrors}
                                </Paper>
                            </div>
                        )}
                    </Paper>
                )}
            </form>

            <DemoQRCode/>
            <PublishAlertDialog/>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
        
    )
}

export default LearningDocumentCreator