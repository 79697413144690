import { Box, Chip, CircularProgress, CircularProgressProps, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { InspectionChecklistSection, QuestionType } from 'src/dtos/AwTypes'
import { InspectedComponentDto, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { NameNumberDto } from 'src/dtos/NameDto.dto'
import { Status, StatusString } from 'src/dtos/Statuses'
import { GetQuestionStatus, GetQuestionVisible, GetSectionStatus, GetSectionStatusColour } from 'src/pages/User Site/Inspections/InspectionV2/CalculateStatusesHelper'
import QuestionRowYesOrNo from './QuestionRowYesOrNo'

type Props = {
    sectionContent: InspectionChecklistSection
    sectionResponse: InspectionChecklistSectionResponseDto
    inspectedComponents: InspectedComponentDto[]
    selectedComponents: NameNumberDto[]
}
function SectionTable({sectionContent, sectionResponse, inspectedComponents, selectedComponents} : Props) {
    const totalQuestions = sectionResponse.questionResponses.filter((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], inspectedComponents, selectedComponents) !== Status.NA).length;
    const passedQuestions = sectionResponse.questionResponses.filter((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], inspectedComponents, selectedComponents) === Status.Passed).length;
    

    function QuestionProgress(props: CircularProgressProps & { value: number }) {
        const progressColour = passedQuestions === totalQuestions ? "success" : sectionStatus === Status.Failed ? "error" : "primary";
        return (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress variant="determinate" {...props} color={progressColour} size="3rem"/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${passedQuestions}/${totalQuestions}`}</Typography>
                </Box>
            </Box>
        );
    }

    const sectionStatus = GetSectionStatus(sectionResponse, sectionContent, inspectedComponents, selectedComponents)
    const statusColour = GetSectionStatusColour(sectionStatus)

    return (
        <div style={{padding:"1rem"}}>
            <Stack direction="row" sx={{ width: "100%", marginRight:"1rem", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant='h5'>{sectionContent.label}</Typography>
                <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center"}}>
                    <Chip
                        label={StatusString[sectionStatus]}
                        color={statusColour}
                    />
                    <QuestionProgress 
                        value={(passedQuestions/totalQuestions)*100}  
                    />          
                </Stack>
            </Stack>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Question
                        </TableCell>
                        <TableCell>
                            Answer
                        </TableCell>
                        <TableCell>
                            Impacts Operation?
                        </TableCell>
                        <TableCell>
                            Journal Entry
                        </TableCell>
                        <TableCell>
                            Answered By
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sectionContent.questions.map((questionContent, questionIndex) => (
                        <Fragment key={questionIndex}>
                            {GetQuestionVisible(questionContent, selectedComponents) && 
                            questionContent.type === QuestionType.YesOrNo && 
                                (
                                    <QuestionRowYesOrNo
                                        questionContent={questionContent}
                                        questionResponse={sectionResponse.questionResponses[questionIndex]}
                                        inspectedComponents={inspectedComponents}
                                        selectedComponents={selectedComponents}
                                    />
                                )
                            }
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default SectionTable