import dayjs from "dayjs";
import { InspectionChecklist } from "src/dtos/Checklists/MaintenanceChecklist.dto";
import { ListParameters, SortDirection } from "../../ListParameters";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { PublishedNameDto } from "src/dtos/PublishedName.dto";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/InspectionChecklist";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class InspectionChecklistService {
    static GetDefaultValues(){
        const defaultInspectionChecklistValues: InspectionChecklist ={
            id: "00000000-0000-0000-0000-000000000000",
            label: "",
            description: "",
            expiry: {
                type: "",
                afterValue: null,
                afterUnits: "",
                everyUnit: "",
                everyWeekdayValue: "",
                everyMonthValue: "",

                everyTimeValue: dayjs().startOf("day").format("HH:mm"),
            },
            departmentId: undefined, 
            department: null,
            enabled: true,
            attractionId: undefined
        }
        return defaultInspectionChecklistValues;
    }

    static async Get(id: string): Promise<InspectionChecklist> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<InspectionChecklist> = await axios.get<InspectionChecklist>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: bearer
                },
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, departmentId?: string, attractionId?: string, locationId?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    departmentId: departmentId,
                    attractionId: attractionId,
                    locationId: locationId,
                    includeDisabled: includeDisabled                
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetPublishedList(listParam?: ListParameters, searchTerm?: string, departmentId?: string, attractionId?: string, locationId?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetPublishedList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    departmentId: departmentId,
                    attractionId: attractionId,
                    locationId: locationId             
                }
            });
            return response.data;
        } catch (error: any) {
            if(error.response !== undefined){
                if(error.response.status != null && error.response.status === 404){
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error;
        }
    }

    static async GetPublishedNames()
    {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<PublishedNameDto[]> = await axios.get<PublishedNameDto[]>(`${apiBaseUrl}/GetPublishedNames`, {
                headers: {
                    Authorization: bearer
                }
            })
            return response.data
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(checklistData: InspectionChecklist): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,
                checklistData, 
                {
                    headers: {
                        Authorization: bearer
                    },
                }
            );
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, checklistData: InspectionChecklist): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, checklistData, {
                headers: {
                    Authorization: bearer
                },
            }
            );
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}