import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Tab, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LearningAreaCreator from './Components/LearningAreaCreator/LearningAreaCreator'
import MaterialCreator from './Components/LearningMaterialCreator/MaterialCreator'
import { useFormik } from 'formik'
import * as yup from "yup";
import TrainingGroupService from 'src/Services/Training/TrainingGroupService'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService'
import LearningItemService from 'src/Services/Training/LearningItemService'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { useMutation, useQuery } from '@tanstack/react-query'
import { guidRegex } from 'src/Utils/helperFunc'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { TrainingGroup, TrainingGroupType, TrainingGroupTypeString } from 'src/dtos/Training/TrainingGroup.dto'
import { ExpandMore } from '@mui/icons-material'
import { dateOptions } from 'src/config/constants'
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService'
import EditIcon from '@mui/icons-material/Edit';
import TrainingGroupParents from './Components/TrainingGroupParents'
import OnlineLearningPrerequisites from './Components/OnlineLearningPrerequisites/OnlineLearningPrerequisites'


const validationSchema = yup.object({
    learningAreas: yup.array()
        .of(
            yup.object().shape({
                areaName: yup.object({
                    label: yup.string()
                }).required(),
                
            })
        )

})

function TrainingGroupCreator() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})
    const [editTrainingGroupName, setEditTrainingGroupName] = useState(false)
    const {id} = useParams();
    const urlIdRegex = new RegExp(`/${id}$`)
    const navigate = useNavigate()
    const [openTab, setOpenTab] = useState<"Training Group" | "Online Learning Prerequisites">("Training Group")

    //#region queries
    const learningDocsQuery = useQuery({
        queryKey: ["learningDocumentNames"],
        queryFn: async () => {
            return await LearningDocumentService.GetNames();
        }        
    })

    const learningItemsQuery = useQuery({
        queryKey: ["learningItemNames"],
        queryFn: async () => {
            return await LearningItemService.GetNames();
        }
    })

    const trainingGroupQuery = useQuery({
        queryKey: ["trainingGroup", id],
        queryFn: async () => {
            if(id !== undefined && guidRegex.test(id)){
                return await TrainingGroupService.Get(id);
            }
            return null
        }
    })
    
   const trainingGroupParentNamesQuery = useQuery({
    queryKey: ["trainingGroupParentNames"],
    queryFn: async () => {
        return await TrainingGroupParentService.GetNames();
    }
   })

    //HandleQuery Errors
    useEffect(() => {
        if(trainingGroupQuery.isError){
            setMessageItem({error: trainingGroupQuery.error})
        }
        if(learningDocsQuery.isError){
            setMessageItem({error: learningDocsQuery.error})
        }
        if(learningItemsQuery.isError){
            setMessageItem({error: learningItemsQuery.error})
        }
        if(trainingGroupParentNamesQuery.isError){
            setMessageItem({error: trainingGroupParentNamesQuery.error})
        }
        
    }, [learningDocsQuery.error, learningDocsQuery.isError, learningItemsQuery.error, learningItemsQuery.isError, trainingGroupParentNamesQuery.error, trainingGroupParentNamesQuery.isError, trainingGroupQuery.error, trainingGroupQuery.isError])

    const createMutation = useMutation({
        mutationFn: (values:TrainingGroup) => {
            return TrainingGroupService.Create(values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Group created successfully!"})
            
            //Update Url
            navigate({pathname: window.location.pathname.replace(urlIdRegex, `/${data.data.id}`)})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: string, values: TrainingGroup}) => {
            return TrainingGroupService.Update(id, values)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Training Group updated successfully!"})
            formik.setValues(data.data)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    //#endregion

    const save = async (values: TrainingGroup) => {
        if(id === "new"){
            //Create
            createMutation.mutate(values)
        } else if(id !== undefined && guidRegex.test(id)){
            //update
            updateMutation.mutate({id, values})
        }
    }

    const formik = useFormik({
        enableReinitialize: true, 
        validationSchema: validationSchema,
        initialValues: trainingGroupQuery.data ?? TrainingGroupService.GetDefaultValues(),
        onSubmit: (values) => {
            save(values);
          }
    })

    const handleBack = () => {
        navigate({ pathname: window.location.pathname.replace(urlIdRegex, '') });
    }

    const handleSaveClick = () => {
        if(formik.isValid){
            formik.handleSubmit()
        } else {
            formik.validateForm();
            console.log(formik.errors)
        }
    }

    const handleEditNamesClose = () => {
        setEditTrainingGroupName(false)
        trainingGroupParentNamesQuery.refetch()
    }

    if(trainingGroupQuery.isLoading || learningDocsQuery.isLoading || learningItemsQuery.isLoading){
        return <>Loading...</>
    }

    return (
        <>
            <Paper>
                <TabContext value={openTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(_, value) => setOpenTab(value)}>
                            <Tab label="Training Groups" value={"Training Group"} />
                            {id && guidRegex.test(id) && (
                                <Tab label="Online Learning Prerequisites" value={"Online Learning Prerequisites"}/>
                            )}
                        </TabList>
                    </Box>
                    <TabPanel value={"Training Group"}>
                    <Stack direction="row" sx={{display:"flex", justifyContent: "space-between"}}>
                        <Stack direction="row">
                            <Typography variant='h5'>Training Group</Typography>
                            <QuestionTitle title="" infoText='E.g. Thrill, Dragons Kingdom, Surfshack'/>
                        </Stack>
                        <IconButton onClick={handleBack}>
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Autocomplete
                            id={`trainingGroupParent`}
                            options={trainingGroupParentNamesQuery.data ?? []}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option ? option.label : ""}
                            getOptionKey={option => option.id}
                            value={formik.values.trainingGroupParent ?? null}
                            onChange={(e, value) => {
                            if(value) {
                                formik.setFieldValue(`trainingGroupParent`, value)
                                formik.setFieldValue(`trainingGroupParentId`, value.id)
                            } else {
                                formik.setFieldValue(`trainingGroupParent`, null)
                                formik.setFieldValue(`trainingGroupParentId`, null)
                            }
                            }}
                            size="small"
                            sx={{width:"20rem"}}
                            renderInput={(params) => 
                            <TextField {...params} label="Training Group"/>
                            }
                        />
                        <Tooltip title="Training Group">
                            <>
                            <IconButton onClick={() => setEditTrainingGroupName(true)}>
                                <EditIcon/>
                            </IconButton>
                            <TrainingGroupParents
                                open={editTrainingGroupName}
                                handleClose={handleEditNamesClose}
                            />
                            </>
                        </Tooltip>
                        <FormControl size="small">
                            <InputLabel>Type</InputLabel>
                            <Select
                                id="type"
                                name="type"
                                value={formik.values.type}
                                label="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoWidth
                                sx={{minWidth:"5rem"}}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                            >
                                <MenuItem value={TrainingGroupType.NTM}>{TrainingGroupTypeString[TrainingGroupType.NTM]}</MenuItem>
                                <MenuItem value={TrainingGroupType.RTM}>{TrainingGroupTypeString[TrainingGroupType.RTM]}</MenuItem>
                                <MenuItem value={TrainingGroupType.ATM}>{TrainingGroupTypeString[TrainingGroupType.ATM]}</MenuItem>
                                <MenuItem value={TrainingGroupType.Required}>{TrainingGroupTypeString[TrainingGroupType.Required]}</MenuItem>


                            </Select>
                            <FormHelperText sx={{marginLeft:"14px"}} error ={formik.touched.type && Boolean(formik.errors.type)}>
                                {formik.touched.type && formik.errors.type}
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <Stack direction="row" sx={{marginTop:"1rem"}}>
                            <Typography variant='h5'>Attraction/Outlet/Area</Typography>
                            <QuestionTitle title="" infoText="E.g. Surf Shack, Goliath"/>
                        </Stack>
                        <LearningAreaCreator
                            formik={formik}
                            formikAreasValue={formik.values.learningAreas}
                            formikString="learningAreas"
                            learningDocuments={learningDocsQuery.data ?? []}
                            learningItems={learningItemsQuery.data ?? []}
                            setMessageItem={setMessageItem}
                        />
                        <Stack direction="row" sx={{marginTop:"1rem"}}>
                            <Typography variant='h5'>Training Resources All Areas</Typography>
                            <QuestionTitle title="" infoText="These Training Resources are required for all areas. For example Dragons Kingdom overview and or Dragons Kingdom Retention test is required for all attractions in the Dragons Kingdom Training Group."/>
                        </Stack>
                        <MaterialCreator
                            formik={formik}
                            formikLearningMaterialItems={formik.values.materialAllAreas}
                            formikString='materialAllAreas'
                            learningDocuments={learningDocsQuery.data ?? []}
                            learningItems={learningItemsQuery.data ?? []}
                        />

                        <span style={{display:"flex", justifyContent:`${id && guidRegex.test(id) ? "space-between" : "end"}`, marginTop:"1rem", alignItems: "start"}}>
                        {id && guidRegex.test(id) && (
                        <Accordion sx={{width:"30rem"}}>
                            <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            id="additional-info"
                            >
                            <Typography>Modification Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            {formik.values.createdBy && (
                                <Typography>
                                Created By: {formik.values.createdBy}
                                </Typography>
                            )}
                            {formik.values.createdOn && (
                                <Typography>
                                Created On: {new Date(formik.values.createdOn).toLocaleTimeString(undefined, dateOptions)}
                                </Typography>
                            )}
                            {formik.values.modifiedBy && (
                                <Typography>
                                Modified By: {formik.values.modifiedBy}
                                </Typography>
                            )}
                            {formik.values.modifiedOn && (
                                <Typography>
                                Modified On: {new Date(formik.values.modifiedOn).toLocaleTimeString(undefined, dateOptions)}
                                </Typography>
                            )}
                            </AccordionDetails>
                            
                        </Accordion>
                        )}
                        <div>
                            <LoadingButton variant='contained' loading={createMutation.isPending || updateMutation.isPending} onClick={() => handleSaveClick()} size='large'>Save</LoadingButton>
                        </div>
                        </span>

                    </Stack>

                </TabPanel>
                <TabPanel value={"Online Learning Prerequisites"}>
                    {id && guidRegex.test(id) && (
                        <OnlineLearningPrerequisites 
                            trainingGroupId={id}
                            setMessageItem={setMessageItem}    
                        />
                    )}
                </TabPanel>
                    
                </TabContext>

            </Paper>
           
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TrainingGroupCreator