import { TableRow, TableCell, useTheme, IconButton, TableContainer, Paper, Table, TableHead, TableBody, LinearProgress, TableFooter, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import { AttractionPermittedInspectionsDto, TeamMemberPermittedInspectionsDto } from 'src/dtos/Checklists/AttractionInspections/PermittedInspectionsDto.dto'
import PermittedAttractionInspectionsService from 'src/Services/Checklists/Inspections/PermittedAttractionInspectionsService'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import TeamMemberPermittedInspectionCell from './TeamMemberPermittedInspectionCell'
import { NameDto } from 'src/dtos/NameDto.dto'

type Props = {
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    teamMembers?: NameDto[]
    tmDepartments?: NameDto[]
    attractions?: NameDto[]
}
function PermittedInspectionsMatrix({setMessageItem, teamMembers, tmDepartments, attractions}: Props) {
    const theme = useTheme(); 
    const [openColumns, setOpenColumns] = useState<{[attractionId: string] : boolean}>({})
    const [teamMemberPermittedInspections, setTeamMemberPermittedInspections] = useState<TeamMemberPermittedInspectionsDto[]>([])
    //#region quieries

    const attractionInspectionsQuery = useQuery({
        queryKey: ["GetAttractionInspections", attractions],
        queryFn: async () => {
            return await PermittedAttractionInspectionsService.GetAttractionInspections(attractions);
        }
    })

    const teamMemberPermittedInspectionsQuery = useQuery({
        queryKey: ["GetPermittedInspections", teamMembers, tmDepartments, attractions],
        queryFn: async () => {
            return await PermittedAttractionInspectionsService.GetPermittedInspections(teamMembers, tmDepartments, attractions);
        }
    })

    useEffect(() => {
        if(attractionInspectionsQuery.isError){
            setMessageItem({error: attractionInspectionsQuery.error})
        }
        if(teamMemberPermittedInspectionsQuery.isError){
            setMessageItem({error: teamMemberPermittedInspectionsQuery.error})
        }
    }, [attractionInspectionsQuery.error, attractionInspectionsQuery.isError, setMessageItem, teamMemberPermittedInspectionsQuery.error, teamMemberPermittedInspectionsQuery.isError])
    
    //#endregion

    useEffect(() => {
        if(teamMemberPermittedInspectionsQuery.data){
            setTeamMemberPermittedInspections(teamMemberPermittedInspectionsQuery.data)
        }
    }, [teamMemberPermittedInspectionsQuery.data])

    function HeaderColumn({attractionInspections, shaded}: {attractionInspections: AttractionPermittedInspectionsDto, shaded: boolean}) {
        const open = openColumns[attractionInspections.attraction.id]

        const handleClick = () => {
            setOpenColumns(prevOpenColumns => ({
                ...prevOpenColumns,
                [attractionInspections.attraction.id]: !prevOpenColumns[attractionInspections.attraction.id]
            }))
        }

        return (
            <>
                <TableCell sx={{width: "max-content", backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white,}}>
                    {attractionInspections.attraction.label}
                    <IconButton onClick={handleClick}>
                        {open ? <IndeterminateCheckBoxOutlinedIcon/> : <AddBoxOutlinedIcon /> }        
                    </IconButton>
                </TableCell>
                {open && attractionInspections.inspections.map((inspection, index) => (
                    <TableCell key={index} sx={{ textAlign:"center", backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                        {inspection.label}
                    </TableCell>
                ))}
            </>
        )
    }
    
    function TeamMemberRow({teamMember}: {teamMember: TeamMemberPermittedInspectionsDto}) {
        function AttractionColumn({attractionInspections, shaded}: {attractionInspections: AttractionPermittedInspectionsDto, shaded: boolean}){
            const open = openColumns[attractionInspections.attraction.id]
            const totalInspections = attractionInspections.inspections.length;
            const inspectionIds = attractionInspections.inspections.map(ic => ic.id)
            const tmPermittedInspectionsQty = teamMember.permittedInspections.filter(pi => pi.assigned === true && inspectionIds.includes(pi.inspectionChecklist.id)).length;

            

            return (
                <>
                    <TableCell sx={{textAlign:'center', backgroundColor: shaded ? theme.palette.action.hover : theme.palette.common.white}}>
                        {`${tmPermittedInspectionsQty}/${totalInspections}`}
                    </TableCell>
                    {open && attractionInspections.inspections.map((inspection, index) => (
                        <TableCell key={index} sx={{textAlign:'center', backgroundColor: shaded ? theme.palette.action.hover : theme.palette.common.white}}>
                            <TeamMemberPermittedInspectionCell
                                teamMember={teamMember}
                                inspection={inspection}
                                setTeamMemberPermittedInspections={setTeamMemberPermittedInspections}
                                setMessageItem={setMessageItem}
                            />
                        </TableCell>
                    ))}
                </>
            )
        }
        return (
            <TableRow sx={{height:"58px"}}>
                <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:2}}>
                    {teamMember.teamMember.label}
                </TableCell>
                {attractionInspectionsQuery.data && attractionInspectionsQuery.data.map((attractionInspections, index) => (
                    <AttractionColumn
                        key={index}
                        attractionInspections={attractionInspections}
                        shaded={index%2 === 0}
                    />
                ))}
            </TableRow>
        )
    }

    function FooterColumn({attractionInspections, shaded}: {attractionInspections: AttractionPermittedInspectionsDto, shaded: boolean}) {
        const open = openColumns[attractionInspections.attraction.id]

        const calculateAttractionInspectionsTotal = (attractionInspections: AttractionPermittedInspectionsDto): string => {
            const totalInspectionTms = attractionInspections.inspections.length * teamMemberPermittedInspections.length
            //inspections from this attraction
            const inspectionIds = attractionInspections.inspections.map(inspection => inspection.id)
            //total permitted inspections across all teammembers for this attraction
            const totalPermittedInspections = teamMemberPermittedInspections.reduce<number>((acc: number, tm) => {
                //team member permitted Inspections from this attraction
                const tmPermittedInspectionsTotal = tm.permittedInspections.filter(pi => pi.assigned === true && inspectionIds.includes(pi.inspectionChecklist.id)).length
                return acc + tmPermittedInspectionsTotal
            }, 0)
            return `${totalPermittedInspections}/${totalInspectionTms}`
        }

        const calculateInspectionTotal = (inspection: NameDto): string => {
            const totalTm = teamMemberPermittedInspections.length

            const totalPermittedInspections = teamMemberPermittedInspections.reduce<number>((acc: number, tm) => {
                const tmPermittedInspection = tm.permittedInspections.some(pi => pi.assigned === true && pi.inspectionChecklist.id === inspection.id)
                if(tmPermittedInspection)
                    acc ++
                return acc
            }, 0)
            return `${totalPermittedInspections}/${totalTm}`
        }

        return (
            <>
                <TableCell sx={{backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                    <Typography sx={{textAlign:"center"}}>{`${calculateAttractionInspectionsTotal(attractionInspections)}`}</Typography>
                </TableCell>
                {open && attractionInspections.inspections.map((inspection, index) => (
                    <TableCell key={index} sx={{backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                        <Typography sx={{textAlign:"center"}}>{`${calculateInspectionTotal(inspection)}`}</Typography>
                    </TableCell>
                ))}
            </>
        )
    }
    return (
        <>
            {teamMemberPermittedInspectionsQuery.isFetching ? (
                <LinearProgress/>
            ) : (
                <div style={{height:"4px"}}/> //spacer
            )}
            <TableContainer component={Paper} sx={{ width: 'max-content', maxHeight:"calc(100vh - 23rem)", overflowX:"auto", maxWidth:"100%"}}>
                <Table sx={{width: "max-content"}} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>
                                Team Member
                            </TableCell>
                            {attractionInspectionsQuery.data && attractionInspectionsQuery.data.map((attractionInspections, index) => (
                                <HeaderColumn
                                    key={index}
                                    attractionInspections={attractionInspections}
                                    shaded={index%2 === 0}
                                />
                            ))}
                            {attractionInspectionsQuery.isLoading && (
                                <TableCell>
                                    <div className='loader'/>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamMemberPermittedInspections.map((teamMember, index) => (
                            <TeamMemberRow
                                key={index}
                                teamMember={teamMember}
                            />
                        ))}
                    </TableBody>
                    <TableFooter sx={{left:0, bottom:0, zIndex:2, position:"sticky", backgroundColor:"white"}}>
                        <TableRow>
                            <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>
                                <Typography sx={{textAlign:"center"}}>{`Totals`}</Typography>
                            </TableCell>
                            {attractionInspectionsQuery.data && attractionInspectionsQuery.data.map((attractionInspections, index) => (
                            <FooterColumn
                                key={index}
                                attractionInspections={attractionInspections}
                                shaded={index%2 === 0}
                            />
                        ))}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default PermittedInspectionsMatrix