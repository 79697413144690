import { Stack, Skeleton, Fab, Typography, Chip, TableBody, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import assets from 'src/assets';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { dateOptions, dateTimeOptions } from 'src/config/constants';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService';
import InspectionChecklistVersionService from 'src/Services/Checklists/Inspections/InspectionChecklistVersionService';
import { EmailToNameString, guidRegex } from 'src/Utils/helperFunc';
import PrintIcon from '@mui/icons-material/Print';
import { Status, StatusString } from 'src/dtos/Statuses';
import SectionTable from './Components/SectionTable';
import TableOfContents from './Components/TableOfContents';
import { GetSectionVisible } from 'src/pages/User Site/Inspections/InspectionV2/CalculateStatusesHelper';
import JournalEntry from './Components/JournalEntry';

function CompletedAttractionInspection() {
    const {responseId} = useParams()
    const [messageItem, setMessageItem] = useState<MessageItem>({});
    const componentRef = useRef<HTMLDivElement>(null);

    const checklistResponseQuery = useQuery({
        queryKey: ["checklistResponse", responseId],
        queryFn: async () => {
            if(responseId){
                return await InspectionChecklistResponseService.Get(responseId);
            }
            return InspectionChecklistResponseService.GetDefaultValues()
        },
        initialData: InspectionChecklistResponseService.GetDefaultValues()
    })

    const checklistVersionQuery = useQuery({
        queryKey: ["checklistVersion", checklistResponseQuery.data.inspectionChecklistVersionId],
        queryFn: async () => {
            if(guidRegex.test(checklistResponseQuery.data.inspectionChecklistVersionId) && checklistResponseQuery.data.inspectionChecklistVersionId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistVersionService.GetInspection(checklistResponseQuery.data.inspectionChecklistVersionId);
            }
            return InspectionChecklistVersionService.GetDefaultVersionValues()
        },
        initialData: InspectionChecklistVersionService.GetDefaultVersionValues()
    })
    
    const checklistQuery = useQuery({
        queryKey: ["checklist", checklistVersionQuery.data.inspectionChecklistId],
        queryFn: async () => {
            if(guidRegex.test(checklistVersionQuery.data.inspectionChecklistId) && checklistVersionQuery.data.inspectionChecklistId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistService.Get(checklistVersionQuery.data.inspectionChecklistId);
            }
            return InspectionChecklistService.GetDefaultValues()
        },
        initialData: InspectionChecklistService.GetDefaultValues()
    })

    const journalEntriesQuery = useQuery({
        queryKey: ["InspectionJournalEntries", checklistResponseQuery.data.id],
        queryFn: async () => {
            if(checklistResponseQuery.data.id !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistResponseService.GetJournalEntries(checklistResponseQuery.data.id)
            }
        }
    })

    useEffect(() => {
        if(checklistResponseQuery.isError){
            setMessageItem({error: checklistResponseQuery.error})
        }
        if(checklistVersionQuery.isError){
            setMessageItem({error: checklistVersionQuery.error})
        }
        if(checklistQuery.isError){
            setMessageItem({error: checklistQuery.error})
        }
    }, [checklistQuery.error, checklistQuery.isError, checklistResponseQuery.error, checklistResponseQuery.isError, checklistVersionQuery.error, checklistVersionQuery.isError])

    //const attraction = checklistQuery.data.attraction ?? {id: "", label : ""}


    const handlePrint = useReactToPrint({
        contentRef: componentRef, 
        documentTitle: `${checklistQuery.data?.label} ${checklistResponseQuery.data?.completedTime && new Date(checklistResponseQuery.data.completedTime).toLocaleDateString(undefined, dateTimeOptions)}`,
    });

    
    const initialLoading = checklistResponseQuery.isLoading || checklistVersionQuery.isLoading || checklistQuery.isLoading || journalEntriesQuery.isLoading || checklistVersionQuery.data.sections.length === 0;
    if (initialLoading){
        return (
            <>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    {/* For logo */}
                    <Skeleton animation="wave" variant="circular" sx={{ width: "5rem", height: "5rem" }}/>
                    {/* For Title */}
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <Skeleton animation="wave" sx={{width: "80%", display:"flex"}}/>
                    </div>
                </Stack>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </>
        )
    }


     return (
        <>
            <div ref={componentRef}>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                    <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{`${checklistQuery.data?.label}`}</Typography>
                    <div style={{display:"flex", justifyContent:"center", alignItems: "center", marginRight:"1rem"}}>
                        <Chip
                            label={StatusString[checklistResponseQuery.data.status]}
                            color={checklistResponseQuery.data.status === Status.Passed ? "success" : "error"}
                        />
                    </div>
                </Stack>

                <Stack sx={{display:"flex", alignItems:"center"}} spacing={4}>
                    <div>
                        <Typography variant='h6'>Submission Details</Typography>
                        <Table size='small' sx={{width: "fit-content"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Person</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checklistResponseQuery.data.startedBy && (
                                    <TableRow>
                                        <TableCell>
                                            Started
                                        </TableCell>
                                        <TableCell>
                                            {EmailToNameString(checklistResponseQuery.data.startedBy)}
                                        </TableCell>
                                        <TableCell>
                                            {checklistResponseQuery.data.startedTime && new Date(checklistResponseQuery.data.startedTime).toLocaleTimeString(undefined, dateOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {checklistResponseQuery.data.completedBy && (
                                    <TableRow>
                                        <TableCell>
                                            Submitted
                                        </TableCell>
                                        <TableCell>
                                            {EmailToNameString(checklistResponseQuery.data.completedBy)}
                                        </TableCell>
                                        <TableCell>
                                            {checklistResponseQuery.data.completedTime && new Date(checklistResponseQuery.data.completedTime).toLocaleTimeString(undefined, dateOptions)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <div>
                        <Typography variant='h6'>Table of Contents</Typography>
                        <TableOfContents
                            checklistResponse={checklistResponseQuery.data}
                            checklistVersion={checklistVersionQuery.data}
                        />
                    </div>
                </Stack>


                

                {checklistVersionQuery.data.sections.map((sectionContent, sectionIndex) => (
                    <Fragment key={sectionIndex}>
                        {GetSectionVisible(sectionContent, checklistResponseQuery.data.selectedComponents ?? []) && checklistResponseQuery.data.sectionResponses[sectionIndex] && (
                            <div style={{pageBreakBefore: "always"}} id={`section${sectionIndex+1}`} className='landscape'>
                                <SectionTable
                                    sectionContent={sectionContent}
                                    sectionResponse={checklistResponseQuery.data.sectionResponses[sectionIndex]}
                                    inspectedComponents={checklistResponseQuery.data.inspectedComponents}
                                    selectedComponents={checklistResponseQuery.data.selectedComponents ?? []}
                                />
                            </div>
                        )}
                    </Fragment>
                ))}

                {journalEntriesQuery.data && journalEntriesQuery.data.map((journalEntry, index) =>  (
                    <div style={{pageBreakBefore: "always"}} id={`journalEntry${journalEntry.entryId}`} key={index}>
                        <JournalEntry
                            journalEntry={journalEntry}
                        />
                    </div>
                ))}

            </div>
           


            <Fab className="invisiblePrint" onClick={() => handlePrint()} sx={{position:"fixed", bottom: 16, right: 16}}>
                <PrintIcon/>
            </Fab>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default CompletedAttractionInspection