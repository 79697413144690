import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, Collapse, Divider, IconButton, List, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import { ChipColor, dateTimeOptions } from 'src/config/constants'
import SearchIcon from '@mui/icons-material/Search';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { AttractionInspectionDto, AttractionInspectionsStatusDto, InspectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/AttractionInspectionsStatus.dto'
import { Status, StatusString } from 'src/dtos/Statuses'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { showDialog } from 'src/components/AlertDialog/AlertDialog'
import { InspectedComponentStatus, InspectedComponentStatusString } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { EmailToNameString } from 'src/Utils/helperFunc';

function Inspections() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") ?? "")
    const [searchInput, setSearchInput] = useState(searchTerm)
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const inTwelveHours = new Date(Date.now() + 12 * 60 * 60 * 1000);


    const attractionInspectionsStatusQuery = useQuery({
        queryKey: ["StatusOfInspections", searchTerm],
        queryFn: async () => {
            return await InspectionChecklistResponseService.GetAttractionInspections(searchTerm)
        }
    })

    useEffect(() => {
        if(attractionInspectionsStatusQuery.isError) {
            setMessageItem({error: attractionInspectionsStatusQuery.error})
        }
    }, [attractionInspectionsStatusQuery.error, attractionInspectionsStatusQuery.isError])

    const updateSearchTerm = (newSearch: string) => {
        setSearchTerm(newSearch)
        searchParams.set("searchTerm", newSearch)
        setSearchParams(searchParams)
    }

     

    const SecondOddStyledTableRow = styled(TableRow)(({ theme }) => ({
        // First odd row: action.hover, every second odd row: common.white
        '&:nth-of-type(4n-3)': {
          backgroundColor: theme.palette.action.hover,
        },
        '&:nth-of-type(4n-1)': {
          backgroundColor: theme.palette.common.white,
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.common.white,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        cursor: "pointer"
      })) as typeof TableRow;

    const AttractionRow = ({attractionInspections}:{attractionInspections: AttractionInspectionsStatusDto}) => {
        const [open, setOpen] = useState(false);

        const statusPriority: (Status | null)[] = [Status.Failed, null, Status.Unanswered, Status.Inprogress, Status.Passed];

        const attractionWorstStatus: Status | null = attractionInspections.inspections.reduce<Status | null>((worstStatus, inspection) => {
            const inspectionStatus = inspection.status != null ? inspection.status : null
            if(statusPriority.findIndex((status) => status === inspectionStatus) < statusPriority.findIndex((status) => status === worstStatus)){
                return inspectionStatus
            }
            return worstStatus
        }, Status.Passed) //start at the best possible status
        

        const attractionWorstExpiry: Date | null = attractionInspections.inspections.filter(inspection => inspection.status === Status.Passed).reduce<Date | null>((worstExpiry, inspection) => {
            const inspectionExpiry = inspection.expiry ? new Date(inspection.expiry) : null;
            if (inspectionExpiry && (!worstExpiry || inspectionExpiry < worstExpiry)) {
                return inspectionExpiry;
            }
            return worstExpiry;
        }, null)

        const statusChipColour: ChipColor = attractionWorstStatus !== null ? 
            attractionWorstStatus === Status.Passed 
                ? ((attractionWorstExpiry && new Date(attractionWorstExpiry) < inTwelveHours) 
                    ? "warning" 
                    : "success" ) 
                : attractionWorstStatus === Status.Failed 
                ? "error" 
                :(attractionWorstStatus === Status.Inprogress || attractionWorstStatus === Status.Unanswered) 
                ? "primary" 
                : "default" 
            :"default";

        return (
            <>
                <SecondOddStyledTableRow 
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                    hover
                    onClick={() => setOpen(!open)}    
                >
                    <TableCell sx={{width:"2rem", borderBottom: 'unset'}}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{borderBottom: 'unset'}}>
                        {attractionInspections.attractionLabel}
                    </TableCell>
                    <TableCell align='center' sx={{borderBottom: 'unset'}}>
                        <Chip
                            color={statusChipColour}
                            label={attractionWorstStatus !== null ? StatusString[attractionWorstStatus] : "Outstanding"}
                        />
                    </TableCell>
                    <TableCell sx={{borderBottom: 'unset'}}/>
                </SecondOddStyledTableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{margin: 1}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>
                                                Inspection
                                            </TableCell>
                                            <TableCell align='center' sx={{width:"9rem"}}>
                                                Status
                                            </TableCell>
                                            <TableCell align='center' sx={{width:"9rem"}}>
                                                Components
                                            </TableCell>
                                            <TableCell sx={{width:"9rem"}}/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attractionInspections.inspections.map((inspection, index) => (
                                            <AttractionInspectionRow
                                                key={index}
                                                attractionInspection={inspection}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    const AttractionInspectionRow = ({attractionInspection}: {attractionInspection: AttractionInspectionDto}) => {
        const [open, setOpen] = useState(false);
        const navigate = useNavigate();

        const hasResponses = attractionInspection.inspectionResponses.length > 0;

        //TODO: Change to status instead of checking for completedTime
        const inProgressResponse = attractionInspection.inspectionResponses.find(response => response.completedTime === null)
        
        const startInspectionMutation = useMutation({
            mutationKey: ["startInspection"],
            mutationFn: (inspectionId: string) => {
                return InspectionChecklistResponseService.StartInspection(inspectionId)
            },
            onSuccess: (data) => {
                navigate(`/user/attractionInspections/inspectionChecklists/${data.data}`)
            },
            onError: (error) => {
                setMessageItem({error: error})
            }
        })

        const handleStartNewClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation()
            const startNew = await showDialog(
                {
                    title: `Start New Inspection`,
                    contentText: `Start a new response for ${attractionInspection.inspectionChecklistLabel}`,
                    acceptText: "Start Inspection",
                    declineText: "Cancel"
                }
            )

            if(startNew){
                await startInspectionMutation.mutateAsync(attractionInspection.inspectionChecklistId) 
            }
        }   

        const statusChipColour: ChipColor = attractionInspection.status != null ? 
            attractionInspection.status === Status.Passed ? ((attractionInspection.expiry && new Date(attractionInspection.expiry) < inTwelveHours) ? "warning" : "success" ) : 
            attractionInspection.status === Status.Failed ? "error" : 
            (attractionInspection.status === Status.Inprogress || attractionInspection.status === Status.Unanswered) ? "primary" : "default" :
            "default"

        const componentsText: string = attractionInspection.allComponents.length > 0 ? 
            `${attractionInspection.allComponents.filter(comp => comp.componentStatus === InspectedComponentStatus.passed).length}/${attractionInspection.allComponents.length}` : 
            "N/A"

        return (
            <>
                <TableRow 
                    hover={hasResponses}
                    sx={{cursor: hasResponses ? "pointer" : "auto"}} 
                    onClick={() => hasResponses && setOpen(!open)}
                >
                    <TableCell sx={{width:"2rem", borderBottom: 'unset'}}>
                        {hasResponses && (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell sx={{borderBottom: 'unset'}}>
                        <Typography>{attractionInspection.inspectionChecklistLabel}</Typography>
                    </TableCell>
                    <TableCell align='center' sx={{borderBottom: 'unset'}}>
                        <Chip
                            color={statusChipColour}
                            label={attractionInspection.status != null ? StatusString[attractionInspection.status] : "Outstanding"}
                        />
                        
                    </TableCell>
                    <TableCell align='center' sx={{borderBottom: 'unset'}}>
                        {componentsText}
                    </TableCell>
                    <TableCell align='center' sx={{borderBottom: 'unset'}}>
                        {inProgressResponse ? (
                            <Link to={`/user/attractionInspections/inspectionChecklists/${inProgressResponse.inspectionChecklistResponseId}`}>
                                <Button
                                    variant='contained'
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Resume
                                </Button>
                            </Link>
                        ) : (
                            <LoadingButton
                                variant='contained'
                                loading={startInspectionMutation.isPending}
                                onClick={handleStartNewClick}
                            >
                                Start New
                            </LoadingButton>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{margin: 1}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width:"8rem"}}>
                                                Team Member
                                            </TableCell>
                                            <TableCell align='center'sx={{width:"8rem"}}>
                                                Completed
                                            </TableCell>
                                            <TableCell align='center'sx={{width:"9rem"}}>
                                                Status
                                            </TableCell>
                                            <TableCell align='center' sx={{width:"8rem"}}>
                                                Expiry
                                            </TableCell>
                                            <TableCell/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attractionInspection.inspectionResponses.map((response, index) => (
                                            <InspectionResponseRow
                                                key={index}
                                                inspectionResponse={response}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                {attractionInspection.allComponents.length > 0 && (
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{margin: 1}}>
                                    <Paper sx={{padding:"1rem", display:"flex", flexWrap: "wrap", gap:"0.5rem", width:"100%"}}>
                                        <Typography variant='h6' sx={{width:"100%"}}>
                                            Components
                                        </Typography>
                                        <Divider/>
                                        {attractionInspection.allComponents.sort((a, b) => (a.expiry ? new Date(a.expiry).getTime() : -1) - (b.expiry ? new Date(b.expiry).getTime() : -1)).map((component, index) => {
                                            const chipColour: ChipColor = component.componentStatus == null ? "default" :
                                                component.componentStatus === InspectedComponentStatus.failed ? "error" :
                                                component.componentStatus === InspectedComponentStatus.passed ? ((component.expiry && new Date(component.expiry) < inTwelveHours) ? "warning" : "success" )
                                                : "default"
                                            return (
                                                <Chip 
                                                    key={index}
                                                    label={`${component.component?.label} | ${InspectedComponentStatusString[component.componentStatus ?? 0]}${component.expiry ? ` | ${new Date(component.expiry).toLocaleDateString(undefined, dateTimeOptions)}` : ""}`}
                                                    color={chipColour}
                                                />
                                            )
                                        })}
                                    </Paper>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        )
    }
    
    const InspectionResponseRow = ({inspectionResponse}: {inspectionResponse: InspectionResponseDto}) => {

        const statusChipColour: ChipColor = inspectionResponse.inspectionStatus != null ? 
            inspectionResponse.inspectionStatus === Status.Passed ? ((inspectionResponse.expiry && new Date(inspectionResponse.expiry) < inTwelveHours) ? "warning" : "success" ) : 
            inspectionResponse.inspectionStatus === Status.Failed ? "error" : 
            (inspectionResponse.inspectionStatus === Status.Inprogress || inspectionResponse.inspectionStatus === Status.Unanswered) ? "primary" : "default" :
            "default"

        return (
            <>
                <TableRow>
                    <TableCell>
                        {EmailToNameString(inspectionResponse.completedBy ?? inspectionResponse.startedBy)}
                    </TableCell>
                    <TableCell align='center'>
                        {inspectionResponse.completedTime ? new Date(inspectionResponse.completedTime).toLocaleDateString(undefined, dateTimeOptions) : "Not Submitted"}
                    </TableCell>
                    <TableCell align='center'>
                        <Chip 
                            color={statusChipColour}
                            label={inspectionResponse.inspectionStatus !== undefined && StatusString[inspectionResponse.inspectionStatus]}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        {inspectionResponse.expiry && new Date(inspectionResponse.expiry).toLocaleDateString(undefined, dateTimeOptions)}
                    </TableCell>
                    <TableCell align='right'>
                        <Link to={`/user/attractionInspections/inspectionChecklists/${inspectionResponse.inspectionChecklistResponseId}`}>
                            <Button>
                                View
                            </Button>
                        </Link>
                    </TableCell>
                </TableRow>
            </>
        )
    }


    return (
        <Box sx={{paddingTop:"1rem"}}>
            <Stack direction={"row"} spacing={1} sx={{display:"felx", justifyContent:"center"}}>
                <TextField
                    key={"searchTermInput"}
                    onBlur={() => {updateSearchTerm(searchInput);}}
                    onKeyDown={e => {
                        if(e.key === "Enter"){
                            updateSearchTerm(searchInput)
                        }
                    }}
                    id="searchTermInput" 
                    name="searchTermInput" 
                    label="Search" 
                    variant='outlined' 
                    size='small' 
                    value={searchInput} 
                    onChange={e => setSearchInput(e.target.value)} 
                />
                <LoadingButton variant={"contained"}  loading={attractionInspectionsStatusQuery.isLoading || attractionInspectionsStatusQuery.isFetching} onClick={() => attractionInspectionsStatusQuery.refetch()}><SearchIcon/></LoadingButton>
            </Stack>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Attractions</TableCell>
                            <TableCell align='center' sx={{width:"9rem"}}>Status</TableCell>
                            <TableCell sx={{width:"5rem"}}/> {/* Spacer */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attractionInspectionsStatusQuery.data && attractionInspectionsStatusQuery.data.map((attractionInspections, index) => (
                            <AttractionRow
                                key={index}
                                attractionInspections={attractionInspections}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <List>
                
            </List>
            <ErrorHandlingSnackbar messageItem={messageItem} />
        </Box>
    )
}

export default Inspections