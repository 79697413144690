import { Autocomplete, darken, lighten, Stack, styled, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect, useMemo } from 'react'
import EditableLabel from 'src/components/EditableLabel/EditableLabel'
import { JournalEntryDto } from 'src/dtos/Checklists/AttractionInspections/Journal.dto'
import { ChildEntity } from 'src/dtos/EntityChild.dto'
import AttractionService from 'src/Services/Resources/AttractionService'

type Props = {
    formik: FormikProps<JournalEntryDto>
    attractionId: string;
    componentId?: number
}
function OutOfServiceEntry({formik, attractionId, componentId}: Props) {
    
    const attractionComponentsQuery = useQuery({
        queryKey: ["GetComponents", attractionId],
        queryFn: async () => {
            return await AttractionService.GetComponents(attractionId);
        }
    })

    const generateComponents = (parentComponent: ChildEntity): ChildEntity[] => {
        const newComponentsList: ChildEntity[] = []; // Local list to store the results
    
        const collectComponents = (component: ChildEntity) => {
            if (component.children && component.children.length > 0) {
                // Add all children to the new list
                component.children.forEach((child) => {
                    newComponentsList.push({ ...child, parentLabel: component.label });
                });
    
                // Recursively checks for more sub-children
                component.children.forEach((child) => {
                    collectComponents(child);
                });
            }
        };
    
        collectComponents(parentComponent); // Start the recursive process
        return newComponentsList; // Return the collected list
    };

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor:
          theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    const componentOptions = useMemo(() => 
        attractionComponentsQuery.data ? generateComponents(attractionComponentsQuery.data) : [], 
        [attractionComponentsQuery.data]
    );

    useEffect(() => {
        if(componentId && componentOptions.length > 0 && formik.values.component == null){
            const component = componentOptions.find(c => c.id === componentId);
            formik.setFieldValue("component", component)
            console.log(component)
        }
    }, [componentId, componentOptions, formik])
    return (
        <>
            <Stack>
                <Typography variant='h6'>Component</Typography>
                <Autocomplete
                    disablePortal
                    id="component"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => (option.label ? option.label : "")}
                    options={componentOptions}
                    groupBy={(option) => (option.parentLabel ?? option.label)}
                    size="small"
                    value={formik.values.component ?? null}
                    onChange={(_, value: any) => {
                        formik.setFieldValue("component", value ?? null)
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        name="component"
                        size="small"
                        variant="outlined"
                        />
                    )}
                    renderGroup={(params) => (
                        <li key={params.key}>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                />
                <Typography variant='h6'>Action Required</Typography>
                <EditableLabel
                    value={formik.values.description ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("description", newValue)}
                    placeholder='Click here to describe the action required to put the item back in service'
                    onBlur={() => formik.setFieldTouched('description', true, false)}
                    helperText={formik.touched.description && formik.errors.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                />
            
                <Typography variant='h6'>Action Taken</Typography>
                <EditableLabel
                    value={formik.values.resolutionDescription ?? ""}
                    setValue={(newValue: string) => formik.setFieldValue("resolutionDescription", newValue)}
                    placeholder='Click here to describe the action taken'
                    helperText={formik.errors.resolutionDescription}
                    error={Boolean(formik.errors.resolutionDescription)}
                />    
            </Stack>
        </>
  )
}

export default OutOfServiceEntry