import { TableContainer, Paper, Table, TableCell, TableHead, TableRow, TableBody, TableFooter, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import { IntakeTypeDto, TMTrainingGroupParent, TeamMemberTrainingGroup } from 'src/dtos/HumanResources/TeamMemberTrainingGroup.dto';
import { NameDto } from 'src/dtos/NameDto.dto';
import TrainingGroupCheckbox from './TrainingGroupCheckbox';

type Props = {
    teamMembersParam: NameDto[];
    parentTrainingGroupsParam: NameDto[];
    tgDepartmentsParam: NameDto[];
    tmDepartmentsParam: NameDto[];
    intakesParam: IntakeTypeDto[];
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TraineeMatrix({teamMembersParam, parentTrainingGroupsParam, tgDepartmentsParam, tmDepartmentsParam, intakesParam, setMessageItem}:Props) {

    const [teamMembers, setTeamMembers] = useState<TeamMemberTrainingGroup[]>([])
    
    const teamMembersQuery = useQuery({
        queryKey: ["TraineesTrainingGroups", teamMembersParam, tmDepartmentsParam, intakesParam],
        queryFn: async () => {
            return await TeamMemberService.GetTraineesTrainingGroups(teamMembersParam, tmDepartmentsParam, intakesParam)
        }
    })
    useEffect(() => {
        if(teamMembersQuery.data){
            setTeamMembers(teamMembersQuery.data)
        }
    },[teamMembersQuery.data])

    const trainingGroupParentsQuery = useQuery({
        queryKey: ["TrainingGroupParents", tgDepartmentsParam, parentTrainingGroupsParam],
        queryFn: async () => {
            return await TrainingGroupParentService.GetTrainingGroupParents(tgDepartmentsParam, parentTrainingGroupsParam)
        }
    })

    useEffect(() => {
        if(teamMembersQuery.isError){
            setMessageItem({error: teamMembersQuery.error})
        }
        if(trainingGroupParentsQuery.isError){
            setMessageItem({error: trainingGroupParentsQuery.error})
        }
    }, [setMessageItem, teamMembersQuery.error, teamMembersQuery.isError, trainingGroupParentsQuery.error, trainingGroupParentsQuery.isError])

    function calculateTgpTotals(trainingGroupParents: TMTrainingGroupParent[], teamMembers: TeamMemberTrainingGroup[]) {
        const parentTotals: { [parentId: string]: number } = {};

        trainingGroupParents.forEach((parent) => {
            const trainingGroupIds = parent.trainingGroups.map(group => group.id);

            const count = teamMembers.filter(member =>
                trainingGroupIds.filter(groupId => member.trainingGroupIds.includes(groupId)).length > 0
            ).length;

            parentTotals[parent.id] = count;
        });

        return parentTotals;
    }

    const trainingGroupParentTotals = trainingGroupParentsQuery.data ? calculateTgpTotals(trainingGroupParentsQuery.data, teamMembers) : {};
    
    return (
        <>
            <TableContainer component={Paper} sx={{ width: 'max-content', maxHeight:"calc(100vh - 23rem)", overflowX:"auto", maxWidth:"100%"}}>
                <Table sx={{width: "max-content"}} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>Team Member</TableCell>
                            {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((trainingGroupParent, index) => (
                                <React.Fragment key={index}>
                                    <TableCell>
                                        {trainingGroupParent.label}
                                    </TableCell>
                                </React.Fragment>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamMembers.map((teamMember, index) => (
                            <TableRow key={index}>
                                <TableCell  sx={{position:"sticky", left:0, background:"white", zIndex:2}}>
                                    {teamMember.teamMemberName}
                                </TableCell>
                                {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((trainingGroupParent, tgpIndex) => (
                                    <TableCell key={tgpIndex}>
                                        <TrainingGroupCheckbox
                                            teamMember={teamMember}
                                            setTeamMembers={setTeamMembers}
                                            setMessageItem={setMessageItem}
                                            trainingGroupParent={trainingGroupParent}
                                        />
                                    </TableCell>  
                                ))}
                                
                            </TableRow>
                        ))}
                        {teamMembersQuery.isLoading && (
                            <TableRow>
                                <TableCell>
                                    <div className='loader'/>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter sx={{left:0, bottom:0, zIndex:2, position:"sticky", backgroundColor:"white"}}>
                    <TableRow>
                        <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>
                            <Typography sx={{textAlign:"center"}}>{`Totals`}</Typography>
                        </TableCell>
                        {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((trainingGroupParent, index) => (
                            <TableCell key={index}>
                                <Typography sx={{textAlign:"left", paddingLeft:"1rem"}}>{`${trainingGroupParentTotals[trainingGroupParent.id] || 0}`}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

export default TraineeMatrix