import React from 'react'
import JournalEntries from '../../Journal/Components/JournalEntries'
import InspectionBottomNavigation from './InspectionBottomNavigation'

function InspectionJournalEntries() {
    return (
        <div style={{marginBottom:"4rem"}}>
            <JournalEntries/>
            <InspectionBottomNavigation/>
        </div>
    )
}

export default InspectionJournalEntries