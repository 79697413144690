import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { AttractionPermittedInspectionsDto, PermittedInspectionDto, TeamMemberPermittedInspectionsDto } from "src/dtos/Checklists/AttractionInspections/PermittedInspectionsDto.dto";
import { NameDto } from "src/dtos/NameDto.dto";
import { GetBearer } from "src/Utils/authUtils";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/PermittedAttractionInspections";
const debug = process.env.REACT_APP_DEBUG;

export default class PermittedAttractionInspectionsService {

    static GetDefaultValues(inspectionChecklist: NameDto): PermittedInspectionDto {
        return {
            assigned: false, 
            inspectionChecklist: inspectionChecklist
        }
    } 
    static async GetPermittedInspections(teamMembers?: NameDto[], tmDepartments?: NameDto[], attractions?: NameDto[]): Promise<TeamMemberPermittedInspectionsDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<TeamMemberPermittedInspectionsDto[]> = await axios.get<TeamMemberPermittedInspectionsDto[]>(`${apiBaseUrl}/GetPermittedInspections`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    teamMemberIds: teamMembers?.map((tm) => tm.id),
                    tmDepartmentIds: tmDepartments?.map((dep) => dep.id),
                    attractionIds: attractions?.map((attraction) => attraction.id)

                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAttractionInspections(attractions?: NameDto[]): Promise<AttractionPermittedInspectionsDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<AttractionPermittedInspectionsDto[]> = await axios.get<AttractionPermittedInspectionsDto[]>(`${apiBaseUrl}/GetAttractionInspections`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    attractionIds: attractions?.map((attraction) => attraction.id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAttractionInspectionDepartments(): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetAttractionInspectionDepartments`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetInspectionTeamMembers(departments?: NameDto[]): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetInspectionTeamMembers`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    departmentIds: departments?.map((dep) => dep.id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'});
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async AddPermittedInspection(teamMemberId: string, permittedInspection: PermittedInspectionDto){
        console.log(JSON.stringify(permittedInspection))
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<PermittedInspectionDto[]> = await axios.put(`${apiBaseUrl}/AddPermittedInspection/${teamMemberId}`, permittedInspection, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async RemovePermittedInspection(teamMemberId: string, permittedInspection: PermittedInspectionDto){
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<PermittedInspectionDto[]> = await axios.put(`${apiBaseUrl}/RemovePermittedInspection/${teamMemberId}`, permittedInspection, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}
