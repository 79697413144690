import { Typography, Stack, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import colorConfigs from "src/config/colorConfigs";
import { LearningDocumentTopic } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentTopicResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto";
import Subtopic from "../../Subtopic/Subtopic";
import { GetTopicStatus } from "../../../calculateStatusHelper";

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    demoMode?: boolean;
}

function TrainingDocumentTopic({topic, topicResponse, formikString, formik, demoMode}: Props) {
    const [notes, setNotes] = useState(topicResponse.commentary)    

    const topicStatus = GetTopicStatus(topicResponse, false)
    
    return (
      <>
        <div style={{color: "white", backgroundColor: colorConfigs.AW.blue, borderRadius: "10px"}}>
          <Typography variant='h5' sx={{textAlign:"center"}}>What to Train</Typography>
        </div>
        <ol>
          {topic.subtopics.map((subtopic, index) => (
            <Typography key={index}><li>{subtopic.label}</li></Typography>
          ))}
        </ol>
        
        <Stack spacing={2}>
        <div style={{color:"white", backgroundColor: colorConfigs.AW.blue, borderRadius: "10px"}}>
          <Typography variant='h5' sx={{textAlign:"center"}}>How to Train it</Typography>
        </div>
          {topic.subtopics.map((subtopic, index) => (
            <Subtopic 
              key={index}
              index={index}
              type="Active"
              formik={formik}
              formikString={`${formikString}.subtopicResponses[${index}]`}
              subtopicResponse={topicResponse.subtopicResponses[index]}
              subtopic={subtopic}
              pointsLocked={topicStatus === LearningDocumentResponseStatus.Completed}
              demoMode={demoMode}
            />
          ))}
          <div className='avoidBreak'>
            <QuestionTitle title="Notes"/>
            <TextField 
              id={`${formikString}.commentary`}
              name={`${formikString}.commentary`}
              value={notes ?? ""}
              onChange={(e) => setNotes(e.target.value)}
              onBlur={() => formik.setFieldValue(`${formikString}.commentary`, notes)}
              sx={{width:"100%"}}
              multiline  
            />    
          </div>
        </Stack>
      </>
    )
}

export default TrainingDocumentTopic