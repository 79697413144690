import { LearningDocumentPointResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus, LearningDocumentSubtopicResponse, LearningDocumentTopicResponse } from "src/dtos/Training/LearningDocumentResponse.dto";

export function GetPointStatus(pointResponse: LearningDocumentPointResponse) {
    
    const totalSubpoints = pointResponse.subpointResponses.length;
    const answeredSubpoints = pointResponse.subpointResponses.filter(spr => spr.checked === true).length

    if(pointResponse.checked !== true){
        if(answeredSubpoints === 0)
            return LearningDocumentResponseStatus.Unanswered
        else
            return LearningDocumentResponseStatus.InProgress
    }

    if(answeredSubpoints === totalSubpoints)
        return LearningDocumentResponseStatus.Completed
    
    return LearningDocumentResponseStatus.InProgress  
}

export function GetSubtopicStatus(subtopicResponse: LearningDocumentSubtopicResponse) : LearningDocumentResponseStatus {
    
    const totalPoints = subtopicResponse.pointResponses.length
    const completedPoints = subtopicResponse.pointResponses.filter(pr => GetPointStatus(pr) === LearningDocumentResponseStatus.Completed).length
    const unansweredPoints = subtopicResponse.pointResponses.filter(pr => GetPointStatus(pr) === LearningDocumentResponseStatus.Unanswered).length

    if(completedPoints === totalPoints)
        return LearningDocumentResponseStatus.Completed

    if(unansweredPoints === totalPoints)
        return LearningDocumentResponseStatus.Unanswered

    return LearningDocumentResponseStatus.InProgress   
}

export function GetTopicStatus(topicResponse: LearningDocumentTopicResponse, isCompetencyDocument: boolean) : LearningDocumentResponseStatus {
    
    const totalSubtopics = topicResponse.subtopicResponses.length
    const completedSubtopics = topicResponse.subtopicResponses.filter(str => GetSubtopicStatus(str) === LearningDocumentResponseStatus.Completed).length
    const unansweredSubtopics = topicResponse.subtopicResponses.filter(str => GetSubtopicStatus(str) === LearningDocumentResponseStatus.Unanswered).length

    if(unansweredSubtopics === totalSubtopics)
        return LearningDocumentResponseStatus.Unanswered

    if(completedSubtopics === totalSubtopics) {
        if(isCompetencyDocument){
            if(topicResponse.competent === true)
                return LearningDocumentResponseStatus.Competent
            if(topicResponse.competent === false)
                return LearningDocumentResponseStatus.NotYetCompetent

            return LearningDocumentResponseStatus.AwaitingOutcome
        } 
        
        return LearningDocumentResponseStatus.Completed 
    } 

    return LearningDocumentResponseStatus.InProgress
}

export function GetDocumentStatus(learningDocumentResponse: LearningDocumentResponseDto, isCompetencyDocument: boolean){

    const completedStatuses = [LearningDocumentResponseStatus.Completed, LearningDocumentResponseStatus.Competent, LearningDocumentResponseStatus.NotYetCompetent]

    if(completedStatuses.includes(learningDocumentResponse.status)){
        return learningDocumentResponse.status
    }

    const totalTopics = learningDocumentResponse.topicResponses.length
    const completedTopics = learningDocumentResponse.topicResponses.filter(tr => completedStatuses.includes(GetTopicStatus(tr, isCompetencyDocument))).length
    const unansweredTopics = learningDocumentResponse.topicResponses.filter(tr => GetTopicStatus(tr, isCompetencyDocument) === LearningDocumentResponseStatus.Unanswered).length

    if(unansweredTopics === totalTopics)
        return LearningDocumentResponseStatus.Unanswered

    if(completedTopics === totalTopics){
        if(isCompetencyDocument){
            if(learningDocumentResponse.competent == null)
                return LearningDocumentResponseStatus.AwaitingOutcome
            if(learningDocumentResponse.completedTime == null)
                return LearningDocumentResponseStatus.AwaitingSubmission
        } else {
            if(learningDocumentResponse.completedTime == null)
                return LearningDocumentResponseStatus.AwaitingSubmission
            else
                return LearningDocumentResponseStatus.AwaitingSignature
        }
    }

    return LearningDocumentResponseStatus.InProgress

}