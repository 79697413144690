import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, useTheme, TableFooter, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TMTrainingGroupParent, TeamMemberTrainingGroup } from 'src/dtos/HumanResources/TeamMemberTrainingGroup.dto'
import { TrainingGroupTypeString } from 'src/dtos/Training/TrainingGroup.dto'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { useMutation, useQuery } from '@tanstack/react-query';
import TeamMemberService from 'src/Services/HumanResources/TeamMemberService';
import { NameDto } from 'src/dtos/NameDto.dto';
import '../styles.css'
import TrainingGroupParentService from 'src/Services/Training/TrainingGroupParentService';
import { MessageItem } from 'src/components/errorHandlingSnackbar';
import TrainingGroupCheckbox from './TrainingGroupCheckbox';

type Props = {
    teamMembersParam: NameDto[];
    parentTrainingGroupsParam: NameDto[];
    tgDepartmentsParam: NameDto[];
    tmDepartmentsParam: NameDto[];
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function TrainingGroupsMatrix({teamMembersParam, parentTrainingGroupsParam, tgDepartmentsParam, tmDepartmentsParam, setMessageItem}:Props) {
    const [openColumns, setOpenColumns] = useState<{[tgpId: string] : boolean}>({})
    const [teamMembers, setTeamMembers] = useState<TeamMemberTrainingGroup[]>([])

    const theme = useTheme(); 

    const teamMembersQuery = useQuery({
        queryKey: ["TeammembersTrainingGroups", teamMembersParam, tmDepartmentsParam],
        queryFn: async () => {
            return await TeamMemberService.GetTrainersTrainingGroups(teamMembersParam, tmDepartmentsParam)
        }
    })
    useEffect(() => {
        if(teamMembersQuery.data){
            setTeamMembers(teamMembersQuery.data)
        }
    },[teamMembersQuery.data])

    const trainingGroupParentsQuery = useQuery({
        queryKey: ["TrainingGroupParents", tgDepartmentsParam, parentTrainingGroupsParam],
        queryFn: async () => {
            return await TrainingGroupParentService.GetTrainingGroupParents(tgDepartmentsParam, parentTrainingGroupsParam)
        }
    })

    useEffect(() => {
        if(teamMembersQuery.isError){
            setMessageItem({error: teamMembersQuery.error})
        }
        if(trainingGroupParentsQuery.isError){
            setMessageItem({error: trainingGroupParentsQuery.error})
        }
    }, [setMessageItem, teamMembersQuery.error, teamMembersQuery.isError, trainingGroupParentsQuery.error, trainingGroupParentsQuery.isError])


    function HeaderColumn({tmTrainingGroupParent, shaded}:{tmTrainingGroupParent: TMTrainingGroupParent, shaded: boolean}) {
        const open = openColumns[tmTrainingGroupParent.id]

        const handleClick = () => {
            setOpenColumns(prevOpenColumns => ({
                ...prevOpenColumns,
                [tmTrainingGroupParent.id]: !prevOpenColumns[tmTrainingGroupParent.id]
            }));
        }
        return (
            <>
                <TableCell sx={{width: "max-content", backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white,}}>
                    {tmTrainingGroupParent.label}
                    <IconButton onClick={handleClick}>
                        {open ? <IndeterminateCheckBoxOutlinedIcon/> : <AddBoxOutlinedIcon /> }        
                    </IconButton>
                </TableCell>
                {open && tmTrainingGroupParent.trainingGroups.map((trainingGroup, index) => (
                    <TableCell key={index} width={"3rem"} sx={{textAlign:"center", backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                        {TrainingGroupTypeString[trainingGroup.type]}
                    </TableCell>
                ))}
            </>
        )
    }

    function calculateTgTotals(trainingGroupParents: TMTrainingGroupParent[], teamMembers: TeamMemberTrainingGroup[]) {
        const totals: { [key: string]: number } = {};
    
        trainingGroupParents.forEach((parent) => {
            parent.trainingGroups.forEach((group) => {
                if (!totals[group.id]) {
                    totals[group.id] = 0;
                }
    
                teamMembers.forEach((member) => {
                    if (member.trainingGroupIds.includes(group.id)) {
                        totals[group.id] += 1;
                    }
                });
            });
        });
    
        return totals;
    }


    function calculateTgpTotals(trainingGroupParents: TMTrainingGroupParent[], teamMembers: TeamMemberTrainingGroup[]) {
        const parentTotals: { [parentId: string]: number } = {};

        trainingGroupParents.forEach((parent) => {
            const trainingGroupIds = parent.trainingGroups.map(group => group.id);

            const count = teamMembers.filter(member =>
                trainingGroupIds.every(groupId => member.trainingGroupIds.includes(groupId))
            ).length;

            parentTotals[parent.id] = count;
        });

        return parentTotals;
    }

    const trainingGroupTotals = trainingGroupParentsQuery.data ? calculateTgTotals(trainingGroupParentsQuery.data, teamMembers) : {};
    const trainingGroupParentTotals = trainingGroupParentsQuery.data ? calculateTgpTotals(trainingGroupParentsQuery.data, teamMembers) : {};

    function FooterColumn({tmTrainingGroupParent, shaded}: {tmTrainingGroupParent: TMTrainingGroupParent, shaded: boolean}) {
        const open = openColumns[tmTrainingGroupParent.id]

        return (
            <>
                <TableCell sx={{backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                    <Typography sx={{textAlign:"center"}}>{`${trainingGroupParentTotals[tmTrainingGroupParent.id]}`}</Typography>
                </TableCell>
                {open && tmTrainingGroupParent.trainingGroups.map((trainingGroup, index) => (
                    <TableCell key={index} sx={{backgroundColor: shaded ? "#f5f5f5" : theme.palette.common.white}}>
                        <Typography sx={{textAlign:"center"}}>{`${trainingGroupTotals[trainingGroup.id] || 0}`}</Typography>
                    </TableCell>
                ))}
            </>
        )
    }

    function TMRow({teamMember}:{teamMember: TeamMemberTrainingGroup}) {
        function TGColumn({tmTrainingGroupParent, shaded}: {tmTrainingGroupParent: TMTrainingGroupParent, shaded: boolean}){
            const open = openColumns[tmTrainingGroupParent.id]

            const trainingGroupIds = tmTrainingGroupParent.trainingGroups.map(tg => tg.id)
            const checkedTg = teamMember.trainingGroupIds.filter(id => trainingGroupIds.includes(id)).length

            const addTrainingGroupsMutation = useMutation({
                mutationFn: (addTrainingGroupIds: string[]) => {
                    return TeamMemberService.AddTrainerTrainingGroup(teamMember.teamMemberId, addTrainingGroupIds)
                },
                onSuccess: (data) => {
                    setMessageItem({successMessage: `Training Groups added to ${teamMember.teamMemberName} successfully`})
                    setTeamMembers(prevMembers =>
                        prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
                    );
                },
                onError: (error) => {
                    setMessageItem({error: error})
                }
            })
        
            const removeTrainingGroupsMutation = useMutation({
                mutationFn: (removeTrainingGroupIds: string[]) => {
                    return TeamMemberService.RemoveTrainerTrainingGroup(teamMember.teamMemberId, removeTrainingGroupIds)
                },
                onSuccess: (data) => {
                    setMessageItem({successMessage: `Training Groups removed from ${teamMember.teamMemberName} successfully`})
                    setTeamMembers(prevMembers =>
                        prevMembers.map(member => (member.teamMemberId === teamMember.teamMemberId ? data : member))
                    );
                },
                onError: (error) => {
                    setMessageItem({error: error})
                }
            })
            
            

            const indeterminate = checkedTg !== 0 && checkedTg < trainingGroupIds.length
            const checked = checkedTg === trainingGroupIds.length
            const handleCheckBoxClick = () => {
                
                if(indeterminate || checked){
                    const removeTrainingGroupIds = trainingGroupIds.filter(tgId => teamMember.trainingGroupIds.includes(tgId))
                    removeTrainingGroupsMutation.mutate(removeTrainingGroupIds)
                } else {
                    const addTrainingGroupIds = trainingGroupIds
                    addTrainingGroupsMutation.mutate(addTrainingGroupIds)
                }
            }            

            const loading = addTrainingGroupsMutation.isPending || removeTrainingGroupsMutation.isPending
            return (
                <>
                    <TableCell sx={{textAlign:'center', backgroundColor: shaded ? theme.palette.action.hover : theme.palette.common.white}}>
                        {!loading ?
                            <Checkbox
                                indeterminate={indeterminate}
                                checked={checked}
                                onClick={() => handleCheckBoxClick()}
                            />
                            :
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <div className='loader'/>
                            </div>
                        }
                    </TableCell>
                    {open && tmTrainingGroupParent.trainingGroups.map((trainingGroup, index) => (
                        <TableCell key={index} sx={{textAlign:'center', backgroundColor: shaded ? theme.palette.action.hover : theme.palette.common.white}}>
                           <TrainingGroupCheckbox
                                teamMember={teamMember}
                                setTeamMembers={setTeamMembers}
                                setMessageItem={setMessageItem}
                                trainingGroupId={trainingGroup.id}
                                loading={loading}
                           />
                            
                        </TableCell>
                    ))}
                </>
            )
        }

        return (
            <TableRow>
                <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:2}}>
                    {teamMember.teamMemberName}
                </TableCell>
                {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((tmTrainingGroup, index) => (
                    <TGColumn
                        key={index}
                        tmTrainingGroupParent={tmTrainingGroup}
                        shaded={index%2 === 0}
                    />
                ))}
            </TableRow>
        )
    }
    
    return (
    <>
        <TableContainer component={Paper} sx={{ width: 'max-content', maxHeight:"calc(100vh - 23rem)", overflowX:"auto", maxWidth:"100%"}}>
            <Table sx={{width: "max-content"}} stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>Team Member</TableCell>
                        {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((trainingGroupParent, index) => (
                            <HeaderColumn
                                key={index}
                                tmTrainingGroupParent={trainingGroupParent}
                                shaded={index%2 === 0}
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teamMembers.map((teamMember, index) => (
                        <TMRow
                            key={index}
                            teamMember={teamMember}
                        />
                    ))}
                    {teamMembersQuery.isLoading && (
                        <TableRow>
                            <TableCell>
                                <div className='loader'/>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter sx={{left:0, bottom:0, zIndex:2, position:"sticky", backgroundColor:"white"}}>
                    <TableRow>
                        <TableCell sx={{position:"sticky", left:0, background:"white", zIndex:3}}>
                            <Typography sx={{textAlign:"center"}}>{`Totals`}</Typography>
                        </TableCell>
                        {trainingGroupParentsQuery.data && trainingGroupParentsQuery.data.map((trainingGroupParent, index) => (
                            <FooterColumn
                                key={index}
                                tmTrainingGroupParent={trainingGroupParent}
                                shaded={index%2 === 0}
                            />
                        ))}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </>
  )
}

export default TrainingGroupsMatrix