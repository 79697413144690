import { Stack, Skeleton, Fab, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService'
import LearningDocumentService from 'src/Services/Training/LearningDocuments/LearningDocumentService'
import LearningDocumentVersionService from 'src/Services/Training/LearningDocuments/LearningDocumentVersionService'
import TrainingSessionService from 'src/Services/Training/TrainingSessionService'
import { guidRegex } from 'src/Utils/helperFunc'
import CompletedAttendanceSheet from './CompletedAttendanceSheet'
import { AttendeeType } from 'src/dtos/Training/AttendanceSheetResponse.dto'
import { useReactToPrint } from 'react-to-print'
import { dateOptions } from 'src/config/constants'
import PrintIcon from '@mui/icons-material/Print';
import assets from 'src/assets'

function CompletedLearningDocument() {
    const {trainingSessionId, responseId} = useParams()
    const componentRef = useRef<HTMLDivElement>(null);


    //#region queries
    const documentResponseQuery = useQuery({
        queryKey: ["documentResponse", responseId],
        queryFn: async () => {
            if(responseId && guidRegex.test(responseId)){
                return await LearningDocumentResponseService.Get(responseId)
            }
            return null
        }
    })
    
    const learningDocumentVersionQuery = useQuery({
        queryKey: ["learningDocumentVersion", documentResponseQuery.data?.learningDocumentVersionId],
        queryFn: async () => {
            if(documentResponseQuery.data){
                return await LearningDocumentVersionService.Get(documentResponseQuery.data.learningDocumentVersionId);
            }
            return null
        }
    })
    
    const learningDocumentQuery = useQuery({
        queryKey: ["learningDocument", learningDocumentVersionQuery.data?.learningDocumentId],
        queryFn: async () => {
            if(learningDocumentVersionQuery.data){
                return await LearningDocumentService.Get(learningDocumentVersionQuery.data.learningDocumentId)
            }
            return null
        } 
    })

    const trainingSessionQuery = useQuery({
        queryKey: ["trainingSession", trainingSessionId],
        queryFn: async () => {
            if(trainingSessionId && guidRegex.test(trainingSessionId)){
                return await TrainingSessionService.Get(trainingSessionId)
            }
            return null
        }
    })
    
    //TODO: Error handling
    //#endregion

    const handlePrint = useReactToPrint({
        contentRef: componentRef, 
        documentTitle: `${learningDocumentQuery.data?.label} ${trainingSessionQuery.data?.date && new Date(trainingSessionQuery.data.date).toLocaleDateString(undefined, dateOptions)}`,
    });

    const initialLoading = documentResponseQuery.isLoading || learningDocumentVersionQuery.isLoading || learningDocumentQuery.isLoading;
    if(initialLoading){
        return (
            <>
                <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                    {/* For logo */}
                    <Skeleton animation="wave" variant="circular" sx={{ width: "5rem", height: "5rem" }}/>
                    {/* For Title */}
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <Skeleton animation="wave" sx={{width: "80%", display:"flex"}}/>
                    </div>
                </Stack>
                {/* For Body */}
                <div style={{width:"100%", padding:"1rem"}}>
                    <Skeleton animation="wave" variant="rectangular" height={500} />
                </div>  
            </>
        )
    }

    return (
        <>
            <div ref={componentRef}>
                <div>
                    <Stack direction="row" sx={{width:"100%", padding:"1rem"}}>
                        <img alt="AW LOGO" src ={assets.images.logo} style={{ width: "5rem", height: "5rem" }}/>
                        <Typography variant='h5' sx={{width: "100%", paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>{`${learningDocumentQuery.data?.label}`}</Typography>
                        <Typography sx={{display: "flex", justifyContent: "center", alignItems:"center", width:"8rem"}}>{trainingSessionQuery.data?.date && new Date(trainingSessionQuery.data?.date).toLocaleDateString(undefined, dateOptions)}</Typography>
                        
                    </Stack>
                </div>
                <div style={{pageBreakBefore: "always"}}>
                    <CompletedAttendanceSheet
                        learningDocumentLabel={learningDocumentQuery.data?.label ?? ""}
                        topics={learningDocumentVersionQuery.data?.topics ?? []}
                        trainees={documentResponseQuery.data?.attendanceSheetResponse.attendees.filter(a => a.type === AttendeeType.Trainee) ?? []}
                        trainers={documentResponseQuery.data?.attendanceSheetResponse.attendees.filter(a => a.type === AttendeeType.Trainer) ?? []}
                        departmentLabel={learningDocumentQuery.data?.department?.label ?? ""}
                        trainingSessionDate={trainingSessionQuery.data?.date}
                        type={learningDocumentQuery.data?.type}
                    />
                </div>
            </div>

            <Fab className="invisiblePrint" onClick={() => handlePrint()} sx={{position:"fixed", bottom: 16, right: 16}}>
                <PrintIcon/>
            </Fab>
        </>
    )
}

export default CompletedLearningDocument