import { ListItem, ListItemIcon, ListItemButton, Checkbox, Typography, Stack, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import { PointIcon } from "./PointIcon";
import { LearningDocumentPoint } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentPointResponse } from "src/dtos/Training/LearningDocumentResponse.dto";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { GetCurrentUser } from "src/Utils/authUtils";
import { EmailToNameString } from "src/Utils/helperFunc";
import { dateTimeOptions } from "src/config/constants";

type BaseProps = {
    point: LearningDocumentPoint
    index: number
    allIconlessPoints?: boolean
    SaveStatusIcon?: React.ReactNode;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    pointResponse: LearningDocumentPointResponse;
    pointLocked: boolean;
    SaveStatusIcon: React.ReactNode;
    updatePoint: (newValues: LearningDocumentPointResponse) => void;
}

type Props = DisplayProps | ActiveProps;
function TallyCounter(props: Props) {

    const CLICK_DELAY_MS = 25000;

    const {point, allIconlessPoints} = props;

    const [lastClick, setLastClick] = useState<Date>(new Date(new Date().getTime() - CLICK_DELAY_MS))
    const [addDisabled, setAddDisabled] = useState(false)
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const minTally = point.tallyNumber ?? 0
    const checked = props.type === "Active" ? props.pointResponse.checked : false;
    const count = props.type === "Active" ? props.pointResponse.tallyValue ?? 0: 0;

    const handleAddClick = () => {
        if(checked) return

        if(props.type === "Active"){
            const { pointResponse, updatePoint} = props 
            if((new Date().getTime()-lastClick.getTime()) > CLICK_DELAY_MS){
                updatePoint({...pointResponse, tallyValue: count + 1})
                
                setLastClick(new Date())
                setAddDisabled(true)
            } 
        }
    }

    const handleMinusClick = () => {
        if(count === 0) return
        if(checked) return

        if(props.type === "Active"){
            const { pointResponse, updatePoint} = props 

            updatePoint({...pointResponse, tallyValue: count - 1})

            setLastClick(new Date(new Date().getTime() - CLICK_DELAY_MS))
        }
        
    }

    const pointDisabled = props.type === "Active" && props.pointResponse.answeredById ? !(props.pointResponse.answeredById === GetCurrentUser().id) && props.pointResponse.checked === true : false

    const handlePointClick = () => {
        if(count < minTally){
            setMessageItem({errorMessage: `Cannot check point until minimum tally of ${minTally} has been reached.`})
            return
        }
        
        if(props.type === "Active"){
            const {pointResponse, pointLocked, updatePoint} = props

            if (pointLocked || pointDisabled){
                return
            }

            updatePoint({...pointResponse, checked: !pointResponse.checked, timeAnswered: new Date()})
        } 
        
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setAddDisabled((new Date().getTime()-lastClick.getTime()) < CLICK_DELAY_MS)
        }, 200) 
        return () => clearInterval(interval)   
    })
    const {SaveStatusIcon} = props

    return (
        <>
            <ListItem disablePadding>
                    {allIconlessPoints !== true && (
                        <ListItemIcon>
                            <PointIcon pointType={point.type}/>
                        </ListItemIcon>
                    )}
                <div style={{width:"100%"}}>
                    <ListItemButton onClick={handlePointClick}>
                        <ListItemIcon>
                            <Checkbox
                                disabled={pointDisabled}
                                edge="start"
                                checked={checked}
                                tabIndex={-1}
                                disableRipple
                                />
                        </ListItemIcon>
                        <Stack direction="row" sx={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}} spacing={1}>
                            <Typography>{point.label}</Typography>
                            <Stack spacing={1} direction="row" sx={{alignItems:"center"}}>
                                {pointDisabled && (
                                <Stack spacing={1} sx={{textAlign:"center"}}>
                                    <Typography variant="caption">{props.type === "Active" && props.pointResponse.answeredBy && EmailToNameString(props.pointResponse.answeredBy)}</Typography>
                                    <Typography variant="caption">{props.type === "Active" && props.pointResponse.timeAnswered && new Date(props.pointResponse.timeAnswered).toLocaleDateString(undefined, dateTimeOptions)}</Typography>
                                </Stack>
                                )}
                                {SaveStatusIcon}
                        </Stack>
                        </Stack>

                    </ListItemButton>
                    <Stack direction="row" spacing={2} sx={{width:"10rem", marginLeft:"5rem"}}>
                        <IconButton onClick={handleMinusClick} color='error' disabled={count === 0 || (props.type === "Active" && props.pointResponse.checked)}>
                            <IndeterminateCheckBoxIcon fontSize='inherit' sx={{fontSize:"45px"}}/>
                        </IconButton>
                            <Typography style={{margin:"auto", marginLeft:"1rem", fontSize:"20px"}}>{`${count}`}</Typography>
                        <IconButton onClick={handleAddClick} color="success" disabled={addDisabled || (props.type === "Active" && props.pointResponse.checked)}>
                            <AddBoxIcon fontSize='inherit' sx={{fontSize:"45px"}}/>
                        </IconButton>
                    </Stack>
                </div>
            </ListItem>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default TallyCounter