import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import { MessageItem } from 'src/components/errorHandlingSnackbar'
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle'
import { OnlineLearningPrerequisitesDto } from 'src/dtos/Training/TrainingGroup.dto'
import TrainingGroupService from 'src/Services/Training/TrainingGroupService'

type Props = {
    trainingGroupId: string;
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
}
function OnlineLearningPrerequisites({trainingGroupId, setMessageItem}:Props) {
    
    const prerequisitesQuery = useQuery({
        queryKey: ["onlineLearningPossiblePrerequisiets", trainingGroupId],
        queryFn: async () => {
            return await TrainingGroupService.GetOnlineLearningPrerequisites(trainingGroupId)
        }
    })

    const updateMutation = useMutation({
        mutationFn: async (values: OnlineLearningPrerequisitesDto[]) => {
            return await TrainingGroupService.UpdateOnlineLearningPrerequisites(trainingGroupId, values)
        },
        onSuccess: () => {
            setMessageItem({successMessage: "Successfully Update Online Learning Prerequisites"})
        }, 
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const save = async (values: OnlineLearningPrerequisitesDto[]) => {
        updateMutation.mutateAsync(values)
    }

    const formik = useFormik({
        enableReinitialize: true, 
        initialValues: prerequisitesQuery.data ?? [],
        onSubmit: (values) => {
            save(values);
          }
    })

    const handleLearningDocClick = (onlineItemIndex: number, learningDocIndex: number) => {
        const checked = formik.values[onlineItemIndex].learningDocumentPrerequisites[learningDocIndex].checked

        formik.setFieldValue(`[${onlineItemIndex}].learningDocumentPrerequisites[${learningDocIndex}].checked`, !checked)
        
    }
    const handleLearningItemClick = (onlineItemIndex: number, learningItemIndex: number) => {
        const checked = formik.values[onlineItemIndex].learningItemPrerequisites[learningItemIndex].checked

        formik.setFieldValue(`[${onlineItemIndex}].learningItemPrerequisites[${learningItemIndex}].checked`, !checked)
    }
    return (
        <>
            {formik.values.length > 0 && (
            <Stack>
                <Stack direction="row">
                    <Typography variant='h5'>Online Learning Prerequisites</Typography>
                    <QuestionTitle title='' infoText='Once 75% (rounded up) of Online Learning Prerequisites are met by the team member, the course is automatically assigned.'/>
                </Stack>    
                <Stack spacing={2}>
                    {formik.values.map((learningItemPrerequisites, index) => (
                        <Box key={index} component={Paper} >
                            <Typography variant='h6' sx={{padding:"1rem", paddingBottom:"0"}}>{learningItemPrerequisites.learningItem.label}</Typography>
                            <List dense>
                                {learningItemPrerequisites.learningDocumentPrerequisites && learningItemPrerequisites.learningDocumentPrerequisites.map((doc, docIndex) => (
                                    <ListItem key={`doc_${docIndex}`}>
                                        <ListItemButton dense onClick={() => handleLearningDocClick( index, docIndex)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={doc.checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={doc.id} primary={doc.label} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                {learningItemPrerequisites.learningItemPrerequisites && learningItemPrerequisites.learningItemPrerequisites.map((learningItem, itemIndex) => (
                                    <ListItem key={`learningItem_${itemIndex}`}>
                                        <ListItemButton dense onClick={() => handleLearningItemClick(index, itemIndex)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={learningItem.checked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={learningItem.id} primary={learningItem.label} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        
                    
                    ))}
                </Stack>
                <span style={{display:"flex", flexDirection: "row", justifyContent:"end", paddingTop:"1rem"}}>
                    <LoadingButton variant='contained' loading={updateMutation.isPending} onClick={() => formik.handleSubmit()}>
                        Save 
                    </LoadingButton>
                </span>

            </Stack>

            )}
        </>
    )
}

export default OnlineLearningPrerequisites