import React, { memo } from 'react'
import { LearningDocumentPoint, LearningDocumentPointType } from 'src/dtos/Training/LearningDocument.dto'
import { FormikProps } from 'formik';
import { LearningDocumentPointResponse, LearningDocumentResponseDto } from 'src/dtos/Training/LearningDocumentResponse.dto';
import CommonPoint from './Components/CommonPoint';
import { useMutation } from '@tanstack/react-query';
import LearningDocumentResponseService from 'src/Services/Training/LearningDocuments/LearningDocumentResponseService';
import { SaveStatusIcons } from 'src/config/constants';
import TallyCounter from './Components/TallyCounter';
import TroubleShootingPoint from './Components/TroubleShootingPoint';


type BaseProps = {
    point: LearningDocumentPoint
    index: number
    previousPointType?: LearningDocumentPointType
    allIconlessPoints?: boolean
    pointResponse?: LearningDocumentPointResponse;
    demoMode?: boolean;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    pointResponse: LearningDocumentPointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    pointLocked: boolean;
}

type Props = DisplayProps | ActiveProps;

function areEqual(prevProps: Props, nextProps: Props) {
    return (
        prevProps.pointResponse === nextProps.pointResponse
    )
}

function PointUnmemoized(props: Props) {
    const {point, index, previousPointType, allIconlessPoints} = props;

    //Handle Update point
    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: LearningDocumentPointResponse }) => {
            return LearningDocumentResponseService.UpdatePoint(id, values)
        }
    })
    
    const saveStatusIcon : React.ReactNode = updateMutation.isPending ? SaveStatusIcons.loading
        : updateMutation.isSuccess ? SaveStatusIcons.success
        : updateMutation.isError ? SaveStatusIcons.failed 
        : SaveStatusIcons.idle 

    function updatePoint(newValues: LearningDocumentPointResponse){
        if(props.type === "Active"){
            const {pointResponse, formikString, formik} = props

            formik.setFieldValue(formikString, newValues)
            if (props.demoMode === false) {
                if(props.pointResponse){
                    updateMutation.mutateAsync({id: pointResponse.id, values: newValues})
                }
            } 
        }
    }

    if (props.type === "DisplayOnly"){
        if(point.type === LearningDocumentPointType.tallyCounter) {
            return (
                <TallyCounter 
                    key={index}
                    type="DisplayOnly"
                    point={point}
                    index={index}
                />
            )
        } else if (point.type === LearningDocumentPointType.troubleshooting) {
            return (
                <TroubleShootingPoint
                    key={index} 
                    type="DisplayOnly"
                    point={point}
                    showHeader={previousPointType !== LearningDocumentPointType.troubleshooting}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        } else {
            return (
                <CommonPoint 
                    key={index}
                    type="DisplayOnly"
                    point={point}
                    index={index}
                    allIconlessPoints={allIconlessPoints}
                />
            )
        }
    }
    else {
        const {pointResponse, formik, formikString, pointLocked} = props
        if(point.type === LearningDocumentPointType.tallyCounter) {
            return (
                <TallyCounter 
                    key={index}
                    type="Active"
                    point={point}
                    index={index}
                    pointResponse={pointResponse}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                    updatePoint={updatePoint}
                />
            )
        } else if (point.type === LearningDocumentPointType.troubleshooting) {
            return (
                <TroubleShootingPoint
                    key={index} 
                    type='Active'
                    point={point}
                    showHeader={previousPointType !== LearningDocumentPointType.troubleshooting}
                    pointResponse={pointResponse}
                    formik={formik}
                    formikString={formikString}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                    updatePoint={updatePoint}
                />
            )
        } else {
            return (
                <CommonPoint 
                    key={index}
                    type="Active"
                    point={point}
                    index={index}
                    pointResponse={pointResponse}
                    formik={formik}
                    formikString={formikString}
                    pointLocked={pointLocked}
                    SaveStatusIcon={saveStatusIcon}
                    allIconlessPoints={allIconlessPoints}
                    demoMode={props.demoMode}
                    updatePoint={updatePoint}
                /> 
            )
        }
    }
}

export const Point = memo<Props>(PointUnmemoized, areEqual)


