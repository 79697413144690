import { ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { FormikProps } from "formik"
import React from "react"
import LearningDocumentResponseService from "src/Services/Training/LearningDocuments/LearningDocumentResponseService"
import { SaveStatusIcons } from "src/config/constants"
import { LearningDocumentResponseDto, LearningDocumentSubpointResponse } from "src/dtos/Training/LearningDocumentResponse.dto"


type BaseProps = {
    label:string
    subpointResponse?: LearningDocumentSubpointResponse;
    demoMode?: boolean;
}

type DisplayProps = BaseProps & {
    type: "DisplayOnly"
}

type ActiveProps = BaseProps & {
    type: "Active"
    subpointResponse: LearningDocumentSubpointResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
}




type Props = DisplayProps | ActiveProps;
function Subpoint(props:Props){
    const {label} = props
    
    //Handle Update subpoint
    const updateMutation = useMutation({
        mutationFn: ({id, values}: {id: number, values: LearningDocumentSubpointResponse }) => {
            return LearningDocumentResponseService.UpdateSubpoint(id, values)
        }
    })

    const handleSubpointClick = () => {
        if(props.type === "Active"){
            const {subpointResponse, formikString, formik} = props
            
            const newValues = {...subpointResponse, checked: !subpointResponse.checked, timeAnswered: new Date()}
            formik.setFieldValue(`${formikString}`, newValues);

            if (props.demoMode === false) {
                updateMutation.mutate({id: subpointResponse.id, values: newValues})
            }
        }
    }

    const saveStatusIcon : React.ReactNode = updateMutation.isPending ? SaveStatusIcons.loading
        : updateMutation.isSuccess ? SaveStatusIcons.success
        : updateMutation.isError ? SaveStatusIcons.failed 
        : SaveStatusIcons.idle 


    return(
        <ListItemButton onClick={() => handleSubpointClick()}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={props.type === "Active" ? props.subpointResponse.checked : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': "fdsfsd" }}
                />
            </ListItemIcon>
            <ListItemText primary={label} primaryTypographyProps={{variant: "body1"}}/>
            {saveStatusIcon}
        </ListItemButton>
    )
}

export default Subpoint