import { Table, TableHead, TableRow, TableCell, TableBody, Chip } from '@mui/material'
import React, { Fragment } from 'react'
import { InspectionChecklistVersion } from 'src/dtos/AwTypes'
import { InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { Status, StatusString } from 'src/dtos/Statuses'
import { GetSectionVisible, GetQuestionStatus, GetSectionStatus, GetSectionStatusColour } from 'src/pages/User Site/Inspections/InspectionV2/CalculateStatusesHelper'

type Props = {
    checklistVersion: InspectionChecklistVersion
    checklistResponse: InspectionChecklistResponseDto
}
function TableOfContents({checklistVersion, checklistResponse} : Props) {
    return (
    <Table size='small' sx={{width: "fit-content"}}>
        <TableHead>
            <TableRow>
                <TableCell>
                    Section
                </TableCell>
                <TableCell>
                    Passed Questions
                </TableCell>
                <TableCell>
                    Status
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {checklistVersion.sections.map((sectionContent, sectionIndex) => {
                const sectionVisible = GetSectionVisible(sectionContent, checklistResponse.selectedComponents ?? []);
                const sectionResponse = checklistResponse.sectionResponses[sectionIndex];
                const totalQuestions = sectionResponse.questionResponses.filter((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], checklistResponse.inspectedComponents, checklistResponse.selectedComponents ?? []) !== Status.NA).length;
                const passedQuestions = sectionResponse.questionResponses.filter((qr, index) => GetQuestionStatus(qr, sectionContent.questions[index], checklistResponse.inspectedComponents, checklistResponse.selectedComponents ?? []) === Status.Passed).length;
                const sectionStatus = GetSectionStatus(sectionResponse, sectionContent, checklistResponse.inspectedComponents, checklistResponse.selectedComponents ?? [])
                const statusColour = GetSectionStatusColour(sectionStatus)

                return (
                    <Fragment key={sectionIndex}>
                        {sectionVisible && (
                            <TableRow>
                                <TableCell>
                                    <a href={`#section${sectionIndex+1}`}>{sectionContent.label}</a>
                                </TableCell>
                                <TableCell>
                                    {`${passedQuestions}/${totalQuestions}`}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={StatusString[sectionStatus]}
                                        color={statusColour}
                                        size='small'
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </Fragment>
                )
            })}

        </TableBody>
    </Table>
    )
}

export default TableOfContents