import axios, { AxiosResponse } from "axios";
import { GetBearer } from "src/Utils/authUtils";
import { ListResponseDto } from "src/dtos/ListResponse.dto";
import { LearningMaterialItem, LearningArea, LearningMaterialType, TrainingGroup, TrainingGroupType, OnlineLearningPrerequisitesDto } from "src/dtos/Training/TrainingGroup.dto";
import { ListParameters, SortDirection } from "../ListParameters";
import { NameDto } from "src/dtos/NameDto.dto";
import { LearningDocument } from "src/dtos/Training/LearningDocument.dto";
import { LearningItem } from "src/dtos/Training/LearningItem.dto";
import TrainingGroupParentService from "./TrainingGroupParentService";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + "/TrainingGroup";
const debug = process.env.REACT_APP_DEBUG;

const emptyData: ListResponseDto = {
    data: [],
    totalRecords: 0
}

export default class TrainingGroupService {
    static GetDefaultValues() {
        const defaultVals: TrainingGroup = {
            id: "00000000-0000-0000-0000-000000000000",
            trainingGroupParent: TrainingGroupParentService.GetDefaultValues(),
            type: TrainingGroupType.NTM,
            learningAreas: [this.GetDefaultLearningAreaValues()],
            materialAllAreas: [],
            enabled: true
        }
        return defaultVals;
    }

    static GetDefaultLearningAreaValues() {
        const defaultVals: LearningArea = {
            learningMaterials: [this.GetDefaultLearningContent()]
        }
        return defaultVals;
    }

    static GetDefaultLearningContent() {
        const defaultVals: LearningMaterialItem = {
            type: LearningMaterialType.learningDocument,
        }
        return defaultVals;
    }

    static async Get(id: string): Promise<TrainingGroup> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<TrainingGroup> = await axios.get<TrainingGroup>(`${apiBaseUrl}/get/${id}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetList(listParam?: ListParameters, searchTerm?: string, includeDisabled?: boolean): Promise<ListResponseDto> {
        try {
            const bearer = await GetBearer();

            const response: AxiosResponse<ListResponseDto> = await axios.get<ListResponseDto>(`${apiBaseUrl}/GetList`, {
                headers: {
                    Authorization: bearer
                },
                params: {
                    page: listParam?.page,
                    pageSize: listParam?.pageSize,
                    sortBy : listParam?.sortBy,
                    sortDirection: listParam?.sortDirection === SortDirection.Ascending ? 0 : listParam?.sortDirection === SortDirection.Descending ? 1 : undefined,
                    searchTerm: searchTerm,
                    includeDisabled: includeDisabled  
                }
            });
            return response.data;
        } catch(error: any) {
            if(error.response !== undefined) {
                if(error.response.status != null && error.response.status === 404) {
                    return emptyData
                }
            }
            debug && console.log(error)
            throw error
        }
    }

    static async GetNames(): Promise<NameDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<NameDto[]> = await axios.get<NameDto[]>(`${apiBaseUrl}/GetNames`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetTrainingDocuments(trainingGroupId: string): Promise<LearningDocument[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningDocument[]> = await axios.get<LearningDocument[]>(`${apiBaseUrl}/GetTrainingDocuments/${trainingGroupId}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetAttendanceSheets(trainingGroupId: string): Promise<LearningItem[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningItem[]> = await axios.get<LearningItem[]>(`${apiBaseUrl}/GetAttendanceSheets/${trainingGroupId}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetOnlineLearnings(trainingGroupId: string): Promise<LearningItem[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<LearningItem[]> = await axios.get<LearningItem[]>(`${apiBaseUrl}/GetOnlineLearnings/${trainingGroupId}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async GetOnlineLearningPrerequisites(trainingGroupId: string): Promise<OnlineLearningPrerequisitesDto[]> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse<OnlineLearningPrerequisitesDto[]> = await axios.get<OnlineLearningPrerequisitesDto[]>(`${apiBaseUrl}/GetOnlineLearningPrerequisites/${trainingGroupId}`, {
                headers: {
                    Authorization: bearer
                }
            });
            return response.data
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Create(data: TrainingGroup): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.post<AxiosResponse>(`${apiBaseUrl}/Create`,data,{
                headers: {
                    Authorization: bearer
                }
            });
            return response
        } catch(error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Update(id: string, data: TrainingGroup): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/Update/${id}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async UpdateOnlineLearningPrerequisites(trainingGroupId: string, data: OnlineLearningPrerequisitesDto[]): Promise<AxiosResponse<OnlineLearningPrerequisitesDto[]>> {
        try {
            const bearer = await GetBearer();
            const response: AxiosResponse = await axios.put<AxiosResponse>(`${apiBaseUrl}/UpdateOnlineLearningPrerequisites/${trainingGroupId}`, data, {
                headers: {
                    Authorization: bearer
                }
            });
            return response;
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Enable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Enable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }

    static async Disable(id: string): Promise<AxiosResponse> {
        try {
            const bearer = await GetBearer(); 
            const response: AxiosResponse = await axios.put(`${apiBaseUrl}/Disable/${id}`, null, {
                headers: {
                    Authorization: bearer
                },
            });
            return response
        } catch (error: any) {
            debug && console.log(error)
            throw error;
        }
    }
}