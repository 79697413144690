import { TableRow, TableCell } from '@mui/material'
import React from 'react'
import { QuestionDto } from 'src/dtos/AwTypes'
import { InspectedComponentDto, InspectionChecklistQuestionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { NameNumberDto } from 'src/dtos/NameDto.dto'
import { StatusString } from 'src/dtos/Statuses'
import { GetQuestionStatus } from 'src/pages/User Site/Inspections/InspectionV2/CalculateStatusesHelper'
import { EmailToNameString } from 'src/Utils/helperFunc'

type Props = {
    questionContent: QuestionDto
    questionResponse: InspectionChecklistQuestionResponseDto
    inspectedComponents: InspectedComponentDto[]
    selectedComponents: NameNumberDto[]
}
function QuestionRowYesOrNo({questionContent, questionResponse, inspectedComponents, selectedComponents}: Props) {

    const questionStatus = GetQuestionStatus(questionResponse, questionContent, inspectedComponents, selectedComponents)
    return (
        <TableRow>
            <TableCell>
                {`${questionContent.number ? `${questionContent.number}. ` : ""} ${questionContent.label}`}
            </TableCell>
            <TableCell>
                {questionResponse.answer}
            </TableCell>
            <TableCell>
                {questionResponse.impactsOperation}
            </TableCell>
            <TableCell>
                <a href={`#journalEntry${questionResponse.relatedJournalEntry?.entryId}`}>{questionResponse.relatedJournalEntry?.entryId}</a>
            </TableCell>
            <TableCell>
                {EmailToNameString(questionResponse.answeredBy)}
            </TableCell>
            <TableCell>
                {StatusString[questionStatus]}
            </TableCell>
        </TableRow>
  )
}

export default QuestionRowYesOrNo