import React, { useState } from 'react'
import { AttractionDto, AttractionType, AttractionTypeIndexToString, ListResponseDto } from 'src/dtos/AwTypes'
import AttractionService from 'src/Services/Resources/AttractionService'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { IconButton, TableCell } from '@mui/material'
import colorConfigs from 'src/config/colorConfigs'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import ClickableTableRow from 'src/components/Datatable/ClickableTableRow'
import Datatable from 'src/components/Datatable/Datatable'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import { ListParameters } from 'src/Services/ListParameters'


const Attractions = () => {
  const tableHeaders: TableHeader[] = [
    {label: '', field: "statusIndicator", width: "10px", sortable: false},
    {label: "Name", field: "label", width: "8rem", sortable: true},
    {label: "Description", field: "description", width: "10rem", sortable: false},
    {label: "Residing Area", field: "residingAreaLabel", width: "8rem", sortable: true},
    {label: "Type", field: "type", width: "8rem", sortable: true},
    {label: '', field: "edit", width: "3rem", sortable: false}
  ]   
  
  type filterOptionsType = {
    type?: AttractionType,
    residingAreaLabel?: string,
    includeDisabled?: boolean
  }

  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [filterOptions] = useState<filterOptionsType>({type: undefined, residingAreaLabel: undefined, includeDisabled: false})
  //TODO: setFilterOptions and create filter options menu for attractions
  const navigate = useNavigate();

  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30   
  }

  const fetchAttractions = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
    const data = await AttractionService.GetList(listParams, searchTerm, filterOptions.type, filterOptions.includeDisabled);
    return data;
  }

  
  function renderTableRow ({data, index}: {data: AttractionDto, index: number}) { 
    const attractionTypeString: string = (data.type !== undefined && !isNaN(parseInt(data.type))) ? AttractionTypeIndexToString[parseInt(data.type)]: "";
    return (
      <React.Fragment>
        <ClickableTableRow
          hover
          href={`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}${data.id}`}
          key={`AttractionssRow_${index}`}
          sx={{cursor: 'pointer', textDecoration: "none"}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`, padding: '0', bgcolor:data.enabled ? colorConfigs.datatable.active: colorConfigs.datatable.inActive}}></TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[1].width} !important`, maxWidth: `${tableHeaders[1].width} !important`}}>{data.label}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[2].width} !important`, maxWidth: `${tableHeaders[2].width} !important`}}>{data.description}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[3].width} !important`, maxWidth: `${tableHeaders[3].width} !important`}}>{data.residingAreaLabel}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[4].width} !important`, maxWidth: `${tableHeaders[4].width} !important`}}>{attractionTypeString}</TableCell>
          <TableCell sx={{minWidth: `${tableHeaders[5].width} !important`, maxWidth: `${tableHeaders[5].width} !important`, paddingLeft: "0rem"}}><IconButton><EditIcon fontSize='small'/></IconButton></TableCell>
      </ClickableTableRow>
      </React.Fragment>
      
    )
  };
  

  const handleFormOpen = () => {
    navigate(`${window.location.pathname}${window.location.pathname[window.location.pathname.length-1] === "/" ? "" : "/"}new`)
  }
  
  return (
    <>
      <Datatable
        tableHeaders={tableHeaders}
        initialListParams={initialListParams}
        tableTitle='Attractions'
        buttonLabel='Add New Attraction'
        buttonOnClick={handleFormOpen}
        showSearch={true}
        renderRow={renderTableRow}
        callService={fetchAttractions}
        setMessageItem={(caughtError: MessageItem) => setMessageItem(caughtError)}
      />
      <ErrorHandlingSnackbar messageItem={messageItem}/>
    </>
  )
}

export default Attractions