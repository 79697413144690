import { Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import React, { useState } from "react"
import { LearningDocument, LearningDocumentType } from "src/dtos/Training/LearningDocument.dto"
import { LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto"
import CompetencyDocumentSignOff from "./CompetencyDocumentSignOff"
import { FormikProps } from "formik"
import { MessageItem } from "src/components/errorHandlingSnackbar"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from "@tanstack/react-query"
import LearningDocumentResponseService from "src/Services/Training/LearningDocuments/LearningDocumentResponseService"
import { LoadingButton } from "@mui/lab"
import OrientationDocumentSignOff from "./OrientationDocumentSignOff"


type Props = {
    formik: FormikProps<LearningDocumentResponseDto>
    setMessageItem: React.Dispatch<React.SetStateAction<MessageItem>>
    learningDocument: LearningDocument
    currentUser: {id: string, name: string}
}
const SignOff = ({formik, setMessageItem, learningDocument, currentUser}: Props) => {
    const [open, setOpen] = useState(false)
    const documentComplete =
      formik.values.status ===
        LearningDocumentResponseStatus.AwaitingSubmission ||
      formik.values.status === LearningDocumentResponseStatus.Completed ||
      formik.values.status === LearningDocumentResponseStatus.NotYetCompetent ||
      formik.values.status === LearningDocumentResponseStatus.Competent;

    const documentSubmitted = formik.values.completedTime !== null
    const submitBtnColour = documentComplete ? "primary" : documentSubmitted ? "success" : "error"

    const submitMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningDocumentResponseService.SubmitDocument(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Document Submitted Successfully"})
            formik.setFieldValue("completedTime", data.data.completedTime)
            formik.setFieldValue("completedBy", data.data.completedBy)
            formik.setFieldValue("status", data.data.status)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const markUnsignedMutation = useMutation({
        mutationFn: (id: string) => {
            return LearningDocumentResponseService.MarkUnsigned(id)
        },
        onSuccess: (data) => {
            setMessageItem({successMessage: "Document Submitted as Completed Successfully"})
            formik.setFieldValue("completedTime", data.data.completedTime)
            formik.setFieldValue("completedBy", data.data.completedBy)
            formik.setFieldValue("status", data.data.status)
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const handleSubmitClick = () => {
        if(documentComplete){
            if(learningDocument.type === LearningDocumentType.competencyDocument || learningDocument.type === LearningDocumentType.orientation){
                //No attendance sheet
                submitMutation.mutate(formik.values.id)
            } else {
                //Attendance sheet
                markUnsignedMutation.mutate(formik.values.id)
            }
        } else {
            setMessageItem({errorMessage:"Cannot submit an incomplete document!"})
        }
    }

    const TrainingDocumentSignOff = () => {
        return (
            <Stack sx={{padding:"1rem", marginBottom:"4rem"}} spacing={2}>
                <Typography>NOTE: Once you have submitted this page as completed you will no longer be able to change any answers or update any notes!</Typography>
                <Typography>Once submitted please complete and sign off the Training Outcome</Typography>
            </Stack>
        )
    }

    

    return (
        <Accordion expanded={open} onChange={() => setOpen(prevVal => !prevVal)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant='h5'>Sign Off</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {(learningDocument.type === LearningDocumentType.trainingDocumentNTM || learningDocument.type === LearningDocumentType.trainingDocumentRTM) && (
                    <TrainingDocumentSignOff/>
                )}
                {learningDocument.type === LearningDocumentType.competencyDocument && (
                    <CompetencyDocumentSignOff
                        formik={formik}
                        setMessageItem={setMessageItem}
                        departmentName={learningDocument.department?.label}
                        currentUser={currentUser}
                    />
                )}
                {learningDocument.type === LearningDocumentType.orientation && (
                    <OrientationDocumentSignOff
                        formik={formik}
                        setMessageItem={setMessageItem}
                        departmentName={learningDocument.department?.label}
                        currentUser={currentUser}
                    />
                )}
                <span style={{margin:"1rem", display: "flex", justifyContent:"right"}}>
                    <LoadingButton variant='contained' loading={submitMutation.isPending} color={submitBtnColour} onClick={handleSubmitClick}>{documentSubmitted ? "Already Submitted!" : "Submit as Completed"}</LoadingButton>
                </span>
            </AccordionDetails>
        </Accordion>
    )
}

export default SignOff