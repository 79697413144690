import { Autocomplete, Paper, TextField, Typography } from '@mui/material';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService';
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService';
import InspectionChecklistVersionService from 'src/Services/Checklists/Inspections/InspectionChecklistVersionService';
import FinalSignOffSection from 'src/pages/User Site/Inspections/Inspection/InspectionComponents/FormSections/FinalSignOffSection/FinalSignOffSection';
import FormSection from 'src/pages/User Site/Inspections/Inspection/InspectionComponents/FormSections/FormSection/FormSection';
import QuestionTitle from 'src/components/QuestionTitle/QuestionTitle';
import InspectionBottomNavigation from 'src/pages/User Site/Inspections/InspectionV2/Components/InspectionBottomNavigation';
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar';
import { InspectedComponentStatus, InspectionChecklistSectionResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto';
import { ChildEntity } from 'src/dtos/EntityChild.dto';
import { InspectionChecklistSection } from 'src/dtos/Checklists/MaintenanceChecklist.dto';
import { Status } from 'src/dtos/Statuses';
import { guidRegex } from 'src/Utils/helperFunc';
import { NameNumberDto } from 'src/dtos/NameDto.dto';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as yup from "yup";

const validationSchema = yup.object({
    sectionResponses: yup.array()
        .of(yup.object().shape({
            questionResponses: yup.array()
                .of(yup.object().shape({
                    answer: yup
                        .string(),
                    failureCause: yup
                        .string()
                        .min(10, "Failure cause is required and must be at least 10 characters"),
                    remidialAction: yup
                        .string()
                        .min(10, "Remidial action is required and must be at least 10 characters")
                        .notRequired(),
                    issueResolved: yup
                        .boolean()
                        .nullable(),
                    actionRequired: yup
                        .string()
                        .min(10, "Action required is required and must be at least 10 characters")
                        .notRequired(),
                    comment: yup
                        .string()
                        .max(255, "Comment cannot be more than 255 characters"),
                    groupedQuestions: yup.array()
                        .of(yup.object().shape({
                            failureCause: yup
                                .string()
                                .min(10, "Failure cause is required and must be at least 10 characters"),
                            remidialAction: yup
                                .string()
                                .min(10, "Remidial action is required and must be at least 10 characters")
                                .notRequired(),
                            issueResolved: yup
                                .boolean()
                                .nullable(),
                            actionRequired: yup
                                .string()
                                .min(10, "Action required is required and must be at least 10 characters")
                                .notRequired(),
                                comment: yup
                        .string()
                        .max(255, "Comment cannot be more than 255 characters"),
                        }))
                        
            }))
            .required()
        }))
})


function Inspection() {
    const [taggedOutComponents, setTaggedOutComponents] = useState<ChildEntity[]>([]);
    const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined});
    const {responseId} = useParams(); 

    const save = (values: any) => {
        //TODO: Save entire checklist function
    }

    //#region queries
    const queryClient = useQueryClient()

    const [checklistResponseQueryEnabled, setChecklistResponseQueryEnabled] = useState(true);
    
    const isAnyMutationOngoing = 
        queryClient.isMutating({ mutationKey: ["UpdateQuestion"] }) > 0 ||
        queryClient.isMutating({ mutationKey: ["UpdateSection"] }) > 0 ||
        queryClient.isMutating({ mutationKey: ["UpdateSelectedComponents"] }) > 0;


    const timeoutRef = useRef<NodeJS.Timeout | null >(null)
    useEffect(() => {
        if(isAnyMutationOngoing){
            setChecklistResponseQueryEnabled(false)
            // Clear the timeout if there's an ongoing mutation
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        } else {

            // Clear the timeout if there's an ongoing mutation
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setChecklistResponseQueryEnabled(true)
            }, 2000)

        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };

    }, [isAnyMutationOngoing]);

    const checklistResponseQuery = useQuery({
        queryKey: ["checklistResponse", responseId],
        queryFn: async () => {
            if(responseId){
                return await InspectionChecklistResponseService.Get(responseId);
            }
            return InspectionChecklistResponseService.GetDefaultValues()
        },
        initialData: InspectionChecklistResponseService.GetDefaultValues(),
        enabled: checklistResponseQueryEnabled
    })

    const checklistVersionQuery = useQuery({
        queryKey: ["checklistVersion", checklistResponseQuery.data.inspectionChecklistVersionId],
        queryFn: async () => {
            if(guidRegex.test(checklistResponseQuery.data.inspectionChecklistVersionId) && checklistResponseQuery.data.inspectionChecklistVersionId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistVersionService.GetInspection(checklistResponseQuery.data.inspectionChecklistVersionId);
            }
            return InspectionChecklistVersionService.GetDefaultVersionValues()
        },
        initialData: InspectionChecklistVersionService.GetDefaultVersionValues()
    })
    
    const checklistQuery = useQuery({
        queryKey: ["checklist", checklistVersionQuery.data.inspectionChecklistId],
        queryFn: async () => {
            if(guidRegex.test(checklistVersionQuery.data.inspectionChecklistId) && checklistVersionQuery.data.inspectionChecklistId !== "00000000-0000-0000-0000-000000000000"){
                return await InspectionChecklistService.Get(checklistVersionQuery.data.inspectionChecklistId);
            }
            return InspectionChecklistService.GetDefaultValues()
        },
        initialData: InspectionChecklistService.GetDefaultValues()
    })

    useEffect(() => {
        if(checklistResponseQuery.isError){
            setMessageItem({error: checklistResponseQuery.error})
        }
        if(checklistVersionQuery.isError){
            setMessageItem({error: checklistVersionQuery.error})
        }
        if(checklistQuery.isError){
            setMessageItem({error: checklistQuery.error})
        }
    }, [checklistQuery.error, checklistQuery.isError, checklistResponseQuery.error, checklistResponseQuery.isError, checklistVersionQuery.error, checklistVersionQuery.isError])


    const updateInspectedComponentMutation = useMutation({
        mutationFn: async ({responseId, componentId, status}: {responseId: string, componentId: number, status: InspectedComponentStatus}) => {
            queryClient.cancelQueries({queryKey: ["checklistResponse", responseId], exact: true})
            return await InspectionChecklistResponseService.UpdateInspectedComponent(responseId, componentId, status)
        }
    })

    const submitInspectionMutation = useMutation({
        mutationFn: async ({id, values}:{id:string, values: Date}) => {
            return await InspectionChecklistResponseService.Submit(id, values)
        }, 
        onSuccess: () => {
            setMessageItem({successMessage: "Inspection Submitted."})
        },
        onError: (error) => {
            setMessageItem({error: error})
        }
    })

    const UpdateSelectedComponents = useMutation({
        mutationKey: ["UpdateSelectedComponents", responseId],
        mutationFn: async ({responseId, values}: {responseId: string, values: NameNumberDto[]}) => {
            return await InspectionChecklistResponseService.UpdateSelectedComponents(responseId, values)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["checklistResponse", responseId], exact: true});
        }
    })

    // const updateSectionMutation = useMutation({
    //     mutationKey: ["UpdateSection"],
    //     mutationFn: async ({sectionResponseId, values}: {sectionResponseId: number, values: InspectionChecklistSectionResponseDto}) => {
    //         return await InspectionChecklistResponseService.UpdateSection(sectionResponseId, values)
    //     },
    // })
    
    //#endregion

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: validationSchema,
        initialValues: checklistResponseQuery.data ?? InspectionChecklistResponseService.GetDefaultValues(),
        onSubmit: (values) => {
            save(values);
        }
    });

    const componentSectionIndexes = (componentId: number) : number[] => {       
        const sectionIndexes = checklistVersionQuery.data.sections.reduce<number[]>((acc, section, sectionIndex) => {
            if(section.repeatingComponentSet?.id === componentId){
                acc.push(sectionIndex)
            }
            return acc
        }, [])

        return sectionIndexes
    }

    const handleUserSelectedCompChange = async (values: any) => {
        //prevent components from being removed if their section is not Unanswered
        if(formik.values.selectedComponents && values < formik.values.selectedComponents){
            const removedComponent = formik.values.selectedComponents.find(comp => !values.includes(comp))
            if(removedComponent) {
                const sectionIndexes = componentSectionIndexes(removedComponent.id)
                
                const hasStartedSections = sectionIndexes.some((sectionIndex) => {
                    return formik.values.sectionResponses[sectionIndex].status !== Status.Unanswered
                })
                if(hasStartedSections && checklistVersionQuery.data.userSelectedComponent){
                    setMessageItem({errorMessage: `Cannot remove one of the ${checklistVersionQuery.data.userSelectedComponent.label} when a related section has been started`})
                    return
                }
            }
        }
        formik.setFieldValue("selectedComponents", values)
        responseId && UpdateSelectedComponents.mutateAsync({responseId: responseId, values: values})
        
    } 

    //determines if the questions in the provided section are editable.
    const questionsEditable = (sectionIndex: number) : true | string  => {
        const lastSectionStatus = sectionIndex - 1 >= 0 && formik.values.sectionResponses[sectionIndex-1].status;

        if((formik.values.status === Status.Passed || formik.values.status === Status.Failed) && formik.values.completedTime !== null){
            return "Cannot edit a completed inspection"
        }

        if(checklistVersionQuery.data?.forceWorkflow === false){
            return true
        }

        const lastSectionVisible = sectionVisibility[sectionIndex-1]
        if(lastSectionStatus === Status.Passed || lastSectionStatus === Status.Failed || lastSectionStatus === Status.ComponentFailed || lastSectionStatus === false || !lastSectionVisible){
            return true
        } else {
            return "Must complete previous section before proceeding"
        }
    };

    

    const handleSectionStatusChange = (sectionIndex: number, newSectionResponse: InspectionChecklistSectionResponseDto) => {
        //component failed or not inspected doesnt prevent the inspection from passing
        const validSectionStatuses = [Status.Passed, Status.ComponentFailed, Status.NotInspected]
        const inspectionPassed = formik.values.sectionResponses.every((sectionResponse) => {
            const response = sectionResponse.id === newSectionResponse.id ? newSectionResponse : sectionResponse
            return validSectionStatuses.includes(response.status)
        })

        const inspectionFailed = formik.values.sectionResponses.some((sectionResponse) => {
            const response = sectionResponse.id === newSectionResponse.id ? newSectionResponse : sectionResponse
            return response.status === Status.Failed
        })

        const inspectionStatus: Status = inspectionPassed ? Status.Passed : inspectionFailed ? Status.Failed : Status.Inprogress;
        formik.setFieldValue("status", inspectionStatus)

        //Update inspected components
        const componentId = checklistVersionQuery.data.sections[sectionIndex].repeatingComponentSet?.id
        if(componentId && formik.values.inspectedComponents.length > 0){
            const allSectionIndexes = componentSectionIndexes(componentId);

            const allStatuses = allSectionIndexes.reduce<Status[]>((acc, componentSectionIndex) => {
                if(componentSectionIndex === sectionIndex){
                    //if the componentSectionIndex equals the sectionIndex the formik values will be old use the new value
                    acc.push(newSectionResponse.status)
                } else if(formik.values.sectionResponses[componentSectionIndex]) {
                    acc.push(formik.values.sectionResponses[componentSectionIndex].status)
                }   
                return acc
            }, [])

            let componentStatus = InspectedComponentStatus.notInspected
            if(allStatuses.includes(Status.ComponentFailed)){
                componentStatus = InspectedComponentStatus.failed
            } else if (allStatuses.filter(status => status === Status.Passed).length === allStatuses.length){
                componentStatus = InspectedComponentStatus.passed
            }

            const inspectedComponentIndex = formik.values.inspectedComponents.findIndex(ic => ic.component.id === componentId)
            formik.setFieldValue(`inspectedComponents[${inspectedComponentIndex}].status`, componentStatus)

            if(responseId){
                updateInspectedComponentMutation.mutate({responseId: responseId, componentId: componentId, status: componentStatus})
            }
        }
    }

    const sectionVisible = (section: InspectionChecklistSection, sectionIndex: number) : boolean => {
        let sectionVisible = true
        if(section.userSelectedComponents === true && section.repeatingComponentSet){
            sectionVisible =  (formik.values.selectedComponents??[]).some(e => e.id === section.repeatingComponentSet?.id);
        } 

        if(sectionVisible && formik.values.sectionResponses[sectionIndex] && formik.values.sectionResponses[sectionIndex].status === Status.NotInspected) {
            //if the section is visible and the status is not inspected change the status to unanswered
            //updateSectionAnswers(sectionIndex, formik.values.sectionResponses[sectionIndex], Status.Unanswered)
            //formik.setFieldValue(`sectionResponses[${sectionIndex}].status`, Status.Unanswered)
            
        } else if (!sectionVisible && formik.values.sectionResponses[sectionIndex] && formik.values.sectionResponses[sectionIndex].status !== Status.NotInspected) {
             //if the section is not visible and the status is not not inspected change the status to not inspected
             //updateSectionAnswers(sectionIndex, formik.values.sectionResponses[sectionIndex], Status.NotInspected)
            //formik.setFieldValue(`sectionResponses[${sectionIndex}].status`, Status.NotInspected)
            
        }
        //updateSectionMutation.mutate({sectionResponseId: })

        return sectionVisible
    }
    const [sectionVisibility, setSectionVisibility] = useState<boolean[]>(() => checklistVersionQuery.data.sections.map((section, index) => sectionVisible(section, index)))

    useEffect(() => {
        const newSectionVisiblity = checklistVersionQuery.data.sections.map((section, index) => sectionVisible(section, index));
        setSectionVisibility(newSectionVisiblity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.selectedComponents, checklistVersionQuery.data?.sections])

    
    const submitInspection = async () => {
        
        const values = formik.values;
        values.completedTime = new Date();
        
        //cannot be submitted as in progress, should fail the inspection
        if(values.status === Status.Inprogress){
            values.status = Status.Failed
        }
        
        submitInspectionMutation.mutateAsync({id: formik.values.id, values: values.completedTime})
    }

    const userSelectedOptions = (userSelectedComponent: ChildEntity) : NameNumberDto[] => {
        const options: NameNumberDto[] = userSelectedComponent.children.map((child) => {
            const comp: NameNumberDto = {
                id: child.id??0,
                label: child.label
            }
            return comp
        })

        return options;
    }

    if (checklistResponseQuery.isLoading || checklistVersionQuery.isLoading || checklistQuery.isLoading){
        return (
            <>
                Loading...
            </>
        )
    }
    // if(checklistAnswers.completedTime !== undefined && checklistAnswers.completedTime !== null){
    //     return (
    //         <>
    //             <Typography>Inspection already completed!</Typography>
    //         </>
    //     )
    // }

    return (
        <>
            <div style={{ paddingBottom: "5rem" }}>
                <form onSubmit={formik.handleSubmit}>
                <Typography variant='h5' sx={{display: "flex", justifyContent: "center", padding: "1rem"}}>{checklistQuery.data.label}</Typography>

                {checklistVersionQuery.data.userSelectedComponent && (
                    <div style={{padding:"1rem"}}>
                        <Paper sx={{padding:"1rem"}}>
                            <QuestionTitle title={`Which ${checklistVersionQuery.data.userSelectedComponent.label} are you inspecting during this inspection?`} required/>
                            <Autocomplete
                                id={`userSelecteditems_${checklistVersionQuery.data.userSelectedComponent.label}`}
                                multiple
                                options={userSelectedOptions(checklistVersionQuery.data.userSelectedComponent)}
                                value={formik.values.selectedComponents ?? []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label}
                                onChange={(_, value) => handleUserSelectedCompChange(value)}
                                sx={{width: "20rem"}}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant='outlined'
                                    size="small"
                                    label={checklistVersionQuery.data.userSelectedComponent?.label}
                                    />
                                )}
                            />
                        </Paper>
                    </div>  
                )}
                {checklistVersionQuery.data && checklistVersionQuery.data.sections.map((section, sectionIndex) => {
                    const sectionErrors =
                    formik.errors.sectionResponses && typeof formik.errors.sectionResponses[sectionIndex] === 'object'
                        ? (formik.errors.sectionResponses[sectionIndex] as FormikErrors<InspectionChecklistSectionResponseDto>)
                        : undefined;
                    const sectionTouched =
                    formik.touched.sectionResponses && typeof formik.touched.sectionResponses[sectionIndex] === 'object'
                        ? (formik.touched.sectionResponses[sectionIndex] as FormikTouched<InspectionChecklistSectionResponseDto>)
                        : undefined;
                    return (
                        <Fragment key={sectionIndex}>
                            {sectionVisibility[sectionIndex] && formik.values.sectionResponses[sectionIndex] && (
                                <FormSection
                                    key={sectionIndex}
                                    demoMode={false}
                                    formik={formik}
                                    formikErrors={sectionErrors}
                                    formikTouched={sectionTouched}
                                    formikString={`sectionResponses[${sectionIndex}]`}
                                    sectionContent={section}
                                    sectionResponse={formik.values.sectionResponses[sectionIndex]}
                                    handleSectionStatusChange={(newSectionResponse: InspectionChecklistSectionResponseDto) => handleSectionStatusChange(sectionIndex, newSectionResponse)}
                                    questionsEditable={() => questionsEditable(sectionIndex)}
                                    taggedOutComponents={taggedOutComponents}
                                    setTaggedOutComponents={setTaggedOutComponents}
                                    setMessageItem={setMessageItem}
                                />
                            )}
                        </Fragment>
                    )
                    
                    })}
                
                <FinalSignOffSection
                    checklistName={checklistQuery.data.label}
                    checklistStatus={formik.values.status}
                    submitInspection={submitInspection}
                />
                
                </form>
            </div>

            <ErrorHandlingSnackbar messageItem={messageItem} alertSx={{marginBottom:"5rem"}}/>
            <InspectionBottomNavigation />
        </>
    );
}

export default Inspection