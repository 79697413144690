import React from 'react'

type Props = {}

const AttractionInspectionsIndex = (props: Props) => {
  return (
    <div>AttractionInspectionsIndex</div>
  )
}

export default AttractionInspectionsIndex