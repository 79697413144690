import { Stack, Button, TextField } from "@mui/material";
import { FormikProps } from "formik";
import QuestionTitle from "src/components/QuestionTitle/QuestionTitle";
import { LearningDocumentTopic } from "src/dtos/Training/LearningDocument.dto";
import { LearningDocumentTopicResponse, LearningDocumentResponseDto, LearningDocumentResponseStatus } from "src/dtos/Training/LearningDocumentResponse.dto";
import Subtopic from "../../Subtopic/Subtopic";
import React, { useState } from "react";
import ErrorHandlingSnackbar, { MessageItem } from "src/components/errorHandlingSnackbar";
import { GetTopicStatus } from "../../../calculateStatusHelper";

type Props = {
    topic: LearningDocumentTopic;
    topicResponse: LearningDocumentTopicResponse;
    formikString: string;
    formik: FormikProps<LearningDocumentResponseDto>;
    demoMode?: boolean;
}

function CompetencyDocumentTopic({topic,topicResponse,formikString, formik, demoMode}: Props) {
    const [messageItem, setMessageItem] = useState<MessageItem>({});
    const [notes, setNotes] = useState(topicResponse.commentary)

    const topicStatus = GetTopicStatus(topicResponse, true)

    const ResultOfAssesment = () => {

        //Statuses where competency can be set.
        const validStatuses = [LearningDocumentResponseStatus.AwaitingOutcome, LearningDocumentResponseStatus.Competent, LearningDocumentResponseStatus.NotYetCompetent]

        const handleYesClick = () => {
            let competency = null;
            if(!validStatuses.includes(topicStatus)){
                setMessageItem({errorMessage: "Cannot set result while there are incomplete subtopics"})
                return
            }
            if (topicResponse.competent !== true) {
                competency = true
                formik.setFieldValue(`${formikString}.completedTime`, new Date());
            }
            formik.setFieldValue(`${formikString}.competent`, competency);
        };

        const handleNoClick = () => {
            let competency = null;
            if(!validStatuses.includes(topicStatus)){
                setMessageItem({errorMessage: "Cannot set result while there are incomplete subtopics"})
                return
            }
            if (topicResponse.competent !== false) {
                competency = false
                formik.setFieldValue(`${formikString}.completedTime`,new Date());
            }
            formik.setFieldValue(`${formikString}.competent`, competency);
        };

        return (
            <Stack direction="row" spacing={2}>
                <Button
                    size="large"
                    variant={
                        topicResponse.competent === true
                            ? "contained"
                            : "outlined"
                    }
                    color="success"
                    onClick={handleYesClick}
                >
                    Competent
                </Button>
                <Button
                    size="large"
                    variant={
                        topicResponse.competent === false
                            ? "contained"
                            : "outlined"
                    }
                    color="warning"
                    onClick={handleNoClick}
                >
                    Not Yet Competent
                </Button>
            </Stack>
        );
    };
    return (
        <Stack spacing={2}>
            {topic.subtopics.map((subtopic, index) => (
                <Subtopic
                    key={index}
                    type="Active"
                    index={index}
                    formik={formik}
                    formikString={`${formikString}.subtopicResponses[${index}]`}
                    subtopicResponse={topicResponse.subtopicResponses[index]}
                    subtopic={subtopic}
                    pointsLocked={topicStatus === LearningDocumentResponseStatus.NotYetCompetent || topicStatus === LearningDocumentResponseStatus.Competent}
                    demoMode={demoMode}
                />
            ))}
            <div></div>
            <div className="avoidBreak">
                <QuestionTitle title="Notes" />
                <TextField
                    id={`${formikString}.commentary`}
                    name={`${formikString}.commentary`}
                    value={notes ?? ""}
                    onChange={(e) => setNotes(e.target.value)}
                    onBlur={() => formik.setFieldValue(`${formikString}.commentary`, notes)}
                    sx={{ width: "100%" }}
                    multiline
                />
            </div>
            <div>
                <QuestionTitle title="Result of Assessment" required />
                <ResultOfAssesment />
            </div>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </Stack>
    );
}

export default CompetencyDocumentTopic;