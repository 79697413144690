import { Autocomplete, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import PermittedInspectionsMatrix from './Components/PermittedInspectionsMatrix'
import { useQuery } from '@tanstack/react-query'
import { NameDto } from 'src/dtos/NameDto.dto'
import PermittedAttractionInspectionsService from 'src/Services/Checklists/Inspections/PermittedAttractionInspectionsService'
import AttractionService from 'src/Services/Resources/AttractionService'

function PermittedInspections() {
    const [messageItem, setMessageItem] = useState<MessageItem>({})

    const [teamMembers, setTeamMembers] = useState<NameDto[]>([])
    const [tmDepartments, setTmDepartments] = useState<NameDto[]>([])
    const [attractions, setAttractions] = useState<NameDto[]>([])
    
    //#region queries
    const departmentNamesQuery = useQuery({
        queryKey: ["GetAttractionInspectionDepartments"],
        queryFn: async () => {
            return await PermittedAttractionInspectionsService.GetAttractionInspectionDepartments();
        }
    })

    const teamMemberNamesQuery = useQuery({
        queryKey: ["GetInspectionTeamMembers", tmDepartments],
        queryFn: async () => {
            return await PermittedAttractionInspectionsService.GetInspectionTeamMembers(tmDepartments)
        }
    })

    const attractionNamesQuery = useQuery({
        queryKey: ["GetAttractionNames"],
        queryFn: async () => {
            return await AttractionService.GetNames();
        }
    })

    useEffect(() => {
        if(departmentNamesQuery.isError){
            setMessageItem({error: departmentNamesQuery.error})
        }
        if(teamMemberNamesQuery.isError){
            setMessageItem({error: teamMemberNamesQuery.error})
        }
    }, [departmentNamesQuery.error, departmentNamesQuery.isError, teamMemberNamesQuery.error, teamMemberNamesQuery.isError])

    //#endregion


    return (
        <>
            <Paper sx={{padding:"1rem"}}>
                <Typography variant='h5'>Team Member Permitted Inspections</Typography>
                <Typography>Team members assigned to these inspections will be able to complete them.</Typography>
                <Stack direction={"row"} spacing={2} sx={{paddingBottom:"1rem", paddingTop:"1rem"}}>
                    <Stack spacing={2}>
                        <Autocomplete
                            id="tmDepartments"
                            options={departmentNamesQuery.data ?? []}
                            multiple
                            value={tmDepartments}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label ? option.label: ""}
                            onChange={(e, value) => {
                                setTmDepartments(value) 
                            }}
                            size="small"
                            sx={{ width: "20rem"}}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    label="Team Member Department"
                                />
                            }
                        />
                        <Autocomplete
                            id="teamMembers"
                            options={teamMemberNamesQuery.data ?? []}
                            multiple
                            value={teamMembers}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label ? option.label: ""}
                            onChange={(e, value) => {
                                setTeamMembers(value) 
                            }}
                            size="small"
                            sx={{width:"20rem"}}
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    label="Team Members"
                                />
                            }   
                        />
                    </Stack>
                    <Autocomplete
                        id="attractions"
                        options={attractionNamesQuery.data ?? []}
                        multiple
                        value={attractions}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label ? option.label: ""}
                        onChange={(e, value) => {
                            setAttractions(value) 
                        }}
                        size="small"
                        sx={{width:"20rem"}}
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                label="Attractions"
                            />
                        }   
                    />
                </Stack>
                <PermittedInspectionsMatrix
                    teamMembers={teamMembers}
                    tmDepartments={tmDepartments}
                    attractions={attractions}
                    setMessageItem={setMessageItem}
                />

            </Paper>
            <ErrorHandlingSnackbar messageItem={messageItem}/>
        </>
    )
}

export default PermittedInspections