import { Autocomplete, Box, Button, Modal, Stack, TableCell, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AttractionService from 'src/Services/Resources/AttractionService'
import InspectionChecklistResponseService from 'src/Services/Checklists/Inspections/ChecklistResponseService'
import DepartmentService from 'src/Services/Resources/DepartmentService'
import InspectionChecklistService from 'src/Services/Checklists/Inspections/InspectionChecklistService'
import { ListParameters } from 'src/Services/ListParameters'
import LocationService from 'src/Services/Resources/LocationService'
import Datatable from 'src/components/Datatable/Datatable'
import { TableHeader } from 'src/components/Datatable/TableHeader'
import ErrorHandlingSnackbar, { MessageItem } from 'src/components/errorHandlingSnackbar'
import { dateOptions } from 'src/config/constants'
import { AttractionNameDto } from 'src/dtos/Resources/Attraction.dto'
import { InspectionChecklistResponseDto } from 'src/dtos/Checklists/AttractionInspections/ChecklistResponse.dto'
import { DepartmentNameDto } from 'src/dtos/Resources/Department.dto'
import { ListResponseDto } from 'src/dtos/ListResponse.dto'
import { LocationNameDto } from 'src/dtos/Resources/Location.dto'
import { InspectionChecklist } from 'src/dtos/Checklists/MaintenanceChecklist.dto'
import { StyledTableRow } from 'src/components/Datatable/StyledTableRow'

type filterOptionsType = {
  department?: {id: string, label: string},
  attraction?: {id: string, label: string},
  location?: {id: string, label: string}
}

function UserInspectionChecklists() {
  const [messageItem, setMessageItem] = useState<MessageItem>({successMessage: undefined, error: undefined})
  const [filterOptions, setFilterOptions] = useState<filterOptionsType>({department: undefined, attraction: undefined, location: undefined})
  const [inspectionMenuOpen, setInspectionMenuOpen] = useState(false)
  const [inspectionId, setInspectionId] = useState<string>();
  const [activeInspection, setActiveInspection] = useState<InspectionChecklistResponseDto | null>(null)

  const navigate = useNavigate();


  const tableHeaders: TableHeader[] = [
      {label: "Name", field: "label", width: "20rem", sortable: true},
    ] 
  const initialListParams: ListParameters = {
    page: 1,
    pageSize: 30,
    sortBy: 'label'
  }
  function renderTableRow ({data}: {data:InspectionChecklist}) {
    const handleClick = (e:any, id: string) => {
      e.stopPropagation();
      setInspectionMenuOpen(true);
      setInspectionId(id)
    }    

    return (
      <React.Fragment>
        <StyledTableRow
          hover
          onClick={(e)=> handleClick(e, data.id ?? "")}
          key={data.id}
          sx={{cursor: 'pointer'}}
        >
          <TableCell sx={{minWidth: `${tableHeaders[0].width} !important`, maxWidth: `${tableHeaders[0].width} !important`}}>{data.label}</TableCell>

      </StyledTableRow>
      </React.Fragment>
      
    )
  };

  const fetchInspections = async (listParams: ListParameters, searchTerm?: string): Promise<ListResponseDto> => {
    const data = await InspectionChecklistService.GetPublishedList(listParams, searchTerm, filterOptions.department?.id, filterOptions.attraction?.id, filterOptions.location?.id);
    return data;
  }

  function FilterMenu(props: {handleClose: () => void}){
    const [department, setDepartment] = useState(filterOptions.department);
    const [departmentNames, setDepartmentNames] = useState<DepartmentNameDto[]>([]);
    const [attraction, setAttraction] = useState(filterOptions.attraction);
    const [attractionNames, setAttractionNames] = useState<AttractionNameDto[]>([]);
    const [location, setLocation] = useState(filterOptions.location);
    const [locationNames, setLocationNames] = useState<LocationNameDto[]>([]);
    

    useEffect(() => {
      const fetchLocationNames = async () => {
        try{
          const data = await LocationService.GetNames()
          setLocationNames(data)
        }
        catch (error: any) {
          setMessageItem({error: error});
        }
      }

      const fetchDepartmentNames = async () => {
        try {
          const data = await DepartmentService.GetNames()
          setDepartmentNames(data)
        } catch (error: any) {
          setMessageItem({error: error})
        }
      }

      const fetchAttractionNames = async () => {
        try {
          const data = await AttractionService.GetNames()
          setAttractionNames(data)
        } catch (error: any){
          setMessageItem({error: error})
        }
      }

      fetchLocationNames();
      fetchDepartmentNames();
      fetchAttractionNames();
    }, [])

    const handleOkClick = () => {
      props.handleClose()
      setFilterOptions({department: department, attraction: attraction, location: location})
    }

    return (
      <Box style={{padding: "1rem", display: "block"}}>
        <Typography variant='h6' sx={{paddingBottom: '1rem', paddingLeft: '0.5rem'}}>Filter</Typography>
        <Autocomplete
          disablePortal={false}
          id="department"
          options={departmentNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={department || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setDepartment(value)
            } else {
              setDepartment(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Department"
          />}
        />
        <Autocomplete
          disablePortal={false}
          id="attraction"
          options={attractionNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={attraction || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setAttraction(value)
            } else {
              setAttraction(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Attraction"
          />}
        />
        <Autocomplete
          disablePortal={false}
          id="location"
          options={locationNames}
          size="small"
          sx={{ width: "15rem", paddingBottom: "0.5rem" }}
          value={location || null}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label ? option.label: ""}
          onChange={(e, value) => {
            if(value){
              setLocation(value)
            } else {
              setLocation(undefined)
            }
          }}
          renderInput={(params) => 
          <TextField {...params} label="Location"
          />}
        />
        <Button onClick={handleOkClick}>Filter</Button>
      </Box>
    )

  }

  const handleInspectionMenuClose = () => {
    setInspectionMenuOpen(false);
    setInspectionId(undefined);
  }

  const handleInspectionStart = () => {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/; //check if a string is a guid

    const startInspection = async () => {
      if(inspectionId !== undefined && guidRegex.test(inspectionId)){
        try {
          const response = await InspectionChecklistResponseService.StartInspection(inspectionId)
          if(response.status >= 200 && response.status <= 299){
            navigate(`/user/attractionInspections/inspectionChecklists/${response.data}`)
            
          } 
        } catch (error: any){
          setMessageItem({error: error})
        }
       
      }
    }
    startInspection();

  }

  useEffect(() => {
    const GetActiveInspection = async () => {
      if(inspectionId){
        try {
          const response = await InspectionChecklistResponseService.GetActiveInspection(inspectionId)
          setActiveInspection(response.data);

        } catch (error: any) {
          setMessageItem({error: error})
        }

      } else {
        setActiveInspection(null)
      }
    }

    GetActiveInspection()
  }, [inspectionId])

  const handleInspectionContinue = (inspectionResponse: InspectionChecklistResponseDto) => {
    navigate(`/user/attractionInspections/inspectionChecklists/${inspectionResponse.id}`)
  }

  const inspectionMenuStyle = {
    borderRadius: '10px',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const startedByEmail = activeInspection?.startedBy ?? ""
  const startedByName = startedByEmail.split('@')[0].split('.')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');

  return (
    <>
      <Box sx={{display: "Flex", justifyContent:"center", paddingTop:"2rem"}}>
        <Datatable
          tableHeaders={tableHeaders}
          showSearch={true}
          tableTitle='Inspection'
          initialListParams={initialListParams}
          setMessageItem={(caughtError: MessageItem) => setMessageItem(caughtError)}
          renderRow={renderTableRow}
          callService={fetchInspections}
          FilterMenu={FilterMenu}
        />       
      </Box>
      <Modal
        open={inspectionMenuOpen}
        onClose={handleInspectionMenuClose}
      >
        <Box sx={{...inspectionMenuStyle, padding:"1rem" }}>
          {activeInspection ? (
            <>
              <Typography variant='h6'>Resume Inspection</Typography>
              <Typography>{`Started On: ${activeInspection.startedTime ? new Date(activeInspection.startedTime).toLocaleDateString(undefined, dateOptions) : ""}`}</Typography>
              <Typography>{`Started By: ${startedByName}`}</Typography>
              <Typography>{`There is already an active inspection, if you need to start a new inspection, submit the previous inspection as failed.`}</Typography>
              <Stack spacing={1} direction="row" style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                <Button variant='contained' onClick={() => handleInspectionContinue(activeInspection)}>Continue</Button>
                <Button onClick={handleInspectionMenuClose}>Cancel</Button>
              </Stack>
            </>
          ) : (
            <>
              <Typography variant='h6'>Start Inspection</Typography>
              <Typography>{`Are you sure you want to start the inspection?`}</Typography>
              <Stack spacing={1} direction="row" style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
                <Button variant='contained' onClick={handleInspectionStart}>Start</Button>
                <Button onClick={handleInspectionMenuClose}>Cancel</Button>
              </Stack>
            </>
          )}
          
        </Box>

      </Modal>
      <ErrorHandlingSnackbar
        messageItem={messageItem}
      />
    </>
    
  )
}

export default UserInspectionChecklists